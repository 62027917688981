<template>
  <div class="column" v-loading="loading">
    <div class="title mt50">自制数据报告</div>
    <div class="sub">请选择需要添加的图表生成报告</div>
    <div class="row mt84">
      <div class="width601 column_base">
        <div class="row_center mt24 width_max">
          <div class="chart_title">园所综合报告</div>
          <div class="flex"></div>
          <img
            v-if="!choice1"
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_N.png"
            @click="choiceYsZHBG(true)"
          />
          <img
            v-else
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_Y.png"
            @click="choiceYsZHBG(false)"
          />
        </div>
        <div class="line mt20"></div>
        <div class="row width_max h500">
          <div v-if="monthList.length != 0" class="column_base mrl5">
            <div
              v-if="ysMonthType == monthList[0].value"
              class="month_select1 row center mt50"
            >
              <div class="circle1"></div>
              <div class="top_text">{{ monthList[0].name }}</div>
            </div>
            <div
              v-else
              class="month_normal1 row center mt50"
              @click="clickYsMonth(monthList[0])"
            >
              <div class="circle1"></div>
              <div class="top_text">{{ monthList[0].name }}</div>
            </div>
            <div
              v-if="ysMonthType == monthList[1].value"
              class="month_select2 row center"
            >
              <div class="circle2"></div>
              <div class="top_text">{{ monthList[1].name }}</div>
            </div>
            <div
              v-else
              class="month_normal1 row center"
              @click="clickYsMonth(monthList[1])"
            >
              <div class="circle2"></div>
              <div class="top_text">{{ monthList[1].name }}</div>
            </div>
            <div
              v-if="ysMonthType == monthList[2].value"
              class="month_select3 row center"
            >
              <div class="circle3"></div>
              <div class="top_text">{{ monthList[2].name }}</div>
            </div>
            <div
              v-else
              class="month_normal1 row center"
              @click="clickYsMonth(monthList[2])"
            >
              <div class="circle3"></div>
              <div class="top_text">{{ monthList[2].name }}</div>
            </div>
            <div
              v-if="ysMonthType == monthList[3].value"
              class="month_select4 row center"
            >
              <div class="circle4"></div>
              <div class="top_text">{{ monthList[3].name }}</div>
            </div>
            <div
              v-else
              class="month_normal1 row center"
              @click="clickYsMonth(monthList[3])"
            >
              <div class="circle4"></div>
              <div class="top_text">{{ monthList[3].name }}</div>
            </div>
            <div
              v-if="ysMonthType == monthList[4].value"
              class="month_select5 row center"
            >
              <div class="circle5"></div>
              <div class="top_text">{{ monthList[4].name }}</div>
            </div>
            <div
              v-else
              class="month_normal1 row center"
              @click="clickYsMonth(monthList[4])"
            >
              <div class="circle5"></div>
              <div class="top_text">{{ monthList[4].name }}</div>
            </div>
            <div
              v-if="ysMonthType == monthList[5].value"
              class="month_select6 row center"
            >
              <div class="circle6"></div>
              <div class="top_text">{{ monthList[5].name }}</div>
            </div>
            <div
              v-else
              class="month_normal1 row center"
              @click="clickYsMonth(monthList[5])"
            >
              <div class="circle6"></div>
              <div class="top_text">{{ monthList[5].name }}</div>
            </div>
          </div>
          <div v-if="monthList.length != 0" class="image_c">
            <div class="title1">园所总人数</div>
            <div class="image_l">
              <img
                v-if="ysMonthType == monthList[0].value"
                class="image"
                src="../../assets/img/home/home/PES/Pic_TB_A.png"
              />
              <div v-if="ysMonthType == monthList[0].value" class="num1">
                {{ ysNum1 }}
              </div>
              <div v-if="ysMonthType == monthList[0].value" class="num2">
                {{ ysNum2 }}
              </div>
              <div v-if="ysMonthType == monthList[0].value" class="num3">
                {{ ysNum3 }}
              </div>
              <img
                v-if="ysMonthType == monthList[1].value"
                class="image"
                src="../../assets/img/home/home/PES/Pic_TB_B.png"
              />
              <div v-if="ysMonthType == monthList[1].value" class="num4">
                {{ ysNum1 }}
              </div>
              <div v-if="ysMonthType == monthList[1].value" class="num5">
                {{ ysNum2 }}
              </div>
              <div v-if="ysMonthType == monthList[1].value" class="num6">
                {{ ysNum3 }}
              </div>
              <img
                v-if="ysMonthType == monthList[2].value"
                class="image"
                src="../../assets/img/home/home/PES/Pic_TB_C.png"
              />
              <div v-if="ysMonthType == monthList[2].value" class="num7">
                {{ ysNum1 }}
              </div>
              <div v-if="ysMonthType == monthList[2].value" class="num8">
                {{ ysNum2 }}
              </div>
              <div v-if="ysMonthType == monthList[2].value" class="num9">
                {{ ysNum3 }}
              </div>
              <img
                v-if="ysMonthType == monthList[3].value"
                class="image"
                src="../../assets/img/home/home/PES/Pic_TB_D.png"
              />
              <div v-if="ysMonthType == monthList[3].value" class="num10">
                {{ ysNum1 }}
              </div>
              <div v-if="ysMonthType == monthList[3].value" class="num11">
                {{ ysNum2 }}
              </div>
              <div v-if="ysMonthType == monthList[3].value" class="num12">
                {{ ysNum3 }}
              </div>
              <img
                v-if="ysMonthType == monthList[4].value"
                class="image"
                src="../../assets/img/home/home/PES/Pic_TB_E.png"
              />
              <div v-if="ysMonthType == monthList[4].value" class="num13">
                {{ ysNum1 }}
              </div>
              <div v-if="ysMonthType == monthList[4].value" class="num14">
                {{ ysNum2 }}
              </div>
              <div v-if="ysMonthType == monthList[4].value" class="num15">
                {{ ysNum3 }}
              </div>
              <img
                v-if="ysMonthType == monthList[5].value"
                class="image"
                src="../../assets/img/home/home/PES/Pic_TB_F.png"
              />
              <div v-if="ysMonthType == monthList[5].value" class="num16">
                {{ ysNum1 }}
              </div>
              <div v-if="ysMonthType == monthList[5].value" class="num17">
                {{ ysNum2 }}
              </div>
              <div v-if="ysMonthType == monthList[5].value" class="num18">
                {{ ysNum3 }}
              </div>
            </div>
            <div class="row_center" style="width: 100%">
              <div class="title1 flex">测试总人数</div>
              <div class="title1">测试合格人数</div>
            </div>
          </div>
        </div>
      </div>
      <div class="width601 column_base mrl40">
        <div class="row_center mt24 width_max">
          <div class="chart_title">班级综合报告</div>
          <div class="flex"></div>
          <img
            v-if="!choice2"
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_N.png"
            @click="choiceBjZHBG(true)"
          />
          <img
            v-else
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_Y.png"
            @click="choiceBjZHBG(false)"
          />
        </div>
        <div class="line mt20"></div>
        <div class="row width_max h500">
          <div v-if="monthList.length != 0" class="column_base mrl5">
            <div
              v-if="bjMonthType == monthList[0].value"
              class="month_select1 row center mt50"
            >
              <div class="circle1"></div>
              <div class="top_text">{{ monthList[0].name }}</div>
            </div>
            <div
              v-else
              class="month_normal1 row center mt50"
              @click="clickBjMonth(monthList[0])"
            >
              <div class="circle1"></div>
              <div class="top_text">{{ monthList[0].name }}</div>
            </div>
            <div
              v-if="bjMonthType == monthList[1].value"
              class="month_select2 row center"
            >
              <div class="circle2"></div>
              <div class="top_text">{{ monthList[1].name }}</div>
            </div>
            <div
              v-else
              class="month_normal1 row center"
              @click="clickBjMonth(monthList[1])"
            >
              <div class="circle2"></div>
              <div class="top_text">{{ monthList[1].name }}</div>
            </div>
            <div
              v-if="bjMonthType == monthList[2].value"
              class="month_select3 row center"
            >
              <div class="circle3"></div>
              <div class="top_text">{{ monthList[2].name }}</div>
            </div>
            <div
              v-else
              class="month_normal1 row center"
              @click="clickBjMonth(monthList[2])"
            >
              <div class="circle3"></div>
              <div class="top_text">{{ monthList[2].name }}</div>
            </div>
            <div
              v-if="bjMonthType == monthList[3].value"
              class="month_select4 row center"
            >
              <div class="circle4"></div>
              <div class="top_text">{{ monthList[3].name }}</div>
            </div>
            <div
              v-else
              class="month_normal1 row center"
              @click="clickBjMonth(monthList[3])"
            >
              <div class="circle4"></div>
              <div class="top_text">{{ monthList[3].name }}</div>
            </div>
            <div
              v-if="bjMonthType == monthList[4].value"
              class="month_select5 row center"
            >
              <div class="circle5"></div>
              <div class="top_text">{{ monthList[4].name }}</div>
            </div>
            <div
              v-else
              class="month_normal1 row center"
              @click="clickBjMonth(monthList[4])"
            >
              <div class="circle5"></div>
              <div class="top_text">{{ monthList[4].name }}</div>
            </div>
            <div
              v-if="bjMonthType == monthList[5].value"
              class="month_select6 row center"
            >
              <div class="circle6"></div>
              <div class="top_text">{{ monthList[5].name }}</div>
            </div>
            <div
              v-else
              class="month_normal1 row center"
              @click="clickBjMonth(monthList[5])"
            >
              <div class="circle6"></div>
              <div class="top_text">{{ monthList[5].name }}</div>
            </div>
          </div>
          <div v-if="monthList.length != 0" class="image_c">
            <div class="title1">班级总人数</div>
            <div class="image_l">
              <img
                v-if="bjMonthType == monthList[0].value"
                class="image"
                src="../../assets/img/home/home/PES/Pic_TB_A.png"
              />
              <div v-if="bjMonthType == monthList[0].value" class="num1">
                {{ bjNum1 }}
              </div>
              <div v-if="bjMonthType == monthList[0].value" class="num2">
                {{ bjNum2 }}
              </div>
              <div v-if="bjMonthType == monthList[0].value" class="num3">
                {{ bjNum3 }}
              </div>
              <img
                v-if="bjMonthType == monthList[1].value"
                class="image"
                src="../../assets/img/home/home/PES/Pic_TB_B.png"
              />
              <div v-if="bjMonthType == monthList[1].value" class="num4">
                {{ bjNum1 }}
              </div>
              <div v-if="bjMonthType == monthList[1].value" class="num5">
                {{ bjNum2 }}
              </div>
              <div v-if="bjMonthType == monthList[1].value" class="num6">
                {{ bjNum3 }}
              </div>
              <img
                v-if="bjMonthType == monthList[2].value"
                class="image"
                src="../../assets/img/home/home/PES/Pic_TB_C.png"
              />
              <div v-if="bjMonthType == monthList[2].value" class="num7">
                {{ bjNum1 }}
              </div>
              <div v-if="bjMonthType == monthList[2].value" class="num8">
                {{ bjNum2 }}
              </div>
              <div v-if="bjMonthType == monthList[2].value" class="num9">
                {{ bjNum3 }}
              </div>
              <img
                v-if="bjMonthType == monthList[3].value"
                class="image"
                src="../../assets/img/home/home/PES/Pic_TB_D.png"
              />
              <div v-if="bjMonthType == monthList[3].value" class="num10">
                {{ bjNum1 }}
              </div>
              <div v-if="bjMonthType == monthList[3].value" class="num11">
                {{ bjNum2 }}
              </div>
              <div v-if="bjMonthType == monthList[3].value" class="num12">
                {{ bjNum3 }}
              </div>
              <img
                v-if="bjMonthType == monthList[4].value"
                class="image"
                src="../../assets/img/home/home/PES/Pic_TB_E.png"
              />
              <div v-if="bjMonthType == monthList[4].value" class="num13">
                {{ bjNum1 }}
              </div>
              <div v-if="bjMonthType == monthList[4].value" class="num14">
                {{ bjNum2 }}
              </div>
              <div v-if="bjMonthType == monthList[4].value" class="num15">
                {{ bjNum3 }}
              </div>
              <img
                v-if="bjMonthType == monthList[5].value"
                class="image"
                src="../../assets/img/home/home/PES/Pic_TB_F.png"
              />
              <div v-if="bjMonthType == monthList[5].value" class="num16">
                {{ bjNum1 }}
              </div>
              <div v-if="bjMonthType == monthList[5].value" class="num17">
                {{ bjNum2 }}
              </div>
              <div v-if="bjMonthType == monthList[5].value" class="num18">
                {{ bjNum3 }}
              </div>
              <el-select
                class="form-control"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
                v-model="currentGradeId"
                @change="change"
                placeholder="请选择"
              >
                <el-option
                  v-for="val in classList"
                  :key="val.id"
                  :label="val.name"
                  :value="val.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="row_center" style="width: 100%">
              <div class="title1 flex">测试总人数</div>
              <div class="title1">测试合格人数</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt41">
      <div class="width601 column_base">
        <div class="row_center mt24 width_max">
          <div class="chart_title">本学期测试完成率</div>
          <div class="flex"></div>
          <img
            v-if="!choice3"
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_N.png"
            @click="choiceYeZHBG(true)"
          />
          <img
            v-else
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_Y.png"
            @click="choiceYeZHBG(false)"
          />
        </div>
        <div class="line mt20"></div>
        <div class="row width_max h500">
          <div v-if="monthList.length != 0" class="column_base mrl5">
            <div
              v-if="yeMonthType == monthList[0].value"
              class="month_select1 row center mt50"
            >
              <div class="circle1"></div>
              <div class="top_text">{{ monthList[0].name }}</div>
            </div>
            <div
              v-else
              class="month_normal1 row center mt50"
              @click="clickYeMonth(monthList[0])"
            >
              <div class="circle1"></div>
              <div class="top_text">{{ monthList[0].name }}</div>
            </div>
            <div
              v-if="yeMonthType == monthList[1].value"
              class="month_select2 row center"
            >
              <div class="circle2"></div>
              <div class="top_text">{{ monthList[1].name }}</div>
            </div>
            <div
              v-else
              class="month_normal1 row center"
              @click="clickYeMonth(monthList[1])"
            >
              <div class="circle2"></div>
              <div class="top_text">{{ monthList[1].name }}</div>
            </div>
            <div
              v-if="yeMonthType == monthList[2].value"
              class="month_select3 row center"
            >
              <div class="circle3"></div>
              <div class="top_text">{{ monthList[2].name }}</div>
            </div>
            <div
              v-else
              class="month_normal1 row center"
              @click="clickYeMonth(monthList[2])"
            >
              <div class="circle3"></div>
              <div class="top_text">{{ monthList[2].name }}</div>
            </div>
            <div
              v-if="yeMonthType == monthList[3].value"
              class="month_select4 row center"
            >
              <div class="circle4"></div>
              <div class="top_text">{{ monthList[3].name }}</div>
            </div>
            <div
              v-else
              class="month_normal1 row center"
              @click="clickYeMonth(monthList[3])"
            >
              <div class="circle4"></div>
              <div class="top_text">{{ monthList[3].name }}</div>
            </div>
            <div
              v-if="yeMonthType == monthList[4].value"
              class="month_select5 row center"
            >
              <div class="circle5"></div>
              <div class="top_text">{{ monthList[4].name }}</div>
            </div>
            <div
              v-else
              class="month_normal1 row center"
              @click="clickYeMonth(monthList[4])"
            >
              <div class="circle5"></div>
              <div class="top_text">{{ monthList[4].name }}</div>
            </div>
            <div
              v-if="yeMonthType == monthList[5].value"
              class="month_select6 row center"
            >
              <div class="circle6"></div>
              <div class="top_text">{{ monthList[5].name }}</div>
            </div>
            <div
              v-else
              class="month_normal1 row center"
              @click="clickYeMonth(monthList[5])"
            >
              <div class="circle6"></div>
              <div class="top_text">{{ monthList[5].name }}</div>
            </div>
          </div>
          <div v-if="monthList.length != 0" class="image_c">
            <div class="title1">小班</div>
            <div class="image_l">
              <img
                v-if="yeMonthType == monthList[0].value"
                class="image"
                src="../../assets/img/home/home/PES/Pic_TB_A.png"
              />
              <div v-if="yeMonthType == monthList[0].value" class="num1">
                {{ yeNum1 }}
              </div>
              <div v-if="yeMonthType == monthList[0].value" class="num2">
                {{ yeNum2 }}
              </div>
              <div v-if="yeMonthType == monthList[0].value" class="num3">
                {{ yeNum3 }}
              </div>
              <img
                v-if="yeMonthType == monthList[1].value"
                class="image"
                src="../../assets/img/home/home/PES/Pic_TB_B.png"
              />
              <div v-if="yeMonthType == monthList[1].value" class="num4">
                {{ yeNum1 }}
              </div>
              <div v-if="yeMonthType == monthList[1].value" class="num5">
                {{ yeNum2 }}
              </div>
              <div v-if="yeMonthType == monthList[1].value" class="num6">
                {{ yeNum3 }}
              </div>
              <img
                v-if="yeMonthType == monthList[2].value"
                class="image"
                src="../../assets/img/home/home/PES/Pic_TB_C.png"
              />
              <div v-if="yeMonthType == monthList[2].value" class="num7">
                {{ yeNum1 }}
              </div>
              <div v-if="yeMonthType == monthList[2].value" class="num8">
                {{ yeNum2 }}
              </div>
              <div v-if="yeMonthType == monthList[2].value" class="num9">
                {{ yeNum3 }}
              </div>
              <img
                v-if="yeMonthType == monthList[3].value"
                class="image"
                src="../../assets/img/home/home/PES/Pic_TB_D.png"
              />
              <div v-if="yeMonthType == monthList[3].value" class="num10">
                {{ yeNum1 }}
              </div>
              <div v-if="yeMonthType == monthList[3].value" class="num11">
                {{ yeNum2 }}
              </div>
              <div v-if="yeMonthType == monthList[3].value" class="num12">
                {{ yeNum3 }}
              </div>
              <img
                v-if="yeMonthType == monthList[4].value"
                class="image"
                src="../../assets/img/home/home/PES/Pic_TB_E.png"
              />
              <div v-if="yeMonthType == monthList[4].value" class="num13">
                {{ yeNum1 }}
              </div>
              <div v-if="yeMonthType == monthList[4].value" class="num14">
                {{ yeNum2 }}
              </div>
              <div v-if="yeMonthType == monthList[4].value" class="num15">
                {{ yeNum3 }}
              </div>
              <img
                v-if="yeMonthType == monthList[5].value"
                class="image"
                src="../../assets/img/home/home/PES/Pic_TB_F.png"
              />
              <div v-if="yeMonthType == monthList[5].value" class="num16">
                {{ yeNum1 }}
              </div>
              <div v-if="yeMonthType == monthList[5].value" class="num17">
                {{ yeNum2 }}
              </div>
              <div v-if="yeMonthType == monthList[5].value" class="num18">
                {{ yeNum3 }}
              </div>
            </div>
            <div class="row_center" style="width: 100%">
              <div class="title1 flex">中班</div>
              <div class="title1">大班</div>
            </div>
          </div>
        </div>
      </div>
      <div class="width601 column_base mrl40 grwoth_small">
        <div class="row_center mt24 width_max">
          <div class="chart_title">幼儿成长测试</div>
          <div class="flex"></div>
          <img
            v-if="!choice4"
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_N.png"
            @click="choiceYefx(true)"
          />
          <img
            v-else
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_Y.png"
            @click="choiceYefx(false)"
          />
        </div>
        <div class="line mt20"></div>
        <div id="gLineChart" ref="gLineChart" class="topLine mt23"></div>
        <div class="top_hint">
          <div class="line"></div>
          <div class="flex text">
            幼儿{{ growthBean.typeValue }}测试数据小班
          </div>
          <div class="mrl2 line"></div>
          <div class="flex text">
            幼儿{{ growthBean.typeValue }}测试数据中班
          </div>
          <div class="mrl3 line"></div>
          <div class="flex text">
            幼儿{{ growthBean.typeValue }}测试数据大班
          </div>
          <div class="mrl1 line"></div>
        </div>
        <div class="row mt23">
          <div class="label ml8" @click="clickColor(1)">
            <div
              :class="
                growthBean.index == 1
                  ? 'width16 color1 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[0] }}
            </div>
          </div>
          <div class="label ml32" @click="clickColor(2)">
            <div
              :class="
                growthBean.index == 2
                  ? 'width16 color2 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[1] }}
            </div>
          </div>
          <div class="label ml32" @click="clickColor(3)">
            <div
              :class="
                growthBean.index == 3
                  ? 'width16 color3 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[2] }}
            </div>
          </div>
          <div class="label ml32" @click="clickColor(4)">
            <div
              :class="
                growthBean.index == 4
                  ? 'width16 color4 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[3] }}
            </div>
          </div>
          <div class="label ml32" @click="clickColor(5)">
            <div
              :class="
                growthBean.index == 5
                  ? 'width16 color5 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[4] }}
            </div>
          </div>
          <div class="label ml32" @click="clickColor(6)">
            <div
              :class="
                growthBean.index == 6
                  ? 'width16 color6 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[5] }}
            </div>
          </div>
          <div class="label ml32" @click="clickColor(7)">
            <div
              :class="
                growthBean.index == 7
                  ? 'width16 color7 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[6] }}
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 0.1rem">
          <div class="label ml8" @click="clickColor(8)">
            <div
              :class="
                growthBean.index == 8
                  ? 'width16 coloryd1 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[7] }}
            </div>
          </div>
          <div class="label ml32" @click="clickColor(9)">
            <div
              :class="
                growthBean.index == 9
                  ? 'width16 coloryd2 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[8] }}
            </div>
          </div>
          <div class="label ml32" @click="clickColor(10)">
            <div
              :class="
                growthBean.index == 10
                  ? 'width16 coloryd3 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[9] }}
            </div>
          </div>
          <div class="label ml32" @click="clickColor(11)">
            <div
              :class="
                growthBean.index == 11
                  ? 'width16 coloryd4 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[10] }}
            </div>
          </div>
          <div class="label ml32" @click="clickColor(12)">
            <div
              :class="
                growthBean.index == 12
                  ? 'width16 coloryd5 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[11] }}
            </div>
          </div>
          <div class="label ml32" @click="clickColor(13)">
            <div
              :class="
                growthBean.index == 13
                  ? 'width16 coloryd6 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[12] }}
            </div>
          </div>
          <div class="label ml20" @click="clickColor(14)">
            <div
              :class="
                growthBean.index == 14
                  ? 'width16 coloryd7 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[13] }}
            </div>
          </div>
          <div class="label ml20" @click="clickColor(15)">
            <div
              :class="
                growthBean.index == 15
                  ? 'width16 coloryd8 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[14] }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt41">
      <div class="width601 column_base">
        <div class="row_center mt24 width_max">
          <div class="chart_title">上月增长率对比</div>
          <div class="flex"></div>
          <img
            v-if="!choice5"
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_N.png"
            @click="choiceClick5(true)"
          />
          <img
            v-else
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_Y.png"
            @click="choiceClick5(false)"
          />
        </div>
        <div class="line mt20"></div>
        <div
          class="baseLine growth h500"
          :style="{
            transform: `scale(${0.5}) translate(0,-30%)`,
            '-webkit-transform': `scale(${0.5})) translate(0,-30%)`,
            '-moz-transform': `scale(${0.5}) translate(0,-30%)`,
            '-o-transform': `scale(${0.5}) translate(0,-30%)`,
            '-ms-transform': `scale(${0.5}) translate(0,-30%)`,
          }"
        >
          <div class="width514">
            <div class="top_lay">
              <div class="top_bg pos_abs">
                <div class="middle">上月体质增长率</div>
              </div>
            </div>
            <div class="hint1">本月体质测试全国增长率对比园所增长率</div>
            <div class="num_lay mt36">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.B_height
                    ? growthBean.rateData.B_height[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">身高</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.B_height &&
                    growthBean.rateData.B_height[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.B_height }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.B_height &&
                    growthBean.rateData.B_height[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.B_weight
                    ? growthBean.rateData.B_weight[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">体重</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.B_weight &&
                    growthBean.rateData.B_weight[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.B_weight }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.B_weight &&
                    growthBean.rateData.B_weight[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.B_eye_L
                    ? growthBean.rateData.B_eye_L[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">视力</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.B_eye_L &&
                    growthBean.rateData.B_eye_L[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.B_eye_L }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.B_eye_L &&
                    growthBean.rateData.B_eye_L[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.B_arm ? growthBean.rateData.B_arm[0] : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">臂展</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.B_arm &&
                    growthBean.rateData.B_arm[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.B_arm }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.B_arm &&
                    growthBean.rateData.B_arm[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.B_lung
                    ? growthBean.rateData.B_lung[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">肺活量</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.B_lung &&
                    growthBean.rateData.B_lung[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.B_lung }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.B_lung &&
                    growthBean.rateData.B_lung[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.B_bust
                    ? growthBean.rateData.B_bust[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">胸围</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.B_bust &&
                    growthBean.rateData.B_bust[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.B_bust }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.B_bust &&
                    growthBean.rateData.B_bust[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.B_flexion
                    ? growthBean.rateData.B_flexion[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">体前屈</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.B_flexion &&
                    growthBean.rateData.B_flexion[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.B_flexion }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.B_flexion &&
                    growthBean.rateData.B_flexion[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
          </div>
          <div class="width514 ml55">
            <div class="top_lay">
              <div class="top_bg pos_abs">
                <div class="middle">上月运动增长率</div>
              </div>
            </div>
            <div class="hint1">本月运动测试全国增长率对比园所增长率</div>
            <div class="num_lay mt36">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.S_run ? growthBean.rateData.S_run[0] : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">跑动</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.S_run &&
                    growthBean.rateData.S_run[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.S_run }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.S_run &&
                    growthBean.rateData.S_run[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.S_jump
                    ? growthBean.rateData.S_jump[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">跳跃</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.S_jump &&
                    growthBean.rateData.S_jump[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.S_jump }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.S_jump &&
                    growthBean.rateData.S_jump[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.S_agile
                    ? growthBean.rateData.S_agile[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">敏捷</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.S_agile &&
                    growthBean.rateData.S_agile[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.S_agile }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.S_agile &&
                    growthBean.rateData.S_agile[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.S_power
                    ? growthBean.rateData.S_power[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">力量</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.S_power &&
                    growthBean.rateData.S_power[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.S_power }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.S_power &&
                    growthBean.rateData.S_power[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.S_coordinate
                    ? growthBean.rateData.S_coordinate[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">协调</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.S_coordinate &&
                    growthBean.rateData.S_coordinate[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.S_coordinate }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.S_coordinate &&
                    growthBean.rateData.S_coordinate[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.S_balance
                    ? growthBean.rateData.S_balance[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">平衡</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.S_balance &&
                    growthBean.rateData.S_balance[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.S_balance }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.S_balance &&
                    growthBean.rateData.S_balance[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.S_ctrl_hand
                    ? growthBean.rateData.S_ctrl_hand[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">物控手</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.S_ctrl_hand &&
                    growthBean.rateData.S_ctrl_hand[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.S_ctrl_hand }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.S_ctrl_hand &&
                    growthBean.rateData.S_ctrl_hand[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.S_ctrl_foot
                    ? growthBean.rateData.S_ctrl_foot[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">物控脚</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.S_ctrl_foot &&
                    growthBean.rateData.S_ctrl_foot[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.S_ctrl_foot }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.S_ctrl_foot &&
                    growthBean.rateData.S_ctrl_foot[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="width601 column_base mrl40">
        <div class="row_center mt24 width_max">
          <div class="chart_title">幼儿成长分析</div>
          <div class="flex"></div>
          <img
            v-if="!choice6"
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_N.png"
            @click="choiceClick6(true)"
          />
          <img
            v-else
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_Y.png"
            @click="choiceClick6(false)"
          />
        </div>
        <div class="line mt20"></div>
        <div class="column_base width_max h500 grwoth_small">
          <div id="growthAnalyseChart" class="analyse_chart"></div>
          <div class="row width1400">
            <div class="flex column_center">
              <div class="analyse_tab">
                <span class="text2">{{
                  growthBean.times[0]
                    ? growthBean.times[0].replace("-", ".")
                    : ""
                }}</span>
              </div>
              <div v-if="growthBean.analyseData[0] == '--'" class="red_text">
                未测试
              </div>
            </div>
            <div class="flex column_center">
              <div class="analyse_tab">
                <span class="text2">{{
                  growthBean.times[1]
                    ? growthBean.times[1].replace("-", ".")
                    : ""
                }}</span>
              </div>
              <div v-if="growthBean.analyseData[1] == '--'" class="red_text">
                未测试
              </div>
            </div>
            <div class="flex column_center">
              <div class="analyse_tab">
                <span class="text2">{{
                  growthBean.times[2]
                    ? growthBean.times[2].replace("-", ".")
                    : ""
                }}</span>
              </div>
              <div v-if="growthBean.analyseData[2] == '--'" class="red_text">
                未测试
              </div>
            </div>
            <div class="flex column_center">
              <div class="analyse_tab">
                <span class="text2">{{
                  growthBean.times[3]
                    ? growthBean.times[3].replace("-", ".")
                    : ""
                }}</span>
              </div>
              <div v-if="growthBean.analyseData[3] == '--'" class="red_text">
                未测试
              </div>
            </div>
            <div class="flex column_center">
              <div class="analyse_tab">
                <span class="text2">{{
                  growthBean.times[4]
                    ? growthBean.times[4].replace("-", ".")
                    : ""
                }}</span>
              </div>
              <div v-if="growthBean.analyseData[4] == '--'" class="red_text">
                未测试
              </div>
            </div>
            <div class="flex column_center">
              <div class="analyse_tab">
                <span class="text2">{{
                  growthBean.times[5]
                    ? growthBean.times[5].replace("-", ".")
                    : ""
                }}</span>
              </div>
              <div v-if="growthBean.analyseData[5] == '--'" class="red_text">
                未测试
              </div>
            </div>
          </div>

          <div class="row mt23">
            <div class="label ml32" @click="clickGrowthAnalyseColor(1)">
              <div
                :class="
                  growthBean.anaIndex == 1
                    ? 'width16 color1 text_select'
                    : 'color_white width16 text_normal'
                "
              >
                {{ growthBean.names1[0] }}
              </div>
            </div>
            <div class="label ml32" @click="clickGrowthAnalyseColor(2)">
              <div
                :class="
                  growthBean.anaIndex == 2
                    ? 'width16 color2 text_select'
                    : 'color_white width16 text_normal'
                "
              >
                {{ growthBean.names1[1] }}
              </div>
            </div>
            <div class="label ml32" @click="clickGrowthAnalyseColor(3)">
              <div
                :class="
                  growthBean.anaIndex == 3
                    ? 'width16 color3 text_select'
                    : 'color_white width16 text_normal'
                "
              >
                {{ growthBean.names1[2] }}
              </div>
            </div>
            <div class="label ml32" @click="clickGrowthAnalyseColor(4)">
              <div
                :class="
                  growthBean.anaIndex == 4
                    ? 'width16 color4 text_select'
                    : 'color_white width16 text_normal'
                "
              >
                {{ growthBean.names1[3] }}
              </div>
            </div>
            <div class="label ml32" @click="clickGrowthAnalyseColor(5)">
              <div
                :class="
                  growthBean.anaIndex == 5
                    ? 'width16 color5 text_select'
                    : 'color_white width16 text_normal'
                "
              >
                {{ growthBean.names1[4] }}
              </div>
            </div>
            <div class="label ml32" @click="clickGrowthAnalyseColor(6)">
              <div
                :class="
                  growthBean.anaIndex == 6
                    ? 'width16 color6 text_select'
                    : 'color_white width16 text_normal'
                "
              >
                {{ growthBean.names1[5] }}
              </div>
            </div>
            <div class="label ml32" @click="clickGrowthAnalyseColor(7)">
              <div
                :class="
                  growthBean.anaIndex == 7
                    ? 'width16 color7 text_select'
                    : 'color_white width16 text_normal'
                "
              >
                {{ growthBean.names1[6] }}
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 0.1rem">
            <div class="label mrl10" @click="clickGrowthAnalyseColor(8)">
              <div
                :class="
                  growthBean.anaIndex == 8
                    ? 'width16 coloryd1 text_select'
                    : 'color_white width16 text_normal'
                "
              >
                {{ growthBean.names1[7] }}
              </div>
            </div>
            <div class="label mrl10" @click="clickGrowthAnalyseColor(9)">
              <div
                :class="
                  growthBean.anaIndex == 9
                    ? 'width16 coloryd2 text_select'
                    : 'color_white width16 text_normal'
                "
              >
                {{ growthBean.names1[8] }}
              </div>
            </div>
            <div class="label mrl10" @click="clickGrowthAnalyseColor(10)">
              <div
                :class="
                  growthBean.anaIndex == 10
                    ? 'width16 coloryd3 text_select'
                    : 'color_white width16 text_normal'
                "
              >
                {{ growthBean.names1[9] }}
              </div>
            </div>
            <div class="label mrl10" @click="clickGrowthAnalyseColor(11)">
              <div
                :class="
                  growthBean.anaIndex == 11
                    ? 'width16 coloryd4 text_select'
                    : 'color_white width16 text_normal'
                "
              >
                {{ growthBean.names1[10] }}
              </div>
            </div>
            <div class="label mrl10" @click="clickGrowthAnalyseColor(12)">
              <div
                :class="
                  growthBean.anaIndex == 12
                    ? 'width16 coloryd5 text_select'
                    : 'color_white width16 text_normal'
                "
              >
                {{ growthBean.names1[11] }}
              </div>
            </div>
            <div class="label mrl10" @click="clickGrowthAnalyseColor(13)">
              <div
                :class="
                  growthBean.anaIndex == 13
                    ? 'width16 coloryd6 text_select'
                    : 'color_white width16 text_normal'
                "
              >
                {{ growthBean.names1[12] }}
              </div>
            </div>
            <div class="label mrl10" @click="clickGrowthAnalyseColor(14)">
              <div
                :class="
                  growthBean.anaIndex == 14
                    ? 'width16 coloryd7 text_select'
                    : 'color_white width16 text_normal'
                "
              >
                {{ growthBean.names1[13] }}
              </div>
            </div>
            <div class="label mrl10" @click="clickGrowthAnalyseColor(15)">
              <div
                :class="
                  growthBean.anaIndex == 15
                    ? 'width16 coloryd8 text_select'
                    : 'color_white width16 text_normal'
                "
              >
                {{ growthBean.names1[14] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt41">
      <div class="width601 column_base">
        <div class="row_center mt24 width_max">
          <div class="chart_title">全国平均值</div>
          <div class="flex"></div>
          <img
            v-if="!choice7"
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_N.png"
            @click="choiceClick7(true)"
          />
          <img
            v-else
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_Y.png"
            @click="choiceClick7(false)"
          />
        </div>
        <div class="line mt20"></div>
        <div class="country_db_small h500">
          <div class="pos_rel">
            <div ref="ChinaMap" id="chinaMap" class="topLine"></div>
            <div class="label_row">
              <div class="column1">
                <div class="row_center" @click="clickMapTab(1)">
                  <div
                    :class="
                      countryDbBean.type == 1
                        ? 'width62 color1 text_select'
                        : 'alpha width62 text_normal'
                    "
                  >
                    身高
                  </div>
                </div>
                <div class="row_center mt6" @click="clickMapTab(2)">
                  <div
                    :class="
                      countryDbBean.type == 2
                        ? 'width62 color2 text_select'
                        : 'alpha width62 text_normal'
                    "
                  >
                    体重
                  </div>
                </div>
                <div class="row_center mt6" @click="clickMapTab(3)">
                  <div
                    :class="
                      countryDbBean.type == 3
                        ? 'width62 color3 text_select'
                        : 'alpha width62 text_normal'
                    "
                  >
                    视力
                  </div>
                </div>
                <div class="row_center mt6" @click="clickMapTab(4)">
                  <div
                    :class="
                      countryDbBean.type == 4
                        ? 'width62 color4 text_select'
                        : 'alpha width62 text_normal'
                    "
                  >
                    臂展
                  </div>
                </div>
                <div class="row_center mt6" @click="clickMapTab(5)">
                  <div
                    :class="
                      countryDbBean.type == 5
                        ? 'width62 color5 text_select'
                        : 'alpha width62 text_normal'
                    "
                  >
                    肺活量
                  </div>
                </div>
                <div class="row_center mt6" @click="clickMapTab(6)">
                  <div
                    :class="
                      countryDbBean.type == 6
                        ? 'width62 color6 text_select'
                        : 'alpha width62 text_normal'
                    "
                  >
                    胸围
                  </div>
                </div>
                <div class="row_center mt6" @click="clickMapTab(7)">
                  <div
                    :class="
                      countryDbBean.type == 7
                        ? 'width62 color7 text_select'
                        : 'alpha width62 text_normal'
                    "
                  >
                    体前屈
                  </div>
                </div>
                <div class="row_center mt6" @click="clickMapTab(8)">
                  <div
                    :class="
                      countryDbBean.type == 8
                        ? 'width62 color8 text_select'
                        : 'alpha width62 text_normal'
                    "
                  >
                    跑动
                  </div>
                </div>
                <div class="row_center mt6" @click="clickMapTab(9)">
                  <div
                    :class="
                      countryDbBean.type == 9
                        ? 'width62 color9 text_select'
                        : 'alpha width62 text_normal'
                    "
                  >
                    跳跃
                  </div>
                </div>
                <div class="row_center mt6" @click="clickMapTab(10)">
                  <div
                    :class="
                      countryDbBean.type == 10
                        ? 'width62 color10 text_select'
                        : 'alpha width62 text_normal'
                    "
                  >
                    敏捷
                  </div>
                </div>
                <div class="row_center mt6" @click="clickMapTab(11)">
                  <div
                    :class="
                      countryDbBean.type == 11
                        ? 'width62 color11 text_select'
                        : 'alpha width62 text_normal'
                    "
                  >
                    力量
                  </div>
                </div>
                <div class="row_center mt6" @click="clickMapTab(12)">
                  <div
                    :class="
                      countryDbBean.type == 12
                        ? 'width62 color12 text_select'
                        : 'alpha width62 text_normal'
                    "
                  >
                    协调
                  </div>
                </div>
                <div class="row_center mt6" @click="clickMapTab(13)">
                  <div
                    :class="
                      countryDbBean.type == 13
                        ? 'width62 color13 text_select'
                        : 'alpha width62 text_normal'
                    "
                  >
                    平衡
                  </div>
                </div>
                <div class="row_center mt6" @click="clickMapTab(14)">
                  <div
                    :class="
                      countryDbBean.type == 14
                        ? 'width62 color14 text_select'
                        : 'alpha width62 text_normal'
                    "
                  >
                    物控手
                  </div>
                </div>
                <div class="row_center mt6" @click="clickMapTab(15)">
                  <div
                    :class="
                      countryDbBean.type == 15
                        ? 'width62 color15 text_select'
                        : 'alpha width62 text_normal'
                    "
                  >
                    物控脚
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="width601 column_base mrl40">
        <div class="row_center mt24 width_max">
          <div class="chart_title">上月平均值对比</div>
          <div class="flex"></div>
          <img
            v-if="!choice8"
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_N.png"
            @click="choiceClick8(true)"
          />
          <img
            v-else
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_Y.png"
            @click="choiceClick8(false)"
          />
        </div>
        <div class="line mt20"></div>
        <div
          class="baseLine growth h500"
          :style="{
            transform: `scale(${0.5}) translate(0,-30%)`,
            '-webkit-transform': `scale(${0.5})) translate(0,-30%)`,
            '-moz-transform': `scale(${0.5}) translate(0,-30%)`,
            '-o-transform': `scale(${0.5}) translate(0,-30%)`,
            '-ms-transform': `scale(${0.5}) translate(0,-30%)`,
          }"
        >
          <div class="width514">
            <div class="top_lay">
              <div class="top_bg pos_abs">
                <div class="middle">上月体质平均值</div>
              </div>
            </div>
            <div class="hint1">上月体质测试全国平均值对比园所平均值</div>
            <div class="num_lay mt36">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.B_height
                    ? countryDbBean.rateData.B_height[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">身高</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.B_height &&
                    countryDbBean.rateData.B_height[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.B_height[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.B_weight
                    ? countryDbBean.rateData.B_weight[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">体重</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.B_weight &&
                    countryDbBean.rateData.B_weight[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.B_weight[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.B_eye_L
                    ? countryDbBean.rateData.B_eye_L[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">视力</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.B_eye_L &&
                    countryDbBean.rateData.B_eye_L[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.B_eye_L[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.B_arm
                    ? countryDbBean.rateData.B_arm[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">臂展</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.B_arm &&
                    countryDbBean.rateData.B_arm[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.B_arm[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.B_lung
                    ? countryDbBean.rateData.B_lung[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">肺活量</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.B_lung &&
                    countryDbBean.rateData.B_lung[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.B_lung[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.B_bust
                    ? countryDbBean.rateData.B_bust[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">胸围</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.B_bust &&
                    countryDbBean.rateData.B_bust[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.B_bust[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.B_flexion
                    ? countryDbBean.rateData.B_flexion[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">体前屈</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.B_flexion &&
                    countryDbBean.rateData.B_flexion[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.B_flexion[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
          </div>
          <div class="width514 ml55">
            <div class="top_lay">
              <div class="top_bg pos_abs">
                <div class="middle">上月运动平均值</div>
              </div>
            </div>
            <div class="hint1">上月运动测试全国平均值对比园所平均值</div>
            <div class="num_lay mt36">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.S_run
                    ? countryDbBean.rateData.S_run[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">跑动</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.S_run &&
                    countryDbBean.rateData.S_run[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.S_run[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.S_jump
                    ? countryDbBean.rateData.S_jump[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">跳跃</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.S_jump &&
                    countryDbBean.rateData.S_jump[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.S_jump[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.S_agile
                    ? countryDbBean.rateData.S_agile[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">敏捷</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.S_agile &&
                    countryDbBean.rateData.S_agile[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.S_agile[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.S_power
                    ? countryDbBean.rateData.S_power[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">力量</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.S_power &&
                    countryDbBean.rateData.S_power[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.S_power[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.S_coordinate
                    ? countryDbBean.rateData.S_coordinate[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">协调</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.S_coordinate &&
                    countryDbBean.rateData.S_coordinate[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.S_coordinate[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.S_balance
                    ? countryDbBean.rateData.S_balance[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">平衡</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.S_balance &&
                    countryDbBean.rateData.S_balance[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.S_balance[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.S_ctrl_hand
                    ? countryDbBean.rateData.S_ctrl_hand[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">物控手</div>
              </div>
              <div
                v-if="countryDbBean.rateData.S_ctrl_hand"
                class="left_lay center"
              >
                <span
                  v-if="
                    countryDbBean.rateData.S_ctrl_hand &&
                    countryDbBean.rateData.S_ctrl_hand[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.S_ctrl_hand[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.S_ctrl_foot
                    ? countryDbBean.rateData.S_ctrl_foot[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">物控脚</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.S_ctrl_foot &&
                    countryDbBean.rateData.S_ctrl_foot[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.S_ctrl_foot[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt41">
      <div class="width601 column_base">
        <div class="row_center mt24 width_max">
          <div class="chart_title">全国平均值对比</div>
          <div class="flex"></div>
          <img
            v-if="!choice9"
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_N.png"
            @click="choiceClick9(true)"
          />
          <img
            v-else
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_Y.png"
            @click="choiceClick9(false)"
          />
        </div>
        <div class="line mt20"></div>
        <div class="country_db_small h500 width601">
          <div id="countryAnalyseChart" class="analyse_chart"></div>
          <div class="row width1400">
            <div class="flex column_center">
              <div class="analyse_tab">
                <span class="text2">{{
                  countryDbBean.times[0]
                    ? countryDbBean.times[0].replace("-", ".")
                    : ""
                }}</span>
              </div>
              <div v-if="!countryDbBean.analyseData[0]" class="red_text">
                未测试
              </div>
            </div>
            <div class="flex column_center">
              <div class="analyse_tab">
                <span class="text2">{{
                  countryDbBean.times[1]
                    ? countryDbBean.times[1].replace("-", ".")
                    : ""
                }}</span>
              </div>
              <div v-if="!countryDbBean.analyseData[1]" class="red_text">
                未测试
              </div>
            </div>
            <div class="flex column_center">
              <div class="analyse_tab">
                <span class="text2">{{
                  countryDbBean.times[2]
                    ? countryDbBean.times[2].replace("-", ".")
                    : ""
                }}</span>
              </div>
              <div v-if="!countryDbBean.analyseData[2]" class="red_text">
                未测试
              </div>
            </div>
            <div class="flex column_center">
              <div class="analyse_tab">
                <span class="text2">{{
                  countryDbBean.times[3]
                    ? countryDbBean.times[3].replace("-", ".")
                    : ""
                }}</span>
              </div>
              <div v-if="!countryDbBean.analyseData[3]" class="red_text">
                未测试
              </div>
            </div>
            <div class="flex column_center">
              <div class="analyse_tab">
                <span class="text2">{{
                  countryDbBean.times[4]
                    ? countryDbBean.times[4].replace("-", ".")
                    : ""
                }}</span>
              </div>
              <div v-if="!countryDbBean.analyseData[4]" class="red_text">
                未测试
              </div>
            </div>
            <div class="flex column_center">
              <div class="analyse_tab">
                <span class="text2">{{
                  countryDbBean.times[5]
                    ? countryDbBean.times[5].replace("-", ".")
                    : ""
                }}</span>
              </div>
              <div v-if="!countryDbBean.analyseData[5]" class="red_text">
                未测试
              </div>
            </div>
          </div>
          <div class="row center mt23">
            <div class="label ml32" @click="clickAnalyseType(1)">
              <div
                :class="
                  countryDbBean.anaIndex == 1
                    ? 'width16 ys_color1 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ countryDbBean.names1[0] }}
              </div>
            </div>
            <div class="label ml32" @click="clickAnalyseType(2)">
              <div
                :class="
                  countryDbBean.anaIndex == 2
                    ? 'width16 ys_color2 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ countryDbBean.names1[1] }}
              </div>
            </div>
            <div class="label ml32" @click="clickAnalyseType(3)">
              <div
                :class="
                  countryDbBean.anaIndex == 3
                    ? 'width16 ys_color3 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ countryDbBean.names1[2] }}
              </div>
            </div>
            <div class="label ml32" @click="clickAnalyseType(4)">
              <div
                :class="
                  countryDbBean.anaIndex == 4
                    ? 'width16 ys_color4 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ countryDbBean.names1[3] }}
              </div>
            </div>
            <div class="label ml32" @click="clickAnalyseType(5)">
              <div
                :class="
                  countryDbBean.anaIndex == 5
                    ? 'width16 ys_color5 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ countryDbBean.names1[4] }}
              </div>
            </div>
            <div class="label ml32" @click="clickAnalyseType(6)">
              <div
                :class="
                  countryDbBean.anaIndex == 6
                    ? 'width16 ys_color6 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ countryDbBean.names1[5] }}
              </div>
            </div>
            <div class="label ml32" @click="clickAnalyseType(7)">
              <div
                :class="
                  countryDbBean.anaIndex == 7
                    ? 'width16 ys_color7 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ countryDbBean.names1[6] }}
              </div>
            </div>
          </div>
          <div class="row center" style="margin-top: 0.1rem">
            <div class="label mrl10" @click="clickAnalyseType(8)">
              <div
                :class="
                  countryDbBean.anaIndex == 8
                    ? 'width16 ys_color8 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ countryDbBean.names1[7] }}
              </div>
            </div>
            <div class="label mrl10" @click="clickAnalyseType(9)">
              <div
                :class="
                  countryDbBean.anaIndex == 9
                    ? 'width16 ys_color9 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ countryDbBean.names1[8] }}
              </div>
            </div>
            <div class="label mrl10" @click="clickAnalyseType(10)">
              <div
                :class="
                  countryDbBean.anaIndex == 10
                    ? 'width16 ys_color10 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ countryDbBean.names1[9] }}
              </div>
            </div>
            <div class="label mrl10" @click="clickAnalyseType(11)">
              <div
                :class="
                  countryDbBean.anaIndex == 11
                    ? 'width16 ys_color11 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ countryDbBean.names1[10] }}
              </div>
            </div>
            <div class="label mrl10" @click="clickAnalyseType(12)">
              <div
                :class="
                  countryDbBean.anaIndex == 12
                    ? 'width16 ys_color12 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ countryDbBean.names1[11] }}
              </div>
            </div>
            <div class="label mrl10" @click="clickAnalyseType(13)">
              <div
                :class="
                  countryDbBean.anaIndex == 13
                    ? 'width16 ys_color13 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ countryDbBean.names1[12] }}
              </div>
            </div>
            <div class="label mrl10" @click="clickAnalyseType(14)">
              <div
                :class="
                  countryDbBean.anaIndex == 14
                    ? 'width16 ys_color14 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ countryDbBean.names1[13] }}
              </div>
            </div>
            <div class="label mrl10" @click="clickAnalyseType(15)">
              <div
                :class="
                  countryDbBean.anaIndex == 15
                    ? 'width16 ys_color15 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ countryDbBean.names1[14] }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="width601 column_base mrl40">
        <div class="row_center mt24 width_max">
          <div class="chart_title">平均值对比</div>
          <div class="flex"></div>
          <img
            v-if="!choice10"
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_N.png"
            @click="choiceClick10(true)"
          />
          <img
            v-else
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_Y.png"
            @click="choiceClick10(false)"
          />
        </div>
        <div class="line mt20"></div>
        <div class="column1 h500 yecf_small bgf1">
          <div id="barChart" class="topBar"></div>
          <div class="row width_max">
            <div class="column1">
              <div class="row">
                <div v-if="yecfBean.monthType == 1" class="month_select">
                  <div class="text1">
                    {{ yecfBean.monthList ? yecfBean.monthList[0] : "" }}
                  </div>
                  <div class="text2">月</div>
                </div>
                <div v-else class="month_normal" @click="monthClick(1)">
                  <div class="text1">
                    {{ yecfBean.monthList ? yecfBean.monthList[0] : "" }}
                  </div>
                  <div class="text2">月</div>
                </div>
                <div v-if="yecfBean.monthType == 2" class="month_select">
                  <div class="text1">
                    {{ yecfBean.monthList ? yecfBean.monthList[1] : "" }}
                  </div>
                  <div class="text2">月</div>
                </div>
                <div v-else class="month_normal" @click="monthClick(2)">
                  <div class="text1">
                    {{ yecfBean.monthList ? yecfBean.monthList[1] : "" }}
                  </div>
                  <div class="text2">月</div>
                </div>
                <div v-if="yecfBean.monthType == 3" class="month_select">
                  <div class="text1">
                    {{ yecfBean.monthList ? yecfBean.monthList[2] : "" }}
                  </div>
                  <div class="text2">月</div>
                </div>
                <div v-else class="month_normal" @click="monthClick(3)">
                  <div class="text1">
                    {{ yecfBean.monthList ? yecfBean.monthList[2] : "" }}
                  </div>
                  <div class="text2">月</div>
                </div>
                <div v-if="yecfBean.monthType == 4" class="month_select">
                  <div class="text1">
                    {{ yecfBean.monthList ? yecfBean.monthList[3] : "" }}
                  </div>
                  <div class="text2">月</div>
                </div>
                <div v-else class="month_normal" @click="monthClick(4)">
                  <div class="text1">
                    {{ yecfBean.monthList ? yecfBean.monthList[3] : "" }}
                  </div>
                  <div class="text2">月</div>
                </div>
                <div v-if="yecfBean.monthType == 5" class="month_select">
                  <div class="text1">
                    {{ yecfBean.monthList ? yecfBean.monthList[4] : "" }}
                  </div>
                  <div class="text2">月</div>
                </div>
                <div v-else class="month_normal" @click="monthClick(5)">
                  <div class="text1">
                    {{ yecfBean.monthList ? yecfBean.monthList[4] : "" }}
                  </div>
                  <div class="text2">月</div>
                </div>
                <div v-if="yecfBean.monthType == 6" class="month_select">
                  <div class="text1">
                    {{ yecfBean.monthList ? yecfBean.monthList[5] : "" }}
                  </div>
                  <div class="text2">月</div>
                </div>
                <div v-else class="month_normal" @click="monthClick(6)">
                  <div class="text1">
                    {{ yecfBean.monthList ? yecfBean.monthList[5] : "" }}
                  </div>
                  <div class="text2">月</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt41">
      <div class="width601 column_base">
        <div class="row_center mt24 width_max">
          <div class="chart_title">上月综合评分对比</div>
          <div class="flex"></div>
          <img
            v-if="!choice11"
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_N.png"
            @click="choiceClick11(true)"
          />
          <img
            v-else
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_Y.png"
            @click="choiceClick11(false)"
          />
        </div>
        <div class="line mt20"></div>
        <div
          class="baseLine yecf_small h500"
          :style="{
            transform: `scale(${0.5}) translate(0,-30%)`,
            '-webkit-transform': `scale(${0.5})) translate(0,-30%)`,
            '-moz-transform': `scale(${0.5}) translate(0,-30%)`,
            '-o-transform': `scale(${0.5}) translate(0,-30%)`,
            '-ms-transform': `scale(${0.5}) translate(0,-30%)`,
          }"
        >
          <div class="width514">
            <div class="top_lay">
              <div class="top_bg pos_abs">
                <div class="middle">上月体质综合评分</div>
              </div>
            </div>
            <div class="hint1">上月体质测试全国综合评分对比园所综合评分</div>
            <div class="num_lay mt36">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.B_judge_height
                    ? yecfBean.rateData.B_judge_height[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">身高</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    yecfBean.rateData.B_judge_height &&
                    yecfBean.rateData.B_judge_height[1]
                  "
                  class="text"
                  >{{ yecfBean.rateData.B_judge_height[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.B_judge_weight
                    ? yecfBean.rateData.B_judge_weight[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">体重</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    yecfBean.rateData.B_judge_weight &&
                    yecfBean.rateData.B_judge_weight[1]
                  "
                  class="text"
                  >{{ yecfBean.rateData.B_judge_weight[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.B_judge_eye
                    ? yecfBean.rateData.B_judge_eye[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">视力</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    yecfBean.rateData.B_judge_eye &&
                    yecfBean.rateData.B_judge_eye[1]
                  "
                  class="text"
                  >{{ yecfBean.rateData.B_judge_eye[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.B_judge_arm
                    ? yecfBean.rateData.B_judge_arm[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">臂展</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    yecfBean.rateData.B_judge_arm &&
                    yecfBean.rateData.B_judge_arm[1]
                  "
                  class="text"
                  >{{ yecfBean.rateData.B_judge_arm[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.B_judge_lung
                    ? yecfBean.rateData.B_judge_lung[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">肺活量</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    yecfBean.rateData.B_judge_lung &&
                    yecfBean.rateData.B_judge_lung[1]
                  "
                  class="text"
                  >{{ yecfBean.rateData.B_judge_lung[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.B_judge_bust
                    ? yecfBean.rateData.B_judge_bust[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">胸围</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    yecfBean.rateData.B_judge_bust &&
                    yecfBean.rateData.B_judge_bust[1]
                  "
                  class="text"
                  >{{ yecfBean.rateData.B_judge_bust[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.B_judge_flexion
                    ? yecfBean.rateData.B_judge_flexion[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">体前屈</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    yecfBean.rateData.B_judge_flexion &&
                    yecfBean.rateData.B_judge_flexion[1]
                  "
                  class="text"
                  >{{ yecfBean.rateData.B_judge_flexion[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
          </div>
          <div class="width514 ml55">
            <div class="top_lay">
              <div class="top_bg pos_abs">
                <div class="middle">上月运动综合评分</div>
              </div>
            </div>
            <div class="hint1">上月运动测试全国综合评分对比园所综合评分</div>
            <div class="num_lay mt36">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.S_judge_run
                    ? yecfBean.rateData.S_judge_run[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">跑动</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    yecfBean.rateData.S_judge_run &&
                    yecfBean.rateData.S_judge_run[1]
                  "
                  class="text"
                  >{{ yecfBean.rateData.S_judge_run[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.S_judge_jump
                    ? yecfBean.rateData.S_judge_jump[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">跳跃</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    yecfBean.rateData.S_judge_jump &&
                    yecfBean.rateData.S_judge_jump[1]
                  "
                  class="text"
                  >{{ yecfBean.rateData.S_judge_jump[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.S_judge_agile
                    ? yecfBean.rateData.S_judge_agile[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">敏捷</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    yecfBean.rateData.S_judge_agile &&
                    yecfBean.rateData.S_judge_agile[1]
                  "
                  class="text"
                  >{{ yecfBean.rateData.S_judge_agile[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.S_judge_power
                    ? yecfBean.rateData.S_judge_power[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">力量</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    yecfBean.rateData.S_judge_power &&
                    yecfBean.rateData.S_judge_power[1]
                  "
                  class="text"
                  >{{ yecfBean.rateData.S_judge_power[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.S_judge_coordiate
                    ? yecfBean.rateData.S_judge_coordiate[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">协调</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    yecfBean.rateData.S_judge_coordiate &&
                    yecfBean.rateData.S_judge_coordiate[1]
                  "
                  class="text"
                  >{{ yecfBean.rateData.S_judge_coordiate[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.S_judge_balance
                    ? yecfBean.rateData.S_judge_balance[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">平衡</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    yecfBean.rateData.S_judge_balance &&
                    yecfBean.rateData.S_judge_balance[1]
                  "
                  class="text"
                  >{{ yecfBean.rateData.S_judge_balance[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.S_judge_ctrlHand
                    ? yecfBean.rateData.S_judge_ctrlHand[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">物控手</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    yecfBean.rateData.S_judge_ctrlHand &&
                    yecfBean.rateData.S_judge_ctrlHand[1]
                  "
                  class="text"
                  >{{ yecfBean.rateData.S_judge_ctrlHand[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.S_judge_ctrlFoot
                    ? yecfBean.rateData.S_judge_ctrlFoot[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">物控脚</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    yecfBean.rateData.S_judge_ctrlFoot &&
                    yecfBean.rateData.S_judge_ctrlFoot[1]
                  "
                  class="text"
                  >{{ yecfBean.rateData.S_judge_ctrlFoot[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="width601 column_base mrl40">
        <div class="row_center mt24 width_max">
          <div class="chart_title">综合评分分析</div>
          <div class="flex"></div>
          <img
            v-if="!choice12"
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_N.png"
            @click="choiceClick12(true)"
          />
          <img
            v-else
            class="normal_img"
            src="../../assets/img/home/home/PES/Icon_Choose_Y.png"
            @click="choiceClick12(false)"
          />
        </div>
        <div class="line mt20"></div>
        <div class="country_db_small h500 width601">
          <div id="yecfAnalyseChart" class="analyse_chart"></div>
          <div class="row width1400">
            <div class="flex column_center">
              <div class="analyse_tab">
                <span class="text2">{{
                  yecfBean.times[0] ? yecfBean.times[0].replace("-", ".") : ""
                }}</span>
              </div>
              <div v-if="!yecfBean.analyseData[0]" class="red_text">未测试</div>
            </div>
            <div class="flex column_center">
              <div class="analyse_tab">
                <span class="text2">{{
                  yecfBean.times[1] ? yecfBean.times[1].replace("-", ".") : ""
                }}</span>
              </div>
              <div v-if="!yecfBean.analyseData[1]" class="red_text">未测试</div>
            </div>
            <div class="flex column_center">
              <div class="analyse_tab">
                <span class="text2">{{
                  yecfBean.times[2] ? yecfBean.times[2].replace("-", ".") : ""
                }}</span>
              </div>
              <div v-if="!yecfBean.analyseData[2]" class="red_text">未测试</div>
            </div>
            <div class="flex column_center">
              <div class="analyse_tab">
                <span class="text2">{{
                  yecfBean.times[3] ? yecfBean.times[3].replace("-", ".") : ""
                }}</span>
              </div>
              <div v-if="!yecfBean.analyseData[3]" class="red_text">未测试</div>
            </div>
            <div class="flex column_center">
              <div class="analyse_tab">
                <span class="text2">{{
                  yecfBean.times[4] ? yecfBean.times[4].replace("-", ".") : ""
                }}</span>
              </div>
              <div v-if="!yecfBean.analyseData[4]" class="red_text">未测试</div>
            </div>
            <div class="flex column_center">
              <div class="analyse_tab">
                <span class="text2">{{
                  yecfBean.times[5] ? yecfBean.times[5].replace("-", ".") : ""
                }}</span>
              </div>
              <div v-if="!yecfBean.analyseData[5]" class="red_text">未测试</div>
            </div>
          </div>
          <div class="row center mt23">
            <div class="label ml32" @click="clickAnalyseType3(1)">
              <div
                :class="
                  yecfBean.anaIndex == 1
                    ? 'width16 ys_color1 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ yecfBean.names1[0] }}
              </div>
            </div>
            <div class="label ml32" @click="clickAnalyseType3(2)">
              <div
                :class="
                  yecfBean.anaIndex == 2
                    ? 'width16 ys_color2 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ yecfBean.names1[1] }}
              </div>
            </div>
            <div class="label ml32" @click="clickAnalyseType3(3)">
              <div
                :class="
                  yecfBean.anaIndex == 3
                    ? 'width16 ys_color3 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ yecfBean.names1[2] }}
              </div>
            </div>
            <div class="label ml32" @click="clickAnalyseType3(4)">
              <div
                :class="
                  yecfBean.anaIndex == 4
                    ? 'width16 ys_color4 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ yecfBean.names1[3] }}
              </div>
            </div>
            <div class="label ml32" @click="clickAnalyseType3(5)">
              <div
                :class="
                  yecfBean.anaIndex == 5
                    ? 'width16 ys_color5 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ yecfBean.names1[4] }}
              </div>
            </div>
            <div class="label ml32" @click="clickAnalyseType3(6)">
              <div
                :class="
                  yecfBean.anaIndex == 6
                    ? 'width16 ys_color6 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ yecfBean.names1[5] }}
              </div>
            </div>
            <div class="label ml32" @click="clickAnalyseType3(7)">
              <div
                :class="
                  yecfBean.anaIndex == 7
                    ? 'width16 ys_color7 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ yecfBean.names1[6] }}
              </div>
            </div>
          </div>
          <div class="row center" style="margin-top: 0.1rem">
            <div class="label mrl10" @click="clickAnalyseType3(8)">
              <div
                :class="
                  yecfBean.anaIndex == 8
                    ? 'width16 ys_color8 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ yecfBean.names1[7] }}
              </div>
            </div>
            <div class="label mrl10" @click="clickAnalyseType3(9)">
              <div
                :class="
                  yecfBean.anaIndex == 9
                    ? 'width16 ys_color9 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ yecfBean.names1[8] }}
              </div>
            </div>
            <div class="label mrl10" @click="clickAnalyseType3(10)">
              <div
                :class="
                  yecfBean.anaIndex == 10
                    ? 'width16 ys_color10 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ yecfBean.names1[9] }}
              </div>
            </div>
            <div class="label mrl10" @click="clickAnalyseType3(11)">
              <div
                :class="
                  yecfBean.anaIndex == 11
                    ? 'width16 ys_color11 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ yecfBean.names1[10] }}
              </div>
            </div>
            <div class="label mrl10" @click="clickAnalyseType3(12)">
              <div
                :class="
                  yecfBean.anaIndex == 12
                    ? 'width16 ys_color12 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ yecfBean.names1[11] }}
              </div>
            </div>
            <div class="label mrl10" @click="clickAnalyseType3(13)">
              <div
                :class="
                  yecfBean.anaIndex == 13
                    ? 'width16 ys_color13 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ yecfBean.names1[12] }}
              </div>
            </div>
            <div class="label mrl10" @click="clickAnalyseType3(14)">
              <div
                :class="
                  yecfBean.anaIndex == 14
                    ? 'width16 ys_color14 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ yecfBean.names1[13] }}
              </div>
            </div>
            <div class="label mrl10" @click="clickAnalyseType3(15)">
              <div
                :class="
                  yecfBean.anaIndex == 15
                    ? 'width16 ys_color15 text_select1'
                    : 'color_white width16 text_normal1'
                "
              >
                {{ yecfBean.names1[14] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="white_bg">
      <div class="create" @click="createReport">生成报告</div>
      <div class="downLoad" @click="shotPic">下 载</div>
    </div>
    <div id="downLoad" class="column">
      <div v-show="bigChoice1" class="row mt100 big">
        <div v-if="monthList.length != 0" class="column_base">
          <div
            v-if="ysMonthType == monthList[0].value"
            class="month_select1 row center mt25"
          >
            <div class="circle1"></div>
            <div class="top_text">{{ monthList[0].name }}</div>
          </div>
          <div
            v-else
            class="month_normal1 row center mt25"
            @click="clickYsMonth(monthList[0])"
          >
            <div class="circle1 small_circle"></div>
            <div class="top_text">{{ monthList[0].name }}</div>
          </div>
          <div
            v-if="ysMonthType == monthList[1].value"
            class="month_select2 row center"
          >
            <div class="circle2"></div>
            <div class="top_text">{{ monthList[1].name }}</div>
          </div>
          <div
            v-else
            class="month_normal1 row center"
            @click="clickYsMonth(monthList[1])"
          >
            <div class="circle2 small_circle"></div>
            <div class="top_text">{{ monthList[1].name }}</div>
          </div>
          <div
            v-if="ysMonthType == monthList[2].value"
            class="month_select3 row center"
          >
            <div class="circle3"></div>
            <div class="top_text">{{ monthList[2].name }}</div>
          </div>
          <div
            v-else
            class="month_normal1 row center"
            @click="clickYsMonth(monthList[2])"
          >
            <div class="circle3 small_circle"></div>
            <div class="top_text">{{ monthList[2].name }}</div>
          </div>
          <div
            v-if="ysMonthType == monthList[3].value"
            class="month_select4 row center"
          >
            <div class="circle4"></div>
            <div class="top_text">{{ monthList[3].name }}</div>
          </div>
          <div
            v-else
            class="month_normal1 row center"
            @click="clickYsMonth(monthList[3])"
          >
            <div class="circle4 small_circle"></div>
            <div class="top_text">{{ monthList[3].name }}</div>
          </div>
          <div
            v-if="ysMonthType == monthList[4].value"
            class="month_select5 row center"
          >
            <div class="circle5"></div>
            <div class="top_text">{{ monthList[4].name }}</div>
          </div>
          <div
            v-else
            class="month_normal1 row center"
            @click="clickYsMonth(monthList[4])"
          >
            <div class="circle5 small_circle"></div>
            <div class="top_text">{{ monthList[4].name }}</div>
          </div>
          <div
            v-if="ysMonthType == monthList[5].value"
            class="month_select6 row center"
          >
            <div class="circle6"></div>
            <div class="top_text">{{ monthList[5].name }}</div>
          </div>
          <div
            v-else
            class="month_normal1 row center"
            @click="clickYsMonth(monthList[5])"
          >
            <div class="circle6 small_circle"></div>
            <div class="top_text">{{ monthList[5].name }}</div>
          </div>
        </div>
        <div class="image_c">
          <div class="title">园所综合报告</div>
          <div class="title1 mt60">园所总人数</div>
          <div v-if="monthList.length != 0" class="image_l">
            <img
              v-if="ysMonthType == monthList[0].value"
              class="image"
              src="../../assets/img/home/home/PES/Pic_TB_A.png"
            />
            <div v-if="ysMonthType == monthList[0].value" class="num1">
              {{ ysNum1 }}
            </div>
            <div v-if="ysMonthType == monthList[0].value" class="num2">
              {{ ysNum2 }}
            </div>
            <div v-if="ysMonthType == monthList[0].value" class="num3">
              {{ ysNum3 }}
            </div>
            <img
              v-if="ysMonthType == monthList[1].value"
              class="image"
              src="../../assets/img/home/home/PES/Pic_TB_B.png"
            />
            <div v-if="ysMonthType == monthList[1].value" class="num4">
              {{ ysNum1 }}
            </div>
            <div v-if="ysMonthType == monthList[1].value" class="num5">
              {{ ysNum2 }}
            </div>
            <div v-if="ysMonthType == monthList[1].value" class="num6">
              {{ ysNum3 }}
            </div>
            <img
              v-if="ysMonthType == monthList[2].value"
              class="image"
              src="../../assets/img/home/home/PES/Pic_TB_C.png"
            />
            <div v-if="ysMonthType == monthList[2].value" class="num7">
              {{ ysNum1 }}
            </div>
            <div v-if="ysMonthType == monthList[2].value" class="num8">
              {{ ysNum2 }}
            </div>
            <div v-if="ysMonthType == monthList[2].value" class="num9">
              {{ ysNum3 }}
            </div>
            <img
              v-if="ysMonthType == monthList[3].value"
              class="image"
              src="../../assets/img/home/home/PES/Pic_TB_D.png"
            />
            <div v-if="ysMonthType == monthList[3].value" class="num10">
              {{ ysNum1 }}
            </div>
            <div v-if="ysMonthType == monthList[3].value" class="num11">
              {{ ysNum2 }}
            </div>
            <div v-if="ysMonthType == monthList[3].value" class="num12">
              {{ ysNum3 }}
            </div>
            <img
              v-if="ysMonthType == monthList[4].value"
              class="image"
              src="../../assets/img/home/home/PES/Pic_TB_E.png"
            />
            <div v-if="ysMonthType == monthList[4].value" class="num13">
              {{ ysNum1 }}
            </div>
            <div v-if="ysMonthType == monthList[4].value" class="num14">
              {{ ysNum2 }}
            </div>
            <div v-if="ysMonthType == monthList[4].value" class="num15">
              {{ ysNum3 }}
            </div>
            <img
              v-if="ysMonthType == monthList[5].value"
              class="image"
              src="../../assets/img/home/home/PES/Pic_TB_F.png"
            />
            <div v-if="ysMonthType == monthList[5].value" class="num16">
              {{ ysNum1 }}
            </div>
            <div v-if="ysMonthType == monthList[5].value" class="num17">
              {{ ysNum2 }}
            </div>
            <div v-if="ysMonthType == monthList[5].value" class="num18">
              {{ ysNum3 }}
            </div>
          </div>
          <div class="row_center" style="width: 100%">
            <div class="title1 flex">测试总人数</div>
            <div class="title1">测试合格人数</div>
          </div>
        </div>
      </div>
      <div v-show="bigChoice2" class="row mt100 big">
        <div v-if="monthList.length != 0" class="column_base">
          <div
            v-if="bjMonthType == monthList[0].value"
            class="month_select1 row center mt25"
          >
            <div class="circle1"></div>
            <div class="top_text">{{ monthList[0].name }}</div>
          </div>
          <div
            v-else
            class="month_normal1 row center mt25"
            @click="clickBjMonth(monthList[0])"
          >
            <div class="circle1 small_circle"></div>
            <div class="top_text">{{ monthList[0].name }}</div>
          </div>
          <div
            v-if="bjMonthType == monthList[1].value"
            class="month_select2 row center"
          >
            <div class="circle2"></div>
            <div class="top_text">{{ monthList[1].name }}</div>
          </div>
          <div
            v-else
            class="month_normal1 row center"
            @click="clickBjMonth(monthList[1])"
          >
            <div class="circle2 small_circle"></div>
            <div class="top_text">{{ monthList[1].name }}</div>
          </div>
          <div
            v-if="bjMonthType == monthList[2].value"
            class="month_select3 row center"
          >
            <div class="circle3"></div>
            <div class="top_text">{{ monthList[2].name }}</div>
          </div>
          <div
            v-else
            class="month_normal1 row center"
            @click="clickBjMonth(monthList[2])"
          >
            <div class="circle3 small_circle"></div>
            <div class="top_text">{{ monthList[2].name }}</div>
          </div>
          <div
            v-if="bjMonthType == monthList[3].value"
            class="month_select4 row center"
          >
            <div class="circle4"></div>
            <div class="top_text">{{ monthList[3].name }}</div>
          </div>
          <div
            v-else
            class="month_normal1 row center"
            @click="clickBjMonth(monthList[3])"
          >
            <div class="circle4 small_circle"></div>
            <div class="top_text">{{ monthList[3].name }}</div>
          </div>
          <div
            v-if="bjMonthType == monthList[4].value"
            class="month_select5 row center"
          >
            <div class="circle5"></div>
            <div class="top_text">{{ monthList[4].name }}</div>
          </div>
          <div
            v-else
            class="month_normal1 row center"
            @click="clickBjMonth(monthList[4])"
          >
            <div class="circle5 small_circle"></div>
            <div class="top_text">{{ monthList[4].name }}</div>
          </div>
          <div
            v-if="bjMonthType == monthList[5].value"
            class="month_select6 row center"
          >
            <div class="circle6"></div>
            <div class="top_text">{{ monthList[5].name }}</div>
          </div>
          <div
            v-else
            class="month_normal1 row center"
            @click="clickBjMonth(monthList[5])"
          >
            <div class="circle6 small_circle"></div>
            <div class="top_text">{{ monthList[5].name }}</div>
          </div>
        </div>
        <div class="image_c">
          <div class="title">班级综合报告</div>
          <div class="title1 mt60">班级总人数</div>
          <div v-if="monthList.length != 0" class="image_l">
            <img
              v-if="bjMonthType == monthList[0].value"
              class="image"
              src="../../assets/img/home/home/PES/Pic_TB_A.png"
            />
            <div v-if="bjMonthType == monthList[0].value" class="num1">
              {{ bjNum1 }}
            </div>
            <div v-if="bjMonthType == monthList[0].value" class="num2">
              {{ bjNum2 }}
            </div>
            <div v-if="bjMonthType == monthList[0].value" class="num3">
              {{ bjNum3 }}
            </div>
            <img
              v-if="bjMonthType == monthList[1].value"
              class="image"
              src="../../assets/img/home/home/PES/Pic_TB_B.png"
            />
            <div v-if="bjMonthType == monthList[1].value" class="num4">
              {{ bjNum1 }}
            </div>
            <div v-if="bjMonthType == monthList[1].value" class="num5">
              {{ bjNum2 }}
            </div>
            <div v-if="bjMonthType == monthList[1].value" class="num6">
              {{ bjNum3 }}
            </div>
            <img
              v-if="bjMonthType == monthList[2].value"
              class="image"
              src="../../assets/img/home/home/PES/Pic_TB_C.png"
            />
            <div v-if="bjMonthType == monthList[2].value" class="num7">
              {{ bjNum1 }}
            </div>
            <div v-if="bjMonthType == monthList[2].value" class="num8">
              {{ bjNum2 }}
            </div>
            <div v-if="bjMonthType == monthList[2].value" class="num9">
              {{ bjNum3 }}
            </div>
            <img
              v-if="bjMonthType == monthList[3].value"
              class="image"
              src="../../assets/img/home/home/PES/Pic_TB_D.png"
            />
            <div v-if="bjMonthType == monthList[3].value" class="num10">
              {{ bjNum1 }}
            </div>
            <div v-if="bjMonthType == monthList[3].value" class="num11">
              {{ bjNum2 }}
            </div>
            <div v-if="bjMonthType == monthList[3].value" class="num12">
              {{ bjNum3 }}
            </div>
            <img
              v-if="bjMonthType == monthList[4].value"
              class="image"
              src="../../assets/img/home/home/PES/Pic_TB_E.png"
            />
            <div v-if="bjMonthType == monthList[4].value" class="num13">
              {{ bjNum1 }}
            </div>
            <div v-if="bjMonthType == monthList[4].value" class="num14">
              {{ bjNum2 }}
            </div>
            <div v-if="bjMonthType == monthList[4].value" class="num15">
              {{ bjNum3 }}
            </div>
            <img
              v-if="bjMonthType == monthList[5].value"
              class="image"
              src="../../assets/img/home/home/PES/Pic_TB_F.png"
            />
            <div v-if="bjMonthType == monthList[5].value" class="num16">
              {{ bjNum1 }}
            </div>
            <div v-if="bjMonthType == monthList[5].value" class="num17">
              {{ bjNum2 }}
            </div>
            <div v-if="bjMonthType == monthList[5].value" class="num18">
              {{ bjNum3 }}
            </div>
            <el-select
              class="form-control"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
              v-model="currentGradeId"
              @change="change"
              placeholder="请选择"
            >
              <el-option
                v-for="val in classList"
                :key="val.id"
                :label="val.name"
                :value="val.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="row_center" style="width: 100%">
            <div class="title1 flex">测试总人数</div>
            <div class="title1">测试合格人数</div>
          </div>
        </div>
        <el-select
          class="form-control"
          style="display: flex; justify-content: center; align-items: center"
          v-model="currentGradeId"
          @change="change"
          placeholder="请选择"
        >
          <el-option
            v-for="val in classList"
            :key="val.id"
            :label="val.name"
            :value="val.id"
          >
          </el-option>
        </el-select>
      </div>
      <div v-show="bigChoice3" class="row mt100 big">
        <div v-if="monthList.length != 0" class="column_base">
          <div
            v-if="yeMonthType == monthList[0].value"
            class="month_select1 row center mt25"
          >
            <div class="circle1"></div>
            <div class="top_text">{{ monthList[0].name }}</div>
          </div>
          <div
            v-else
            class="month_normal1 row center mt25"
            @click="clickYeMonth(monthList[0])"
          >
            <div class="circle1 small_circle"></div>
            <div class="top_text">{{ monthList[0].name }}</div>
          </div>
          <div
            v-if="yeMonthType == monthList[1].value"
            class="month_select2 row center"
          >
            <div class="circle2"></div>
            <div class="top_text">{{ monthList[1].name }}</div>
          </div>
          <div
            v-else
            class="month_normal1 row center"
            @click="clickYeMonth(monthList[1])"
          >
            <div class="circle2 small_circle"></div>
            <div class="top_text">{{ monthList[1].name }}</div>
          </div>
          <div
            v-if="yeMonthType == monthList[2].value"
            class="month_select3 row center"
          >
            <div class="circle3"></div>
            <div class="top_text">{{ monthList[2].name }}</div>
          </div>
          <div
            v-else
            class="month_normal1 row center"
            @click="clickYeMonth(monthList[2])"
          >
            <div class="circle3 small_circle"></div>
            <div class="top_text">{{ monthList[2].name }}</div>
          </div>
          <div
            v-if="yeMonthType == monthList[3].value"
            class="month_select4 row center"
          >
            <div class="circle4"></div>
            <div class="top_text">{{ monthList[3].name }}</div>
          </div>
          <div
            v-else
            class="month_normal1 row center"
            @click="clickYeMonth(monthList[3])"
          >
            <div class="circle4 small_circle"></div>
            <div class="top_text">{{ monthList[3].name }}</div>
          </div>
          <div
            v-if="yeMonthType == monthList[4].value"
            class="month_select5 row center"
          >
            <div class="circle5"></div>
            <div class="top_text">{{ monthList[4].name }}</div>
          </div>
          <div
            v-else
            class="month_normal1 row center"
            @click="clickYeMonth(monthList[4])"
          >
            <div class="circle5 small_circle"></div>
            <div class="top_text">{{ monthList[4].name }}</div>
          </div>
          <div
            v-if="yeMonthType == monthList[5].value"
            class="month_select6 row center"
          >
            <div class="circle6"></div>
            <div class="top_text">{{ monthList[5].name }}</div>
          </div>
          <div
            v-else
            class="month_normal1 row center"
            @click="clickYeMonth(monthList[5])"
          >
            <div class="circle6 small_circle"></div>
            <div class="top_text">{{ monthList[5].name }}</div>
          </div>
        </div>
        <div class="image_c">
          <div class="title">本学期测试完成率</div>
          <div class="title1 mt60">小班</div>
          <div v-if="monthList.length != 0" class="image_l">
            <img
              v-if="yeMonthType == monthList[0].value"
              class="image"
              src="../../assets/img/home/home/PES/Pic_TB_A.png"
            />
            <div v-if="yeMonthType == monthList[0].value" class="num1">
              {{ yeNum1 }}
            </div>
            <div v-if="yeMonthType == monthList[0].value" class="num2">
              {{ yeNum2 }}
            </div>
            <div v-if="yeMonthType == monthList[0].value" class="num3">
              {{ yeNum3 }}
            </div>
            <img
              v-if="yeMonthType == monthList[1].value"
              class="image"
              src="../../assets/img/home/home/PES/Pic_TB_B.png"
            />
            <div v-if="yeMonthType == monthList[1].value" class="num4">
              {{ yeNum1 }}
            </div>
            <div v-if="yeMonthType == monthList[1].value" class="num5">
              {{ yeNum2 }}
            </div>
            <div v-if="yeMonthType == monthList[1].value" class="num6">
              {{ yeNum3 }}
            </div>
            <img
              v-if="yeMonthType == monthList[2].value"
              class="image"
              src="../../assets/img/home/home/PES/Pic_TB_C.png"
            />
            <div v-if="yeMonthType == monthList[2].value" class="num7">
              {{ yeNum1 }}
            </div>
            <div v-if="yeMonthType == monthList[2].value" class="num8">
              {{ yeNum2 }}
            </div>
            <div v-if="yeMonthType == monthList[2].value" class="num9">
              {{ yeNum3 }}
            </div>
            <img
              v-if="yeMonthType == monthList[3].value"
              class="image"
              src="../../assets/img/home/home/PES/Pic_TB_D.png"
            />
            <div v-if="yeMonthType == monthList[3].value" class="num10">
              {{ yeNum1 }}
            </div>
            <div v-if="yeMonthType == monthList[3].value" class="num11">
              {{ yeNum2 }}
            </div>
            <div v-if="yeMonthType == monthList[3].value" class="num12">
              {{ yeNum3 }}
            </div>
            <img
              v-if="yeMonthType == monthList[4].value"
              class="image"
              src="../../assets/img/home/home/PES/Pic_TB_E.png"
            />
            <div v-if="yeMonthType == monthList[4].value" class="num13">
              {{ yeNum1 }}
            </div>
            <div v-if="yeMonthType == monthList[4].value" class="num14">
              {{ yeNum2 }}
            </div>
            <div v-if="yeMonthType == monthList[4].value" class="num15">
              {{ yeNum3 }}
            </div>
            <img
              v-if="yeMonthType == monthList[5].value"
              class="image"
              src="../../assets/img/home/home/PES/Pic_TB_F.png"
            />
            <div v-if="yeMonthType == monthList[5].value" class="num16">
              {{ yeNum1 }}
            </div>
            <div v-if="yeMonthType == monthList[5].value" class="num17">
              {{ yeNum2 }}
            </div>
            <div v-if="yeMonthType == monthList[5].value" class="num18">
              {{ yeNum3 }}
            </div>
          </div>
          <div class="row_center" style="width: 100%">
            <div class="title1 flex">中班</div>
            <div class="title1">大班</div>
          </div>
        </div>
      </div>
      <div v-show="bigChoice4" class="column mt100 growth">
        <div class="title">幼儿成长测试</div>
        <div class="line_b">
          <div id="bLineChart" ref="bLineChart" class="topLine1"></div>
        </div>

        <div class="top_hint">
          <div class="line"></div>
          <div class="flex text">
            幼儿{{ growthBean.typeValue }}测试数据小班
          </div>
          <div class="mrl2 line"></div>
          <div class="flex text">
            幼儿{{ growthBean.typeValue }}测试数据中班
          </div>
          <div class="mrl3 line"></div>
          <div class="flex text">
            幼儿{{ growthBean.typeValue }}测试数据大班
          </div>
          <div class="mrl1 line"></div>
        </div>
        <div class="row mt23">
          <div class="label ml8" @click="clickColor(1)">
            <div
              :class="
                growthBean.index == 1
                  ? 'width16 color1 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[0] }}
            </div>
          </div>
          <div class="label ml32" @click="clickColor(2)">
            <div
              :class="
                growthBean.index == 2
                  ? 'width16 color2 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[1] }}
            </div>
          </div>
          <div class="label ml32" @click="clickColor(3)">
            <div
              :class="
                growthBean.index == 3
                  ? 'width16 color3 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[2] }}
            </div>
          </div>
          <div class="label ml32" @click="clickColor(4)">
            <div
              :class="
                growthBean.index == 4
                  ? 'width16 color4 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[3] }}
            </div>
          </div>
          <div class="label ml32" @click="clickColor(5)">
            <div
              :class="
                growthBean.index == 5
                  ? 'width16 color5 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[4] }}
            </div>
          </div>
          <div class="label ml32" @click="clickColor(6)">
            <div
              :class="
                growthBean.index == 6
                  ? 'width16 color6 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[5] }}
            </div>
          </div>
          <div class="label ml32" @click="clickColor(7)">
            <div
              :class="
                growthBean.index == 7
                  ? 'width16 color7 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[6] }}
            </div>
          </div>

          <div class="label ml8" @click="clickColor(8)">
            <div
              :class="
                growthBean.index == 8
                  ? 'width16 coloryd1 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[7] }}
            </div>
          </div>
          <div class="label ml32" @click="clickColor(9)">
            <div
              :class="
                growthBean.index == 9
                  ? 'width16 coloryd2 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[8] }}
            </div>
          </div>
          <div class="label ml32" @click="clickColor(10)">
            <div
              :class="
                growthBean.index == 10
                  ? 'width16 coloryd3 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[9] }}
            </div>
          </div>
          <div class="label ml32" @click="clickColor(11)">
            <div
              :class="
                growthBean.index == 11
                  ? 'width16 coloryd4 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[10] }}
            </div>
          </div>
          <div class="label ml32" @click="clickColor(12)">
            <div
              :class="
                growthBean.index == 12
                  ? 'width16 coloryd5 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[11] }}
            </div>
          </div>
          <div class="label ml32" @click="clickColor(13)">
            <div
              :class="
                growthBean.index == 13
                  ? 'width16 coloryd6 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[12] }}
            </div>
          </div>
          <div class="label ml20" @click="clickColor(14)">
            <div
              :class="
                growthBean.index == 14
                  ? 'width16 coloryd7 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[13] }}
            </div>
          </div>
          <div class="label ml20" @click="clickColor(15)">
            <div
              :class="
                growthBean.index == 15
                  ? 'width16 coloryd8 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[14] }}
            </div>
          </div>
        </div>
      </div>
      <div v-show="bigChoice5" class="column mt100 growth">
        <div class="title">上月增长率对比</div>
        <div class="baseLine">
          <div class="width514 mt30">
            <div class="top_lay">
              <div class="top_bg pos_abs">
                <div class="middle">上月体质增长率</div>
              </div>
            </div>
            <div class="hint1">本月体质测试全国增长率对比园所增长率</div>
            <div class="num_lay mt36">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.B_height
                    ? growthBean.rateData.B_height[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">身高</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.B_height &&
                    growthBean.rateData.B_height[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.B_height }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.B_height &&
                    growthBean.rateData.B_height[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.B_weight
                    ? growthBean.rateData.B_weight[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">体重</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.B_weight &&
                    growthBean.rateData.B_weight[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.B_weight }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.B_weight &&
                    growthBean.rateData.B_weight[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.B_eye_L
                    ? growthBean.rateData.B_eye_L[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">视力</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.B_eye_L &&
                    growthBean.rateData.B_eye_L[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.B_eye_L }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.B_eye_L &&
                    growthBean.rateData.B_eye_L[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.B_arm ? growthBean.rateData.B_arm[0] : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">臂展</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.B_arm &&
                    growthBean.rateData.B_arm[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.B_arm }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.B_arm &&
                    growthBean.rateData.B_arm[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.B_lung
                    ? growthBean.rateData.B_lung[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">肺活量</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.B_lung &&
                    growthBean.rateData.B_lung[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.B_lung }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.B_lung &&
                    growthBean.rateData.B_lung[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.B_bust
                    ? growthBean.rateData.B_bust[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">胸围</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.B_bust &&
                    growthBean.rateData.B_bust[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.B_bust }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.B_bust &&
                    growthBean.rateData.B_bust[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.B_flexion
                    ? growthBean.rateData.B_flexion[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">体前屈</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.B_flexion &&
                    growthBean.rateData.B_flexion[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.B_flexion }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.B_flexion &&
                    growthBean.rateData.B_flexion[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
          </div>
          <div class="width514 ml55 mt30">
            <div class="top_lay">
              <div class="top_bg pos_abs">
                <div class="middle">上月运动增长率</div>
              </div>
            </div>
            <div class="hint1">本月运动测试全国增长率对比园所增长率</div>
            <div class="num_lay mt36">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.S_run ? growthBean.rateData.S_run[0] : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">跑动</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.S_run &&
                    growthBean.rateData.S_run[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.S_run }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.S_run &&
                    growthBean.rateData.S_run[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.S_jump
                    ? growthBean.rateData.S_jump[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">跳跃</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.S_jump &&
                    growthBean.rateData.S_jump[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.S_jump }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.S_jump &&
                    growthBean.rateData.S_jump[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.S_agile
                    ? growthBean.rateData.S_agile[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">敏捷</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.S_agile &&
                    growthBean.rateData.S_agile[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.S_agile }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.S_agile &&
                    growthBean.rateData.S_agile[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.S_power
                    ? growthBean.rateData.S_power[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">力量</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.S_power &&
                    growthBean.rateData.S_power[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.S_power }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.S_power &&
                    growthBean.rateData.S_power[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.S_coordinate
                    ? growthBean.rateData.S_coordinate[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">协调</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.S_coordinate &&
                    growthBean.rateData.S_coordinate[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.S_coordinate }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.S_coordinate &&
                    growthBean.rateData.S_coordinate[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.S_balance
                    ? growthBean.rateData.S_balance[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">平衡</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.S_balance &&
                    growthBean.rateData.S_balance[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.S_balance }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.S_balance &&
                    growthBean.rateData.S_balance[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.S_ctrl_hand
                    ? growthBean.rateData.S_ctrl_hand[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">物控手</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.S_ctrl_hand &&
                    growthBean.rateData.S_ctrl_hand[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.S_ctrl_hand }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.S_ctrl_hand &&
                    growthBean.rateData.S_ctrl_hand[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  growthBean.rateData.S_ctrl_foot
                    ? growthBean.rateData.S_ctrl_foot[0]
                    : "-"
                }}</span>
                <div class="text1">%</div>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">物控脚</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    growthBean.rateData.S_ctrl_foot &&
                    growthBean.rateData.S_ctrl_foot[1] != '--'
                  "
                  class="text"
                  >{{ growthBean.rateData.S_ctrl_foot }}</span
                >
                <span v-else class="empty_text">未测试</span>
                <div
                  v-if="
                    growthBean.rateData.S_ctrl_foot &&
                    growthBean.rateData.S_ctrl_foot[1] != '--'
                  "
                  class="text1"
                >
                  %
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="bigChoice6" class="column mt100 growth">
        <div class="title">幼儿成长分析</div>
        <div class="line_c">
          <div id="growthBigAnalyseChart" class="analyse_chart"></div>
        </div>

        <div class="row width1400">
          <div class="flex column_center">
            <div class="analyse_tab">
              <span class="text2">{{
                growthBean.times[0] ? growthBean.times[0].replace("-", ".") : ""
              }}</span>
            </div>
            <div v-if="growthBean.analyseData[0] == '--'" class="red_text">
              未测试
            </div>
          </div>
          <div class="flex column_center">
            <div class="analyse_tab">
              <span class="text2">{{
                growthBean.times[1] ? growthBean.times[1].replace("-", ".") : ""
              }}</span>
            </div>
            <div v-if="growthBean.analyseData[1] == '--'" class="red_text">
              未测试
            </div>
          </div>
          <div class="flex column_center">
            <div class="analyse_tab">
              <span class="text2">{{
                growthBean.times[2] ? growthBean.times[2].replace("-", ".") : ""
              }}</span>
            </div>
            <div v-if="growthBean.analyseData[2] == '--'" class="red_text">
              未测试
            </div>
          </div>
          <div class="flex column_center">
            <div class="analyse_tab">
              <span class="text2">{{
                growthBean.times[3] ? growthBean.times[3].replace("-", ".") : ""
              }}</span>
            </div>
            <div v-if="growthBean.analyseData[3] == '--'" class="red_text">
              未测试
            </div>
          </div>
          <div class="flex column_center">
            <div class="analyse_tab">
              <span class="text2">{{
                growthBean.times[4] ? growthBean.times[4].replace("-", ".") : ""
              }}</span>
            </div>
            <div v-if="growthBean.analyseData[4] == '--'" class="red_text">
              未测试
            </div>
          </div>
          <div class="flex column_center">
            <div class="analyse_tab">
              <span class="text2">{{
                growthBean.times[5] ? growthBean.times[5].replace("-", ".") : ""
              }}</span>
            </div>
            <div v-if="growthBean.analyseData[5] == '--'" class="red_text">
              未测试
            </div>
          </div>
        </div>
        <div class="row mt23">
          <div class="label ml8" @click="clickGrowthAnalyseColor(1)">
            <div
              :class="
                growthBean.anaIndex == 1
                  ? 'width16 color1 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[0] }}
            </div>
          </div>
          <div class="label ml32" @click="clickGrowthAnalyseColor(2)">
            <div
              :class="
                growthBean.anaIndex == 2
                  ? 'width16 color2 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[1] }}
            </div>
          </div>
          <div class="label ml32" @click="clickGrowthAnalyseColor(3)">
            <div
              :class="
                growthBean.anaIndex == 3
                  ? 'width16 color3 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[2] }}
            </div>
          </div>
          <div class="label ml32" @click="clickGrowthAnalyseColor(4)">
            <div
              :class="
                growthBean.anaIndex == 4
                  ? 'width16 color4 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[3] }}
            </div>
          </div>
          <div class="label ml32" @click="clickGrowthAnalyseColor(5)">
            <div
              :class="
                growthBean.anaIndex == 5
                  ? 'width16 color5 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[4] }}
            </div>
          </div>
          <div class="label ml32" @click="clickGrowthAnalyseColor(6)">
            <div
              :class="
                growthBean.anaIndex == 6
                  ? 'width16 color6 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[5] }}
            </div>
          </div>
          <div class="label ml32" @click="clickGrowthAnalyseColor(7)">
            <div
              :class="
                growthBean.anaIndex == 7
                  ? 'width16 color7 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[6] }}
            </div>
          </div>

          <div class="label ml8" @click="clickGrowthAnalyseColor(8)">
            <div
              :class="
                growthBean.anaIndex == 8
                  ? 'width16 coloryd1 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[7] }}
            </div>
          </div>
          <div class="label ml32" @click="clickGrowthAnalyseColor(9)">
            <div
              :class="
                growthBean.anaIndex == 9
                  ? 'width16 coloryd2 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[8] }}
            </div>
          </div>
          <div class="label ml32" @click="clickGrowthAnalyseColor(10)">
            <div
              :class="
                growthBean.anaIndex == 10
                  ? 'width16 coloryd3 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[9] }}
            </div>
          </div>
          <div class="label ml32" @click="clickGrowthAnalyseColor(11)">
            <div
              :class="
                growthBean.anaIndex == 11
                  ? 'width16 coloryd4 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[10] }}
            </div>
          </div>
          <div class="label ml32" @click="clickGrowthAnalyseColor(12)">
            <div
              :class="
                growthBean.anaIndex == 12
                  ? 'width16 coloryd5 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[11] }}
            </div>
          </div>
          <div class="label ml32" @click="clickGrowthAnalyseColor(13)">
            <div
              :class="
                growthBean.anaIndex == 13
                  ? 'width16 coloryd6 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[12] }}
            </div>
          </div>
          <div class="label ml20" @click="clickGrowthAnalyseColor(14)">
            <div
              :class="
                growthBean.anaIndex == 14
                  ? 'width16 coloryd7 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[13] }}
            </div>
          </div>
          <div class="label ml20" @click="clickGrowthAnalyseColor(15)">
            <div
              :class="
                growthBean.anaIndex == 15
                  ? 'width16 coloryd8 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ growthBean.names1[14] }}
            </div>
          </div>
        </div>
      </div>
      <div v-show="bigChoice7" class="column mt100 country_db">
        <div class="title">全国平均值</div>
        <div class="pos_rel1">
          <div ref="ChinaMap1" id="chinaMap1" class="topLine2"></div>
          <div class="label_row">
            <div class="column">
              <div class="row_center" @click="clickMapTab(1)">
                <div
                  :class="
                    countryDbBean.type == 1
                      ? 'width62 color1 text_select'
                      : 'alpha width62 text_normal'
                  "
                >
                  身高
                </div>
              </div>
              <div class="row_center mt6" @click="clickMapTab(2)">
                <div
                  :class="
                    countryDbBean.type == 2
                      ? 'width62 color2 text_select'
                      : 'alpha width62 text_normal'
                  "
                >
                  体重
                </div>
              </div>
              <div class="row_center mt6" @click="clickMapTab(3)">
                <div
                  :class="
                    countryDbBean.type == 3
                      ? 'width62 color3 text_select'
                      : 'alpha width62 text_normal'
                  "
                >
                  视力
                </div>
              </div>
              <div class="row_center mt6" @click="clickMapTab(4)">
                <div
                  :class="
                    countryDbBean.type == 4
                      ? 'width62 color4 text_select'
                      : 'alpha width62 text_normal'
                  "
                >
                  臂展
                </div>
              </div>
              <div class="row_center mt6" @click="clickMapTab(5)">
                <div
                  :class="
                    countryDbBean.type == 5
                      ? 'width62 color5 text_select'
                      : 'alpha width62 text_normal'
                  "
                >
                  肺活量
                </div>
              </div>
              <div class="row_center mt6" @click="clickMapTab(6)">
                <div
                  :class="
                    countryDbBean.type == 6
                      ? 'width62 color6 text_select'
                      : 'alpha width62 text_normal'
                  "
                >
                  胸围
                </div>
              </div>
              <div class="row_center mt6" @click="clickMapTab(7)">
                <div
                  :class="
                    countryDbBean.type == 7
                      ? 'width62 color7 text_select'
                      : 'alpha width62 text_normal'
                  "
                >
                  体前屈
                </div>
              </div>
              <div class="row_center mt6" @click="clickMapTab(8)">
                <div
                  :class="
                    countryDbBean.type == 8
                      ? 'width62 color8 text_select'
                      : 'alpha width62 text_normal'
                  "
                >
                  跑动
                </div>
              </div>
              <div class="row_center mt6" @click="clickMapTab(9)">
                <div
                  :class="
                    countryDbBean.type == 9
                      ? 'width62 color9 text_select'
                      : 'alpha width62 text_normal'
                  "
                >
                  跳跃
                </div>
              </div>
              <div class="row_center mt6" @click="clickMapTab(10)">
                <div
                  :class="
                    countryDbBean.type == 10
                      ? 'width62 color10 text_select'
                      : 'alpha width62 text_normal'
                  "
                >
                  敏捷
                </div>
              </div>
              <div class="row_center mt6" @click="clickMapTab(11)">
                <div
                  :class="
                    countryDbBean.type == 11
                      ? 'width62 color11 text_select'
                      : 'alpha width62 text_normal'
                  "
                >
                  力量
                </div>
              </div>
              <div class="row_center mt6" @click="clickMapTab(12)">
                <div
                  :class="
                    countryDbBean.type == 12
                      ? 'width62 color12 text_select'
                      : 'alpha width62 text_normal'
                  "
                >
                  协调
                </div>
              </div>
              <div class="row_center mt6" @click="clickMapTab(13)">
                <div
                  :class="
                    countryDbBean.type == 13
                      ? 'width62 color13 text_select'
                      : 'alpha width62 text_normal'
                  "
                >
                  平衡
                </div>
              </div>
              <div class="row_center mt6" @click="clickMapTab(14)">
                <div
                  :class="
                    countryDbBean.type == 14
                      ? 'width62 color14 text_select'
                      : 'alpha width62 text_normal'
                  "
                >
                  物控手
                </div>
              </div>
              <div class="row_center mt6" @click="clickMapTab(15)">
                <div
                  :class="
                    countryDbBean.type == 15
                      ? 'width62 color15 text_select'
                      : 'alpha width62 text_normal'
                  "
                >
                  物控脚
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="bigChoice8" class="column mt100 country_db">
        <div class="title">上月平均值对比</div>
        <div class="baseLine growth">
          <div class="width514 mt30">
            <div class="top_lay">
              <div class="top_bg pos_abs">
                <div class="middle">上月体质平均值</div>
              </div>
            </div>
            <div class="hint1">上月体质测试全国平均值对比园所平均值</div>
            <div class="num_lay mt36">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.B_height
                    ? countryDbBean.rateData.B_height[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">身高</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.B_height &&
                    countryDbBean.rateData.B_height[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.B_height[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.B_weight
                    ? countryDbBean.rateData.B_weight[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">体重</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.B_weight &&
                    countryDbBean.rateData.B_weight[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.B_weight[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.B_eye_L
                    ? countryDbBean.rateData.B_eye_L[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">视力</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.B_eye_L &&
                    countryDbBean.rateData.B_eye_L[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.B_eye_L[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.B_arm
                    ? countryDbBean.rateData.B_arm[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">臂展</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.B_arm &&
                    countryDbBean.rateData.B_arm[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.B_arm[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.B_lung
                    ? countryDbBean.rateData.B_lung[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">肺活量</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.B_lung &&
                    countryDbBean.rateData.B_lung[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.B_lung[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.B_bust
                    ? countryDbBean.rateData.B_bust[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">胸围</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.B_bust &&
                    countryDbBean.rateData.B_bust[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.B_bust[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.B_flexion
                    ? countryDbBean.rateData.B_flexion[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">体前屈</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.B_flexion &&
                    countryDbBean.rateData.B_flexion[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.B_flexion[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
          </div>
          <div class="width514 ml55 mt30">
            <div class="top_lay">
              <div class="top_bg pos_abs">
                <div class="middle">上月运动平均值</div>
              </div>
            </div>
            <div class="hint1">上月运动测试全国平均值对比园所平均值</div>
            <div class="num_lay mt36">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.S_run
                    ? countryDbBean.rateData.S_run[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">跑动</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.S_run &&
                    countryDbBean.rateData.S_run[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.S_run[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.S_jump
                    ? countryDbBean.rateData.S_jump[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">跳跃</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.S_jump &&
                    countryDbBean.rateData.S_jump[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.S_jump[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.S_agile
                    ? countryDbBean.rateData.S_agile[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">敏捷</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.S_agile &&
                    countryDbBean.rateData.S_agile[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.S_agile[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.S_power
                    ? countryDbBean.rateData.S_power[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">力量</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.S_power &&
                    countryDbBean.rateData.S_power[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.S_power[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.S_coordinate
                    ? countryDbBean.rateData.S_coordinate[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">协调</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.S_coordinate &&
                    countryDbBean.rateData.S_coordinate[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.S_coordinate[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.S_balance
                    ? countryDbBean.rateData.S_balance[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">平衡</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.S_balance &&
                    countryDbBean.rateData.S_balance[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.S_balance[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.S_ctrl_hand
                    ? countryDbBean.rateData.S_ctrl_hand[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">物控手</div>
              </div>
              <div
                v-if="countryDbBean.rateData.S_ctrl_hand"
                class="left_lay center"
              >
                <span
                  v-if="
                    countryDbBean.rateData.S_ctrl_hand &&
                    countryDbBean.rateData.S_ctrl_hand[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.S_ctrl_hand[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  countryDbBean.rateData.S_ctrl_foot
                    ? countryDbBean.rateData.S_ctrl_foot[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">物控脚</div>
              </div>
              <div class="left_lay center">
                <span
                  v-if="
                    countryDbBean.rateData.S_ctrl_foot &&
                    countryDbBean.rateData.S_ctrl_foot[1]
                  "
                  class="text"
                  >{{ countryDbBean.rateData.S_ctrl_foot[1] }}</span
                >
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="bigChoice9" class="column mt100 country_db">
        <div class="title">全国平均值对比</div>
        <div id="bcountryAnalyseChart" class="analyse_chart"></div>
        <div class="row width1400">
          <div class="flex column_center">
            <div class="analyse_tab">
              <span class="text2">{{
                countryDbBean.times[0]
                  ? countryDbBean.times[0].replace("-", ".")
                  : ""
              }}</span>
            </div>
            <div v-if="!countryDbBean.analyseData[0]" class="red_text">
              未测试
            </div>
          </div>
          <div class="flex column_center">
            <div class="analyse_tab">
              <span class="text2">{{
                countryDbBean.times[1]
                  ? countryDbBean.times[1].replace("-", ".")
                  : ""
              }}</span>
            </div>
            <div v-if="!countryDbBean.analyseData[1]" class="red_text">
              未测试
            </div>
          </div>
          <div class="flex column_center">
            <div class="analyse_tab">
              <span class="text2">{{
                countryDbBean.times[2]
                  ? countryDbBean.times[2].replace("-", ".")
                  : ""
              }}</span>
            </div>
            <div v-if="!countryDbBean.analyseData[2]" class="red_text">
              未测试
            </div>
          </div>
          <div class="flex column_center">
            <div class="analyse_tab">
              <span class="text2">{{
                countryDbBean.times[3]
                  ? countryDbBean.times[3].replace("-", ".")
                  : ""
              }}</span>
            </div>
            <div v-if="!countryDbBean.analyseData[3]" class="red_text">
              未测试
            </div>
          </div>
          <div class="flex column_center">
            <div class="analyse_tab">
              <span class="text2">{{
                countryDbBean.times[4]
                  ? countryDbBean.times[4].replace("-", ".")
                  : ""
              }}</span>
            </div>
            <div v-if="!countryDbBean.analyseData[4]" class="red_text">
              未测试
            </div>
          </div>
          <div class="flex column_center">
            <div class="analyse_tab">
              <span class="text2">{{
                countryDbBean.times[5]
                  ? countryDbBean.times[5].replace("-", ".")
                  : ""
              }}</span>
            </div>
            <div v-if="!countryDbBean.analyseData[5]" class="red_text">
              未测试
            </div>
          </div>
        </div>

        <div class="row mt23">
          <div class="label ml32" @click="clickAnalyseType(1)">
            <div
              :class="
                countryDbBean.anaIndex == 1
                  ? 'width16 ys_color1 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ countryDbBean.names1[0] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType(2)">
            <div
              :class="
                countryDbBean.anaIndex == 2
                  ? 'width16 ys_color2 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ countryDbBean.names1[1] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType(3)">
            <div
              :class="
                countryDbBean.anaIndex == 3
                  ? 'width16 ys_color3 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ countryDbBean.names1[2] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType(4)">
            <div
              :class="
                countryDbBean.anaIndex == 4
                  ? 'width16 ys_color4 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ countryDbBean.names1[3] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType(5)">
            <div
              :class="
                countryDbBean.anaIndex == 5
                  ? 'width16 ys_color5 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ countryDbBean.names1[4] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType(6)">
            <div
              :class="
                countryDbBean.anaIndex == 6
                  ? 'width16 ys_color6 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ countryDbBean.names1[5] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType(7)">
            <div
              :class="
                countryDbBean.anaIndex == 7
                  ? 'width16 ys_color7 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ countryDbBean.names1[6] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType(8)">
            <div
              :class="
                countryDbBean.anaIndex == 8
                  ? 'width16 ys_color8 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ countryDbBean.names1[7] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType(9)">
            <div
              :class="
                countryDbBean.anaIndex == 9
                  ? 'width16 ys_color9 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ countryDbBean.names1[8] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType(10)">
            <div
              :class="
                countryDbBean.anaIndex == 10
                  ? 'width16 ys_color10 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ countryDbBean.names1[9] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType(11)">
            <div
              :class="
                countryDbBean.anaIndex == 11
                  ? 'width16 ys_color11 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ countryDbBean.names1[10] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType(12)">
            <div
              :class="
                countryDbBean.anaIndex == 12
                  ? 'width16 ys_color12 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ countryDbBean.names1[11] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType(13)">
            <div
              :class="
                countryDbBean.anaIndex == 13
                  ? 'width16 ys_color13 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ countryDbBean.names1[12] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType(14)">
            <div
              :class="
                countryDbBean.anaIndex == 14
                  ? 'width16 ys_color14 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ countryDbBean.names1[13] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType(15)">
            <div
              :class="
                countryDbBean.anaIndex == 15
                  ? 'width16 ys_color15 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ countryDbBean.names1[14] }}
            </div>
          </div>
        </div>
      </div>
      <div v-show="bigChoice10" class="column mt100 yecf">
        <div class="title">平均值对比</div>
        <div id="barChart1" class="topBar"></div>
        <div class="row">
          <div class="column1">
            <div class="row">
              <div v-if="yecfBean.monthType == 1" class="month_select">
                <div class="text1">
                  {{ yecfBean.monthList ? yecfBean.monthList[0] : "" }}
                </div>
                <div class="text2">月</div>
              </div>
              <div v-else class="month_normal" @click="monthClick(1)">
                <div class="text1">
                  {{ yecfBean.monthList ? yecfBean.monthList[0] : "" }}
                </div>
                <div class="text2">月</div>
              </div>
              <div v-if="yecfBean.monthType == 2" class="month_select">
                <div class="text1">
                  {{ yecfBean.monthList ? yecfBean.monthList[1] : "" }}
                </div>
                <div class="text2">月</div>
              </div>
              <div v-else class="month_normal" @click="monthClick(2)">
                <div class="text1">
                  {{ yecfBean.monthList ? yecfBean.monthList[1] : "" }}
                </div>
                <div class="text2">月</div>
              </div>
              <div v-if="yecfBean.monthType == 3" class="month_select">
                <div class="text1">
                  {{ yecfBean.monthList ? yecfBean.monthList[2] : "" }}
                </div>
                <div class="text2">月</div>
              </div>
              <div v-else class="month_normal" @click="monthClick(3)">
                <div class="text1">
                  {{ yecfBean.monthList ? yecfBean.monthList[2] : "" }}
                </div>
                <div class="text2">月</div>
              </div>
              <div v-if="yecfBean.monthType == 4" class="month_select">
                <div class="text1">
                  {{ yecfBean.monthList ? yecfBean.monthList[3] : "" }}
                </div>
                <div class="text2">月</div>
              </div>
              <div v-else class="month_normal" @click="monthClick(4)">
                <div class="text1">
                  {{ yecfBean.monthList ? yecfBean.monthList[3] : "" }}
                </div>
                <div class="text2">月</div>
              </div>
              <div v-if="yecfBean.monthType == 5" class="month_select">
                <div class="text1">
                  {{ yecfBean.monthList ? yecfBean.monthList[4] : "" }}
                </div>
                <div class="text2">月</div>
              </div>
              <div v-else class="month_normal" @click="monthClick(5)">
                <div class="text1">
                  {{ yecfBean.monthList ? yecfBean.monthList[4] : "" }}
                </div>
                <div class="text2">月</div>
              </div>
              <div v-if="yecfBean.monthType == 6" class="month_select">
                <div class="text1">
                  {{ yecfBean.monthList ? yecfBean.monthList[5] : "" }}
                </div>
                <div class="text2">月</div>
              </div>
              <div v-else class="month_normal" @click="monthClick(6)">
                <div class="text1">
                  {{ yecfBean.monthList ? yecfBean.monthList[5] : "" }}
                </div>
                <div class="text2">月</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="bigChoice11" class="column mt100 yecf">
        <div class="title">上月综合评分对比</div>
        <div class="baseLine mt72">
          <div class="width514">
            <div class="top_lay">
              <div class="top_bg pos_abs">
                <div class="middle">上月体质综合评分</div>
              </div>
            </div>
            <div class="hint1">上月体质测试全国综合评分对比园所综合评分</div>
            <div class="num_lay mt36">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.B_judge_height
                    ? yecfBean.rateData.B_judge_height[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">身高</div>
              </div>
              <div class="left_lay center">
                <span v-if="yecfBean.rateData.B_judge_height" class="text">{{
                  yecfBean.rateData.B_judge_height[1]
                    ? yecfBean.rateData.B_judge_height[1]
                    : "-"
                }}</span>
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.B_judge_weight
                    ? yecfBean.rateData.B_judge_weight[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">体重</div>
              </div>
              <div class="left_lay center">
                <span v-if="yecfBean.rateData.B_judge_weight" class="text">{{
                  yecfBean.rateData.B_judge_weight[1]
                    ? yecfBean.rateData.B_judge_weight[1]
                    : "-"
                }}</span>
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.B_judge_eye
                    ? yecfBean.rateData.B_judge_eye[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">视力</div>
              </div>
              <div class="left_lay center">
                <span v-if="yecfBean.rateData.B_judge_eye" class="text">{{
                  yecfBean.rateData.B_judge_eye[1]
                    ? yecfBean.rateData.B_judge_eye[1]
                    : "-"
                }}</span>
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.B_judge_arm
                    ? yecfBean.rateData.B_judge_arm[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">臂展</div>
              </div>
              <div class="left_lay center">
                <span v-if="yecfBean.rateData.B_judge_arm" class="text">{{
                  yecfBean.rateData.B_judge_arm[1]
                    ? yecfBean.rateData.B_judge_arm[1]
                    : "-"
                }}</span>
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.B_judge_lung
                    ? yecfBean.rateData.B_judge_lung[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">肺活量</div>
              </div>
              <div class="left_lay center">
                <span v-if="yecfBean.rateData.B_judge_lung" class="text">{{
                  yecfBean.rateData.B_judge_lung[1]
                    ? yecfBean.rateData.B_judge_lung[1]
                    : "-"
                }}</span>
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.B_judge_bust
                    ? yecfBean.rateData.B_judge_bust[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">胸围</div>
              </div>
              <div class="left_lay center">
                <span v-if="yecfBean.rateData.B_judge_bust" class="text">{{
                  yecfBean.rateData.B_judge_bust[1]
                    ? yecfBean.rateData.B_judge_bust[1]
                    : "-"
                }}</span>
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.B_judge_flexion
                    ? yecfBean.rateData.B_judge_flexion[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">体前屈</div>
              </div>
              <div class="left_lay center">
                <span v-if="yecfBean.rateData.B_judge_flexion" class="text">{{
                  yecfBean.rateData.B_judge_flexion[1]
                    ? yecfBean.rateData.B_judge_flexion[1]
                    : "-"
                }}</span>
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
          </div>
          <div class="width514 ml55">
            <div class="top_lay">
              <div class="top_bg pos_abs">
                <div class="middle">上月运动综合评分</div>
              </div>
            </div>
            <div class="hint1">上月运动测试全国综合评分对比园所综合评分</div>
            <div class="num_lay mt36">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.S_judge_run
                    ? yecfBean.rateData.S_judge_run[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">跑动</div>
              </div>
              <div class="left_lay center">
                <span v-if="yecfBean.rateData.S_judge_run" class="text">{{
                  yecfBean.rateData.S_judge_run[1]
                    ? yecfBean.rateData.S_judge_run[1]
                    : "-"
                }}</span>
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.S_judge_jump
                    ? yecfBean.rateData.S_judge_jump[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">跳跃</div>
              </div>
              <div class="left_lay center">
                <span v-if="yecfBean.rateData.S_judge_jump" class="text">{{
                  yecfBean.rateData.S_judge_jump[1]
                    ? yecfBean.rateData.S_judge_jump[1]
                    : "-"
                }}</span>
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.S_judge_agile
                    ? yecfBean.rateData.S_judge_agile[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">敏捷</div>
              </div>
              <div class="left_lay center">
                <span v-if="yecfBean.rateData.S_judge_agile" class="text">{{
                  yecfBean.rateData.S_judge_agile[1]
                    ? yecfBean.rateData.S_judge_agile[1]
                    : "-"
                }}</span>
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.S_judge_power
                    ? yecfBean.rateData.S_judge_power[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">力量</div>
              </div>
              <div class="left_lay center">
                <span v-if="yecfBean.rateData.S_judge_power" class="text">{{
                  yecfBean.rateData.S_judge_power[1]
                    ? yecfBean.rateData.S_judge_power[1]
                    : "-"
                }}</span>
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.S_judge_coordiate
                    ? yecfBean.rateData.S_judge_coordiate[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">协调</div>
              </div>
              <div class="left_lay center">
                <span v-if="yecfBean.rateData.S_judge_coordiate" class="text">{{
                  yecfBean.rateData.S_judge_coordiate[1]
                    ? yecfBean.rateData.S_judge_coordiate[1]
                    : "-"
                }}</span>
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.S_judge_balance
                    ? yecfBean.rateData.S_judge_balance[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">平衡</div>
              </div>
              <div class="left_lay center">
                <span v-if="yecfBean.rateData.S_judge_balance" class="text">{{
                  yecfBean.rateData.S_judge_balance[1]
                    ? yecfBean.rateData.S_judge_balance[1]
                    : "-"
                }}</span>
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.S_judge_ctrlHand
                    ? yecfBean.rateData.S_judge_ctrlHand[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">物控手</div>
              </div>
              <div class="left_lay center">
                <span v-if="yecfBean.rateData.S_judge_ctrlHand" class="text">{{
                  yecfBean.rateData.S_judge_ctrlHand[1]
                    ? yecfBean.rateData.S_judge_ctrlHand[1]
                    : "-"
                }}</span>
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
            <div class="num_lay mt16">
              <div class="left_lay">
                <span class="text">{{
                  yecfBean.rateData.S_judge_ctrlFoot
                    ? yecfBean.rateData.S_judge_ctrlFoot[0]
                    : "-"
                }}</span>
              </div>
              <div class="column_num center flex">
                <div class="row">
                  <div class="text">全国</div>
                  <img
                    class="vs"
                    src="../../assets/img/home/home/PES/Icon_Vs.png"
                  />
                  <div class="text">本园所</div>
                </div>
                <div class="line"></div>
                <div class="value">物控脚</div>
              </div>
              <div class="left_lay center">
                <span v-if="yecfBean.rateData.S_judge_ctrlFoot" class="text">{{
                  yecfBean.rateData.S_judge_ctrlFoot[1]
                    ? yecfBean.rateData.S_judge_ctrlFoot[1]
                    : "-"
                }}</span>
                <span v-else class="empty_text">未测试</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="bigChoice12" class="column mt100 country_db">
        <div class="title">综合评分分析</div>
        <div id="byecfAnalyseChart" class="analyse_chart"></div>
        <div class="row width1400">
          <div class="flex column_center">
            <div class="analyse_tab">
              <span class="text2">{{
                yecfBean.times[0] ? yecfBean.times[0].replace("-", ".") : ""
              }}</span>
            </div>
            <div v-if="!yecfBean.analyseData[0]" class="red_text">未测试</div>
          </div>
          <div class="flex column_center">
            <div class="analyse_tab">
              <span class="text2">{{
                yecfBean.times[1] ? yecfBean.times[1].replace("-", ".") : ""
              }}</span>
            </div>
            <div v-if="!yecfBean.analyseData[1]" class="red_text">未测试</div>
          </div>
          <div class="flex column_center">
            <div class="analyse_tab">
              <span class="text2">{{
                yecfBean.times[2] ? yecfBean.times[2].replace("-", ".") : ""
              }}</span>
            </div>
            <div v-if="!yecfBean.analyseData[2]" class="red_text">未测试</div>
          </div>
          <div class="flex column_center">
            <div class="analyse_tab">
              <span class="text2">{{
                yecfBean.times[3] ? yecfBean.times[3].replace("-", ".") : ""
              }}</span>
            </div>
            <div v-if="!yecfBean.analyseData[3]" class="red_text">未测试</div>
          </div>
          <div class="flex column_center">
            <div class="analyse_tab">
              <span class="text2">{{
                yecfBean.times[4] ? yecfBean.times[4].replace("-", ".") : ""
              }}</span>
            </div>
            <div v-if="!yecfBean.analyseData[4]" class="red_text">未测试</div>
          </div>
          <div class="flex column_center">
            <div class="analyse_tab">
              <span class="text2">{{
                yecfBean.times[5] ? yecfBean.times[5].replace("-", ".") : ""
              }}</span>
            </div>
            <div v-if="!yecfBean.analyseData[5]" class="red_text">未测试</div>
          </div>
        </div>

        <div class="row mt23">
          <div class="label ml32" @click="clickAnalyseType3(1)">
            <div
              :class="
                yecfBean.anaIndex == 1
                  ? 'width16 ys_color1 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ yecfBean.names1[0] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType3(2)">
            <div
              :class="
                yecfBean.anaIndex == 2
                  ? 'width16 ys_color2 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ yecfBean.names1[1] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType3(3)">
            <div
              :class="
                yecfBean.anaIndex == 3
                  ? 'width16 ys_color3 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ yecfBean.names1[2] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType3(4)">
            <div
              :class="
                yecfBean.anaIndex == 4
                  ? 'width16 ys_color4 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ yecfBean.names1[3] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType3(5)">
            <div
              :class="
                yecfBean.anaIndex == 5
                  ? 'width16 ys_color5 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ yecfBean.names1[4] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType3(6)">
            <div
              :class="
                yecfBean.anaIndex == 6
                  ? 'width16 ys_color6 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ yecfBean.names1[5] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType3(7)">
            <div
              :class="
                yecfBean.anaIndex == 7
                  ? 'width16 ys_color7 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ yecfBean.names1[6] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType3(8)">
            <div
              :class="
                yecfBean.anaIndex == 8
                  ? 'width16 ys_color8 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ yecfBean.names1[7] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType3(9)">
            <div
              :class="
                yecfBean.anaIndex == 9
                  ? 'width16 ys_color9 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ yecfBean.names1[8] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType3(10)">
            <div
              :class="
                yecfBean.anaIndex == 10
                  ? 'width16 ys_color10 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ yecfBean.names1[9] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType3(11)">
            <div
              :class="
                yecfBean.anaIndex == 11
                  ? 'width16 ys_color11 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ yecfBean.names1[10] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType3(12)">
            <div
              :class="
                yecfBean.anaIndex == 12
                  ? 'width16 ys_color12 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ yecfBean.names1[11] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType3(13)">
            <div
              :class="
                yecfBean.anaIndex == 13
                  ? 'width16 ys_color13 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ yecfBean.names1[12] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType3(14)">
            <div
              :class="
                yecfBean.anaIndex == 14
                  ? 'width16 ys_color14 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ yecfBean.names1[13] }}
            </div>
          </div>
          <div class="label ml32" @click="clickAnalyseType3(15)">
            <div
              :class="
                yecfBean.anaIndex == 15
                  ? 'width16 ys_color15 text_select'
                  : 'color_white width16 text_normal'
              "
            >
              {{ yecfBean.names1[14] }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="h240"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import china from "../../utils/china";
import domtoimage from "dom-to-image";
var geoCoordMap = {
  北京: ["116.3979471", "39.9081726"],
  上海: ["121.4692688", "31.2381763"],
  天津: ["117.2523808", "39.1038561"],
  重庆: ["106.548425", "29.5549144"],
  河北: ["114.4897766", "38.0451279"],
  山西: ["112.5223053", "37.8357424"],
  辽宁: ["123.4116821", "41.7966156"],
  吉林: ["125.3154297", "43.8925629"],
  黑龙江: ["126.6433411", "45.7414932"],
  浙江: ["120.1592484", "30.265995"],
  福建: ["119.2978134", "26.0785904"],
  山东: ["117.0056", "36.6670723"],
  河南: ["113.6500473", "34.7570343"],
  湖北: ["114.2919388", "30.5675144"],
  湖南: ["112.9812698", "28.2008247"],
  广东: ["113.2614288", "23.1189117"],
  海南: ["110.3465118", "20.0317936"],
  四川: ["104.0817566", "30.6610565"],
  贵州: ["106.7113724", "26.5768738"],
  云南: ["102.704567", "25.0438442"],
  江西: ["115.8999176", "28.6759911"],
  陕西: ["108.949028", "34.2616844"],
  青海: ["101.7874527", "36.6094475"],
  甘肃: ["103.7500534", "36.0680389"],
  广西: ["108.3117676", "22.8065434"],
  新疆: ["87.6061172", "43.7909393"],
  内蒙古: ["111.6632996", "40.8209419"],
  西藏: ["91.1320496", "29.657589"],
  宁夏: ["106.2719421", "38.4680099"],
  台湾: ["120.9581316", "23.8516062"],
  香港: ["114.139452", "22.391577"],
  澳门: ["113.5678411", "22.167654"],
  安徽: ["117.2757034", "31.8632545"],
  江苏: ["118.7727814", "32.0476151"],
};
var convertData = function (data) {
  var res = [];
  for (var i = 0; i < data.length; i++) {
    var geoCoord = geoCoordMap[data[i].name];
    if (geoCoord) {
      res.push({
        name: data[i].name,
        value: geoCoord.concat(data[i].value),
      });
    }
  }
  return res;
};
export default {
  name: "doZZBG",
  components: {},
  data() {
    return {
      choice1: false,
      ysMonthType: "",
      monthList: [],
      ysNum1: 0,
      ysNum2: 0,
      ysNum3: 0,
      bigChoice1: false,
      choice2: false,
      bjMonthType: "",
      bjNum1: 0,
      bjNum2: 0,
      bjNum3: 0,
      bigChoice2: false,
      classList: [],
      currentGradeId: "",
      choice3: false,
      yeMonthType: "",
      yeNum1: 0,
      yeNum2: 0,
      yeNum3: 0,
      bigChoice3: false,
      choice4: false,
      bigChoice4: false,
      growthBean: {
        index: 1,
        anaIndex: 1,
        charts: null,
        bigCharts: null,
        analyseCharts: null,
        bigAnalyseCharts: null,
        type: 1,
        typeValue: "体质",
        datatz1: [],
        datatz2: [],
        datatz3: [],
        datatz4: [],
        datatz5: [],
        datatz6: [],
        datatz7: [],
        datatz8: [],
        datayd1: [],
        datayd2: [],
        datayd3: [],
        datayd4: [],
        datayd5: [],
        datayd6: [],
        datayd7: [],
        datayd8: [],
        colors: [
          "rgba(192, 40, 27, 1)",
          "rgba(64, 209, 0, 0.05)",
          "rgba(0, 168, 193, 0.05)",
          "rgba(224, 200, 0, 0.05)",
          "rgba(244, 138, 0, 0.05)",
          "rgba(39, 0, 237, 0.05)",
          "rgba(214, 0, 253, 0.05)",
          "rgba(151, 32, 79, 0.05)",
          "rgba(150, 36, 49, 0.05)",
          "rgba(46, 162, 122, 0.05)",
          "rgba(31, 68, 135, 0.05)",
          "rgba(182, 150, 35, 0.05)",
          "rgba(10, 0, 81, 0.05)",
          "rgba(24, 130, 148, 0.05)",
          "rgba(15, 193, 100, 0.05)",
        ],
        color1: [
          "rgba(192, 40, 27, 1)",
          "rgba(64, 209, 0, 0.05)",
          "rgba(0, 168, 193, 0.05)",
          "rgba(224, 200, 0, 0.05)",
          "rgba(244, 138, 0, 0.05)",
          "rgba(39, 0, 237, 0.05)",
          "rgba(214, 0, 253, 0.05)",
          "rgba(151, 32, 79, 0.05)",
          "rgba(150, 36, 49, 0.05)",
          "rgba(46, 162, 122, 0.05)",
          "rgba(31, 68, 135, 0.05)",
          "rgba(182, 150, 35, 0.05)",
          "rgba(10, 0, 81, 0.05)",
          "rgba(24, 130, 148, 0.05)",
          "rgba(15, 193, 100, 0.05)",
        ],
        color2: [
          "rgba(192, 40, 27, 0.05)",
          "rgba(64, 209, 0, 1)",
          "rgba(0, 168, 193, 0.05)",
          "rgba(224, 200, 0, 0.05)",
          "rgba(244, 138, 0, 0.05)",
          "rgba(39, 0, 237, 0.05)",
          "rgba(214, 0, 253, 0.05)",
          "rgba(151, 32, 79, 0.05)",
          "rgba(150, 36, 49, 0.05)",
          "rgba(46, 162, 122, 0.05)",
          "rgba(31, 68, 135, 0.05)",
          "rgba(182, 150, 35, 0.05)",
          "rgba(10, 0, 81, 0.05)",
          "rgba(24, 130, 148, 0.05)",
          "rgba(15, 193, 100, 0.05)",
        ],
        color3: [
          "rgba(192, 40, 27, 0.05)",
          "rgba(64, 209, 0, 0.05)",
          "rgba(0, 168, 193, 1)",
          "rgba(224, 200, 0, 0.05)",
          "rgba(244, 138, 0, 0.05)",
          "rgba(39, 0, 237, 0.05)",
          "rgba(214, 0, 253, 0.05)",
          "rgba(151, 32, 79, 0.05)",
          "rgba(150, 36, 49, 0.05)",
          "rgba(46, 162, 122, 0.05)",
          "rgba(31, 68, 135, 0.05)",
          "rgba(182, 150, 35, 0.05)",
          "rgba(10, 0, 81, 0.05)",
          "rgba(24, 130, 148, 0.05)",
          "rgba(15, 193, 100, 0.05)",
        ],
        color4: [
          "rgba(192, 40, 27, 0.05)",
          "rgba(64, 209, 0, 0.05)",
          "rgba(0, 168, 193, 0.05)",
          "rgba(224, 200, 0, 1)",
          "rgba(244, 138, 0, 0.05)",
          "rgba(39, 0, 237, 0.05)",
          "rgba(214, 0, 253, 0.05)",
          "rgba(151, 32, 79, 0.05)",
          "rgba(150, 36, 49, 0.05)",
          "rgba(46, 162, 122, 0.05)",
          "rgba(31, 68, 135, 0.05)",
          "rgba(182, 150, 35, 0.05)",
          "rgba(10, 0, 81, 0.05)",
          "rgba(24, 130, 148, 0.05)",
          "rgba(15, 193, 100, 0.05)",
        ],
        color5: [
          "rgba(192, 40, 27, 0.05)",
          "rgba(64, 209, 0, 0.05)",
          "rgba(0, 168, 193, 0.05)",
          "rgba(224, 200, 0, 0.05)",
          "rgba(244, 138, 0, 1)",
          "rgba(39, 0, 237, 0.05)",
          "rgba(214, 0, 253, 0.05)",
          "rgba(151, 32, 79, 0.05)",
          "rgba(150, 36, 49, 0.05)",
          "rgba(46, 162, 122, 0.05)",
          "rgba(31, 68, 135, 0.05)",
          "rgba(182, 150, 35, 0.05)",
          "rgba(10, 0, 81, 0.05)",
          "rgba(24, 130, 148, 0.05)",
          "rgba(15, 193, 100, 0.05)",
        ],
        color6: [
          "rgba(192, 40, 27, 0.05)",
          "rgba(64, 209, 0, 0.05)",
          "rgba(0, 168, 193, 0.05)",
          "rgba(224, 200, 0, 0.05)",
          "rgba(244, 138, 0, 0.05)",
          "rgba(39, 0, 237, 1)",
          "rgba(214, 0, 253, 0.05)",
          "rgba(151, 32, 79, 0.05)",
          "rgba(150, 36, 49, 0.05)",
          "rgba(46, 162, 122, 0.05)",
          "rgba(31, 68, 135, 0.05)",
          "rgba(182, 150, 35, 0.05)",
          "rgba(10, 0, 81, 0.05)",
          "rgba(24, 130, 148, 0.05)",
          "rgba(15, 193, 100, 0.05)",
        ],
        color7: [
          "rgba(192, 40, 27, 0.05)",
          "rgba(64, 209, 0, 0.05)",
          "rgba(0, 168, 193, 0.05)",
          "rgba(224, 200, 0, 0.05)",
          "rgba(244, 138, 0, 0.05)",
          "rgba(39, 0, 237, 0.05)",
          "rgba(214, 0, 253, 1)",
          "rgba(151, 32, 79, 0.05)",
          "rgba(150, 36, 49, 0.05)",
          "rgba(46, 162, 122, 0.05)",
          "rgba(31, 68, 135, 0.05)",
          "rgba(182, 150, 35, 0.05)",
          "rgba(10, 0, 81, 0.05)",
          "rgba(24, 130, 148, 0.05)",
          "rgba(15, 193, 100, 0.05)",
        ],
        color8: [
          "rgba(192, 40, 27, 0.05)",
          "rgba(64, 209, 0, 0.05)",
          "rgba(0, 168, 193, 0.05)",
          "rgba(224, 200, 0, 0.05)",
          "rgba(244, 138, 0, 0.05)",
          "rgba(39, 0, 237, 0.05)",
          "rgba(214, 0, 253, 0.05)",
          "rgba(151, 32, 79, 1)",
          "rgba(150, 36, 49, 0.05)",
          "rgba(46, 162, 122, 0.05)",
          "rgba(31, 68, 135, 0.05)",
          "rgba(182, 150, 35, 0.05)",
          "rgba(10, 0, 81, 0.05)",
          "rgba(24, 130, 148, 0.05)",
          "rgba(15, 193, 100, 0.05)",
        ],
        color9: [
          "rgba(192, 40, 27, 0.05)",
          "rgba(64, 209, 0, 0.05)",
          "rgba(0, 168, 193, 0.05)",
          "rgba(224, 200, 0, 0.05)",
          "rgba(244, 138, 0, 0.05)",
          "rgba(39, 0, 237, 0.05)",
          "rgba(214, 0, 253, 0.05)",
          "rgba(151, 32, 79, 0.05)",
          "rgba(150, 36, 49, 1)",
          "rgba(46, 162, 122, 0.05)",
          "rgba(31, 68, 135, 0.05)",
          "rgba(182, 150, 35, 0.05)",
          "rgba(10, 0, 81, 0.05)",
          "rgba(24, 130, 148, 0.05)",
          "rgba(15, 193, 100, 0.05)",
        ],
        color10: [
          "rgba(192, 40, 27, 0.05)",
          "rgba(64, 209, 0, 0.05)",
          "rgba(0, 168, 193, 0.05)",
          "rgba(224, 200, 0, 0.05)",
          "rgba(244, 138, 0, 0.05)",
          "rgba(39, 0, 237, 0.05)",
          "rgba(214, 0, 253, 0.05)",
          "rgba(151, 32, 79, 0.05)",
          "rgba(150, 36, 49, 0.05)",
          "rgba(46, 162, 122, 1)",
          "rgba(31, 68, 135, 0.05)",
          "rgba(182, 150, 35, 0.05)",
          "rgba(10, 0, 81, 0.05)",
          "rgba(24, 130, 148, 0.05)",
          "rgba(15, 193, 100, 0.05)",
        ],
        color11: [
          "rgba(192, 40, 27, 0.05)",
          "rgba(64, 209, 0, 0.05)",
          "rgba(0, 168, 193, 0.05)",
          "rgba(224, 200, 0, 0.05)",
          "rgba(244, 138, 0, 0.05)",
          "rgba(39, 0, 237, 0.05)",
          "rgba(214, 0, 253, 0.05)",
          "rgba(151, 32, 79, 0.05)",
          "rgba(150, 36, 49, 0.05)",
          "rgba(46, 162, 122, 0.05)",
          "rgba(31, 68, 135, 1)",
          "rgba(182, 150, 35, 0.05)",
          "rgba(10, 0, 81, 0.05)",
          "rgba(24, 130, 148, 0.05)",
          "rgba(15, 193, 100, 0.05)",
        ],
        color12: [
          "rgba(192, 40, 27, 0.05)",
          "rgba(64, 209, 0, 0.05)",
          "rgba(0, 168, 193, 0.05)",
          "rgba(224, 200, 0, 0.05)",
          "rgba(244, 138, 0, 0.05)",
          "rgba(39, 0, 237, 0.05)",
          "rgba(214, 0, 253, 0.05)",
          "rgba(151, 32, 79, 0.05)",
          "rgba(150, 36, 49, 0.05)",
          "rgba(46, 162, 122, 0.05)",
          "rgba(31, 68, 135, 0.05)",
          "rgba(182, 150, 35, 1)",
          "rgba(10, 0, 81, 0.05)",
          "rgba(24, 130, 148, 0.05)",
          "rgba(15, 193, 100, 0.05)",
        ],
        color13: [
          "rgba(192, 40, 27, 0.05)",
          "rgba(64, 209, 0, 0.05)",
          "rgba(0, 168, 193, 0.05)",
          "rgba(224, 200, 0, 0.05)",
          "rgba(244, 138, 0, 0.05)",
          "rgba(39, 0, 237, 0.05)",
          "rgba(214, 0, 253, 0.05)",
          "rgba(151, 32, 79, 0.05)",
          "rgba(150, 36, 49, 0.05)",
          "rgba(46, 162, 122, 0.05)",
          "rgba(31, 68, 135, 0.05)",
          "rgba(182, 150, 35, 0.05)",
          "rgba(10, 0, 81, 1)",
          "rgba(24, 130, 148, 0.05)",
          "rgba(15, 193, 100, 0.05)",
        ],
        color14: [
          "rgba(192, 40, 27, 0.05)",
          "rgba(64, 209, 0, 0.05)",
          "rgba(0, 168, 193, 0.05)",
          "rgba(224, 200, 0, 0.05)",
          "rgba(244, 138, 0, 0.05)",
          "rgba(39, 0, 237, 0.05)",
          "rgba(214, 0, 253, 0.05)",
          "rgba(151, 32, 79, 0.05)",
          "rgba(150, 36, 49, 0.05)",
          "rgba(46, 162, 122, 0.05)",
          "rgba(31, 68, 135, 0.05)",
          "rgba(182, 150, 35, 0.05)",
          "rgba(10, 0, 81, 0.05)",
          "rgba(24, 130, 148, 1)",
          "rgba(15, 193, 100, 0.05)",
        ],
        color15: [
          "rgba(192, 40, 27, 0.05)",
          "rgba(64, 209, 0, 0.05)",
          "rgba(0, 168, 193, 0.05)",
          "rgba(224, 200, 0, 0.05)",
          "rgba(244, 138, 0, 0.05)",
          "rgba(39, 0, 237, 0.05)",
          "rgba(214, 0, 253, 0.05)",
          "rgba(151, 32, 79, 0.05)",
          "rgba(150, 36, 49, 0.05)",
          "rgba(46, 162, 122, 0.05)",
          "rgba(31, 68, 135, 0.05)",
          "rgba(182, 150, 35, 0.05)",
          "rgba(10, 0, 81, 0.05)",
          "rgba(24, 130, 148, 0.05)",
          "rgba(15, 193, 100, 1)",
        ],
        names1: [
          "身高",
          "体重",
          "视力",
          "胸围",
          "体前屈",
          "臂展",
          "肺活量",
          "跑动",
          "跳跃",
          "敏捷",
          "平衡",
          "协调",
          "力量",
          "物控手",
          "物控脚",
        ],
        analyseName: "身高",
        analyseColor: "#C0281B",
        qgData: [],
        analyseData: [],
        analyseType: 1,
        analyseValue: {},
        times: [],
        rateData: {},
        qgHeight: [],
        ysHeight: [],
        analyseSource: [],
      },
      choice5: false,
      bigChoice5: false,
      choice6: false,
      bigChoice6: false,
      countryDbBean: {
        type: 1,
        anaIndex: 1,
        charts: null,
        charts1: null,
        analyseCharts: null,
        bigAnalyseCharts: null,
        title: "身高",
        mapAreaData: [],
        valueList: [],
        mapData: [],
        circleColor: "#00b29d",
        leftColor: "#00b29d",
        rightColor: "rgba(192, 40, 27, 1)",
        rateData: {},
        analyseData: [],
        analyseValue: {},
        qgData: [],
        names1: [
          "身高",
          "体重",
          "视力",
          "臂展",
          "肺活量",
          "胸围",
          "体前屈",
          "跑动",
          "跳跃",
          "敏捷",
          "力量",
          "协调",
          "平衡",
          "物控手",
          "物控脚",
        ],
        times: [],
        message: "",
        qgHeight: [],
        ysHeight: [],
      },
      choice7: false,
      bigChoice7: false,
      choice8: false,
      bigChoice8: false,
      choice9: false,
      bigChoice9: false,
      yecfBean: {
        charts: null,
        charts1: null,
        value1: 0,
        value2: 0,
        value3: 0,
        value4: 0,
        value5: 0,
        value6: 0,
        value7: 0,
        value8: 0,
        value9: 0,
        value10: 0,
        value11: 0,
        value12: 0,
        value13: 0,
        value14: 0,
        value15: 0,
        monthType: 1,
        analyseCharts: null,
        analyseCharts1: null,
        indicator: [],
        indicator1: [
          {
            name: "身高\nCM",
            max: 100,
          },
          { name: "体重\nKG", max: 100 },
          { name: "视力", max: 100 },
          { name: "臂展\nCM", max: 100 },
          { name: "肺活量\nML", max: 100 },
          { name: "胸围\nCM", max: 100 },
          { name: "体前屈\nCM", max: 100 },
        ],
        indicator2: [
          { name: "跑动\nS", max: 100 },
          { name: "跳跃\nS", max: 100 },
          { name: "敏捷\nS", max: 100 },
          { name: "力量\nS", max: 100 },
          { name: "协调\nS", max: 100 },
          { name: "平衡\nS", max: 100 },
          { name: "物控手\n个", max: 100 },
          { name: "物控脚\nS", max: 100 },
        ],
        qgValue: [],
        ysValue: [],
        analyseType: 1,
        analyseMonthType: 1,
        topData: [],
        topQgData: [],
        monthList: [],
        rateData: {},
        analyseData: [],
        analyseValue: {},
        qgData: [],
        qgHeight: [],
        ysHeight: [],
        names1: [
          "身高",
          "体重",
          "视力",
          "臂展",
          "肺活量",
          "胸围",
          "体前屈",
          "跑动",
          "跳跃",
          "敏捷",
          "力量",
          "协调",
          "平衡",
          "物控手",
          "物控脚",
        ],
        times: [],
        anaIndex: 1,
        leftColor: "#00b29d",
        rightColor: "rgba(192, 40, 27, 1)",
      },
      choice10: false,
      bigChoice10: false,
      choice11: false,
      bigChoice11: false,
      choice12: false,
      bigChoice12: false,
      loading: false,
    };
  },

  methods: {
    clickYsMonth(item) {
      this.ysMonthType = item.value;
      this.getYsTableData();
    },
    choiceYsZHBG(flag) {
      this.choice1 = flag;
    },
    clickBjMonth(item) {
      this.bjMonthType = item.value;
      this.getBjTableData();
    },
    choiceBjZHBG(flag) {
      this.choice2 = flag;
    },
    change(e) {
      this.currentGradeId = e;
      this.getBjTableData();
    },
    clickYeMonth(item) {
      this.yeMonthType = item.value;
      this.getYeTableData();
    },
    choiceYeZHBG(flag) {
      this.choice3 = flag;
    },
    choiceYefx(flag) {
      this.choice4 = flag;
    },
    choiceClick5(flag) {
      this.choice5 = flag;
    },
    choiceClick6(flag) {
      this.choice6 = flag;
    },
    choiceClick7(flag) {
      this.choice7 = flag;
    },
    choiceClick8(flag) {
      this.choice8 = flag;
    },
    choiceClick9(flag) {
      this.choice9 = flag;
    },
    choiceClick10(flag) {
      this.choice10 = flag;
    },
    choiceClick11(flag) {
      this.choice11 = flag;
    },
    choiceClick12(flag) {
      this.choice12 = flag;
    },
    // 生成报告
    createReport() {
      this.bigChoice1 = this.choice1;
      this.bigChoice2 = this.choice2;
      this.bigChoice3 = this.choice3;
      this.bigChoice4 = this.choice4;
      this.bigChoice5 = this.choice5;
      this.bigChoice6 = this.choice6;
      this.bigChoice7 = this.choice7;
      this.bigChoice8 = this.choice8;
      this.bigChoice9 = this.choice9;
      this.bigChoice10 = this.choice10;
      this.bigChoice11 = this.choice11;
      this.bigChoice12 = this.choice12;
    },
    // 获取月份
    getMonth() {
      this.api.pes.ysReportGetMonth().then((res) => {
        this.monthList = res.data;
        this.ysMonthType = this.monthList[0].value;
        this.bjMonthType = this.monthList[0].value;
        this.yeMonthType = this.monthList[0].value;
        this.getYsTableData();
        this.getBjTableData();
        this.getYeTableData();
      });
    },
    // 获取园所三维数据
    getYsTableData() {
      let data = {
        month: this.ysMonthType,
        type: 0,
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.pes.ysReportMonthNum(data).then((res) => {
        this.ysNum1 = res.data.z;
        this.ysNum2 = res.data.x;
        this.ysNum3 = res.data.y;
      });
    },
    // 获取班级三维数据
    getBjTableData() {
      let data = {
        month: this.ysMonthType,
        type: 0,
        schoolId: this.$store.state.userInfo.schoolId,
        gradeId: this.currentGradeId,
      };
      this.api.pes.ysReportMonthNum(data).then((res) => {
        this.bjNum1 = res.data.z;
        this.bjNum2 = res.data.x;
        this.bjNum3 = res.data.y;
      });
    },
    // 获取幼儿三维数据
    getYeTableData() {
      let data = {
        month: this.ysMonthType,
        type: 1,
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.pes.ysReportMonthNum(data).then((res) => {
        this.yeNum1 = res.data.z;
        this.yeNum2 = res.data.x;
        this.yeNum3 = res.data.y;
      });
    },
    getSchoolGradeList() {
      let that = this;
      this.api.school
        .getSchoolGradeList(this.$store.state.userInfo.schoolId)
        .then((res) => {
          if (res.flag) {
            that.classList.push(...res.data.小班);
            that.classList.push(...res.data.中班);
            that.classList.push(...res.data.大班);
            if (that.classList) {
              that.currentGradeId = that.classList[0].id;
              that.gradeName = that.classList[0].name;
            }
            that.getMonth();
          }
        });
    },
    clickColor(index) {
      this.growthBean.index = index;
      if (index == 1) {
        this.growthBean.colors = this.growthBean.color1;
      } else if (index == 2) {
        this.growthBean.colors = this.growthBean.color2;
      } else if (index == 3) {
        this.growthBean.colors = this.growthBean.color3;
      } else if (index == 4) {
        this.growthBean.colors = this.growthBean.color4;
      } else if (index == 5) {
        this.growthBean.colors = this.growthBean.color5;
      } else if (index == 6) {
        this.growthBean.colors = this.growthBean.color6;
      } else if (index == 7) {
        this.growthBean.colors = this.growthBean.color7;
      } else if (index == 8) {
        this.growthBean.colors = this.growthBean.color8;
      } else if (index == 9) {
        this.growthBean.colors = this.growthBean.color9;
      } else if (index == 10) {
        this.growthBean.colors = this.growthBean.color10;
      } else if (index == 11) {
        this.growthBean.colors = this.growthBean.color11;
      } else if (index == 12) {
        this.growthBean.colors = this.growthBean.color12;
      } else if (index == 13) {
        this.growthBean.colors = this.growthBean.color13;
      } else if (index == 14) {
        this.growthBean.colors = this.growthBean.color14;
      } else if (index == 15) {
        this.growthBean.colors = this.growthBean.color15;
      }
      this.drawGrowthLine("gLineChart");
      this.drawGrowthBigLine("bLineChart");
    },
    drawGrowthLine(id) {
      if (this.growthBean.charts == null) {
        this.growthBean.charts = echarts.init(document.getElementById(id));
      }
      this.growthBean.charts.setOption({
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)", // 提示背景颜色，默认为透明度为0.7的黑色
          borderColor: "#333", // 提示边框颜色
          borderRadius: 4, // 提示边框圆角，单位px，默认为4
          borderWidth: 0, // 提示边框线宽，单位px，默认为0（无边框）
          formatter: function (datas) {
            console.log(datas);
            var res = datas[0].axisValue + "月<br/>";
            for (var i = 0; i < datas.length; i++) {
              var val = datas[i].value;
              if (datas[i].seriesName == "身高") {
                val += "cm";
              } else if (datas[i].seriesName == "体重") {
                val += "kg";
              } else if (datas[i].seriesName == "臂展") {
                val += "cm";
              } else if (datas[i].seriesName == "胸围") {
                val += "cm";
              } else if (datas[i].seriesName == "肺活量") {
                val += "ml";
              } else if (datas[i].seriesName == "体前屈") {
                val += "cm";
              } else if (datas[i].seriesName == "跑动") {
                val += "s";
              } else if (datas[i].seriesName == "跳跃") {
                val += "s";
              } else if (datas[i].seriesName == "敏捷") {
                val += "s";
              } else if (datas[i].seriesName == "平衡") {
                val += "s";
              } else if (datas[i].seriesName == "协调") {
                val += "s";
              } else if (datas[i].seriesName == "力量") {
                val += "s";
              } else if (datas[i].seriesName == "物控手") {
                val += "个";
              } else if (datas[i].seriesName == "物控脚") {
                val += "个";
              }
              res += datas[i].seriesName + "：" + val + "<br/>";
            }
            return res;
          },
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            lineStyle: {
              // 直线指示器样式设置
              color: "#48b",
              width: 2,
              type: "solid",
            },
            shadowStyle: {
              // 阴影指示器样式设置
              width: "auto", // 阴影大小
              color: "rgba(0, 255, 198, 0.2)", // 阴影颜色
            },
          },
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
        },
        xAxis: {
          type: "category",
          data: [
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
          ],
          axisTick: {
            show: false, //去除刻度线
          },
          axisLine: {
            show: false, //去除轴线
          },
          axisLabel: {
            color: "#999999", //x轴文本颜色
            inside: true,
            fontSize: 10,
          },
          splitLine: {
            // 分隔线
            show: true, // 默认显示，属性show控制显示与否
            lineStyle: {
              // 属性lineStyle（详见lineStyle）控制线条样式
              color: "#C2C2C2",
              width: 2,
              type: "solid",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 150,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 45,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 5.4,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 110,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 50,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 180,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 2000,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 60,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: true,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 60,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: true,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 90,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: true,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 90,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 90,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: true,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 120,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: true,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 10,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 70,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: true,
          },
        ],

        series: [
          {
            name: this.growthBean.names1[0],
            type: "line",
            data: this.growthBean.datatz1,
            smooth: true,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[0],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[1],
            type: "line",
            data: this.growthBean.datatz2,
            smooth: true,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[1],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[2],
            type: "line",
            data: this.growthBean.datatz3,
            smooth: true,
            yAxisIndex: 2,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[2],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[3],
            type: "line",
            data: this.growthBean.datatz4,
            smooth: true,
            yAxisIndex: 3,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[3],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[4],
            type: "line",
            data: this.growthBean.datatz5,
            smooth: true,
            yAxisIndex: 4,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[4],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[5],
            type: "line",
            data: this.growthBean.datatz6,
            smooth: true,
            yAxisIndex: 5,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[5],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[6],
            type: "line",
            data: this.growthBean.datatz7,
            smooth: true,
            yAxisIndex: 6,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[6],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[7],
            type: "line",
            data: this.growthBean.datayd1,
            smooth: true,
            yAxisIndex: 7,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[7],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[8],
            type: "line",
            data: this.growthBean.datayd2,
            smooth: true,
            yAxisIndex: 8,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[8],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[9],
            type: "line",
            data: this.growthBean.datayd3,
            smooth: true,
            yAxisIndex: 9,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[9],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[10],
            type: "line",
            data: this.growthBean.datayd4,
            smooth: true,
            yAxisIndex: 10,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[10],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[11],
            type: "line",
            data: this.growthBean.datayd5,
            smooth: true,
            yAxisIndex: 11,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[11],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[12],
            type: "line",
            data: this.growthBean.datayd6,
            smooth: true,
            yAxisIndex: 12,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[12],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[13],
            type: "line",
            data: this.growthBean.datayd7,
            smooth: true,
            yAxisIndex: 13,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[13],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[14],
            type: "line",
            data: this.growthBean.datayd8,
            smooth: true,
            yAxisIndex: 14,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[14],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
        ],
      });
    },
    // 分析折线图
    drawGrowthAnalyseLine(id) {
      if (this.growthBean.analyseCharts == null) {
        this.growthBean.analyseCharts = echarts.init(
          document.getElementById(id)
        );
      }
      var that = this;
      this.growthBean.analyseCharts.setOption({
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)", // 提示背景颜色，默认为透明度为0.7的黑色
          borderColor: "#333", // 提示边框颜色
          borderRadius: 4, // 提示边框圆角，单位px，默认为4
          borderWidth: 0, // 提示边框线宽，单位px，默认为0（无边框）
          formatter: function (datas) {
            console.log(datas);
            var res = datas[0].axisValue + "月<br/>";
            for (var i = 0; i < datas.length; i++) {
              var val = datas[i].value;
              res += datas[i].seriesName + "：" + val + "%<br/>";
            }
            return "";
          },
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            lineStyle: {
              // 直线指示器样式设置
              color: "#48b",
              width: 2,
              type: "solid",
            },
            shadowStyle: {
              // 阴影指示器样式设置
              width: "auto", // 阴影大小
              color: "rgba(0, 255, 198, 0.2)", // 阴影颜色
            },
          },
          textStyle: {
            color: "#fff",
          },
        },
        legend: {
          orient: "horizontal",
          x: "left", //可设定图例在左、右、居中
          y: "top", //可设定图例在上、下、居中
          padding: [0, 0, 0, 0],
          data: ["全国", "园所"],
        },
        grid: {
          top: "40px",
          left: "0px",
          right: "0px",
          bottom: "5px",
        },
        xAxis: {
          type: "category",
          data: this.growthBean.times,
          axisTick: {
            show: false, //去除刻度线
          },
          axisLine: {
            show: true, //去除轴线
          },
          axisLabel: {
            color: "#e8e8e8", //x轴文本颜色
            inside: true,
            formatter: function () {
              return "";
            },
          },
          splitLine: {
            // 分隔线
            show: false, // 默认显示，属性show控制显示与否
            lineStyle: {
              // 属性lineStyle（详见lineStyle）控制线条样式
              color: "#C2C2C2",
              width: 2,
              type: "solid",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: false, // 默认显示，属性show控制显示与否
            },

            splitLine: {
              // 分隔线
              show: false, // 默认显示，属性show控制显示与否
            },
            max: 10,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
          },
        ],

        series: [
          {
            name: "全国",
            type: "bar",
            data: this.growthBean.qgHeight,
            smooth: true,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: "#000E56",
                lineStyle: {
                  width: 6, //设置线条粗细
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: function (data) {
                    return that.growthBean.qgData[data.dataIndex] + "%";
                  },
                },
              },
            },
          },
          {
            name: "园所",
            type: "bar",
            data: this.growthBean.ysHeight,
            smooth: true,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: this.growthBean.analyseColor,
                lineStyle: {
                  width: 6, //设置线条粗细
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: function (data) {
                    if (that.growthBean.analyseData[data.dataIndex] == "--") {
                      return "0.0%";
                    }
                    return that.growthBean.analyseData[data.dataIndex] + "%";
                  },
                },
              },
            },
          },
        ],
      });
    },
    drawGrowthBigLine(id) {
      if (this.growthBean.bigCharts == null) {
        this.growthBean.bigCharts = echarts.init(document.getElementById(id));
      }
      this.growthBean.bigCharts.setOption({
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)", // 提示背景颜色，默认为透明度为0.7的黑色
          borderColor: "#333", // 提示边框颜色
          borderRadius: 4, // 提示边框圆角，单位px，默认为4
          borderWidth: 0, // 提示边框线宽，单位px，默认为0（无边框）
          formatter: function (datas) {
            console.log(datas);
            var res = datas[0].axisValue + "月<br/>";
            for (var i = 0; i < datas.length; i++) {
              var val = datas[i].value;
              if (datas[i].seriesName == "身高") {
                val += "cm";
              } else if (datas[i].seriesName == "体重") {
                val += "kg";
              } else if (datas[i].seriesName == "臂展") {
                val += "cm";
              } else if (datas[i].seriesName == "胸围") {
                val += "cm";
              } else if (datas[i].seriesName == "肺活量") {
                val += "ml";
              } else if (datas[i].seriesName == "体前屈") {
                val += "cm";
              } else if (datas[i].seriesName == "跑动") {
                val += "s";
              } else if (datas[i].seriesName == "跳跃") {
                val += "s";
              } else if (datas[i].seriesName == "敏捷") {
                val += "s";
              } else if (datas[i].seriesName == "平衡") {
                val += "s";
              } else if (datas[i].seriesName == "协调") {
                val += "s";
              } else if (datas[i].seriesName == "力量") {
                val += "s";
              } else if (datas[i].seriesName == "物控手") {
                val += "个";
              } else if (datas[i].seriesName == "物控脚") {
                val += "个";
              }
              res += datas[i].seriesName + "：" + val + "<br/>";
            }
            return res;
          },
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            lineStyle: {
              // 直线指示器样式设置
              color: "#48b",
              width: 2,
              type: "solid",
            },
            shadowStyle: {
              // 阴影指示器样式设置
              width: "auto", // 阴影大小
              color: "rgba(0, 255, 198, 0.2)", // 阴影颜色
            },
          },
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
        },
        xAxis: {
          type: "category",
          data: [
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
          ],
          axisTick: {
            show: false, //去除刻度线
          },
          axisLine: {
            show: false, //去除轴线
          },
          axisLabel: {
            color: "#999999", //x轴文本颜色
            inside: true,
          },
          splitLine: {
            // 分隔线
            show: true, // 默认显示，属性show控制显示与否
            lineStyle: {
              // 属性lineStyle（详见lineStyle）控制线条样式
              color: "#C2C2C2",
              width: 2,
              type: "solid",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 150,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 45,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 5.4,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 110,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 50,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 180,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 2000,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 60,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: true,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 60,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: true,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 90,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: true,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 90,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: false,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 90,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: true,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 120,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: true,
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 10,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
          },
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            min: 0,
            max: 70,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
            inverse: true,
          },
        ],

        series: [
          {
            name: this.growthBean.names1[0],
            type: "line",
            data: this.growthBean.datatz1,
            smooth: true,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[0],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[1],
            type: "line",
            data: this.growthBean.datatz2,
            smooth: true,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[1],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[2],
            type: "line",
            data: this.growthBean.datatz3,
            smooth: true,
            yAxisIndex: 2,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[2],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[3],
            type: "line",
            data: this.growthBean.datatz4,
            smooth: true,
            yAxisIndex: 3,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[3],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[4],
            type: "line",
            data: this.growthBean.datatz5,
            smooth: true,
            yAxisIndex: 4,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[4],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[5],
            type: "line",
            data: this.growthBean.datatz6,
            smooth: true,
            yAxisIndex: 5,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[5],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[6],
            type: "line",
            data: this.growthBean.datatz7,
            smooth: true,
            yAxisIndex: 6,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[6],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[7],
            type: "line",
            data: this.growthBean.datayd1,
            smooth: true,
            yAxisIndex: 7,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[7],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[8],
            type: "line",
            data: this.growthBean.datayd2,
            smooth: true,
            yAxisIndex: 8,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[8],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[9],
            type: "line",
            data: this.growthBean.datayd3,
            smooth: true,
            yAxisIndex: 9,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[9],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[10],
            type: "line",
            data: this.growthBean.datayd4,
            smooth: true,
            yAxisIndex: 10,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[10],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[11],
            type: "line",
            data: this.growthBean.datayd5,
            smooth: true,
            yAxisIndex: 11,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[11],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[12],
            type: "line",
            data: this.growthBean.datayd6,
            smooth: true,
            yAxisIndex: 12,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[12],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[13],
            type: "line",
            data: this.growthBean.datayd7,
            smooth: true,
            yAxisIndex: 13,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[13],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: this.growthBean.names1[14],
            type: "line",
            data: this.growthBean.datayd8,
            smooth: true,
            yAxisIndex: 14,
            itemStyle: {
              normal: {
                color: this.growthBean.colors[14],
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
        ],
      });
    },
    // 分析折线图
    drawGrowthBigAnalyseLine(id) {
      if (this.growthBean.bigAnalyseCharts == null) {
        this.growthBean.bigAnalyseCharts = echarts.init(
          document.getElementById(id)
        );
      }
      var that = this;
      this.growthBean.bigAnalyseCharts.setOption({
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)", // 提示背景颜色，默认为透明度为0.7的黑色
          borderColor: "#333", // 提示边框颜色
          borderRadius: 4, // 提示边框圆角，单位px，默认为4
          borderWidth: 0, // 提示边框线宽，单位px，默认为0（无边框）
          formatter: function (datas) {
            console.log(datas);
            var res = datas[0].axisValue + "月<br/>";
            for (var i = 0; i < datas.length; i++) {
              var val = datas[i].value;
              res += datas[i].seriesName + "：" + val + "%<br/>";
            }
            return "";
          },
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            lineStyle: {
              // 直线指示器样式设置
              color: "#48b",
              width: 2,
              type: "solid",
            },
            shadowStyle: {
              // 阴影指示器样式设置
              width: "auto", // 阴影大小
              color: "rgba(0, 255, 198, 0.2)", // 阴影颜色
            },
          },
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          top: "50px",
          left: "0px",
          right: "0px",
          bottom: "10px",
        },
        legend: {
          orient: "vertical",
          x: "left", //可设定图例在左、右、居中
          y: "top", //可设定图例在上、下、居中
          padding: [0, 0, 0, 0],
          data: ["全国", "园所"],
        },
        xAxis: {
          type: "category",
          data: this.growthBean.times,
          axisTick: {
            show: false, //去除刻度线
          },
          axisLine: {
            show: false, //去除轴线
          },
          axisLabel: {
            color: "#e8e8e8", //x轴文本颜色
            inside: true,
            formatter: function () {
              return "";
            },
          },
          splitLine: {
            // 分隔线
            show: false, // 默认显示，属性show控制显示与否
          },
        },
        yAxis: [
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: false, // 默认显示，属性show控制显示与否
            },
            max: 10,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
          },
        ],

        series: [
          {
            name: "全国",
            type: "bar",
            data: this.growthBean.qgHeight,
            smooth: true,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: "#000E56",
                lineStyle: {
                  width: 6, //设置线条粗细
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: function (data) {
                    return that.growthBean.qgData[data.dataIndex] + "%";
                  },
                },
              },
            },
          },
          {
            name: "园所",
            type: "bar",
            data: this.growthBean.ysHeight,
            smooth: true,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: this.growthBean.analyseColor,
                lineStyle: {
                  width: 6, //设置线条粗细
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: function (data) {
                    if (that.growthBean.analyseData[data.dataIndex] == "--") {
                      return "0.0%";
                    }
                    return that.growthBean.analyseData[data.dataIndex] + "%";
                  },
                },
              },
            },
          },
        ],
      });
    },

    // 分析折线图，点击体质项
    clickGrowthAnalyseColor(index) {
      this.growthBean.anaIndex = index;
      this.growthBean.qgHeight = [];
      this.growthBean.ysHeight = [];
      if (index == 1) {
        this.growthBean.analyseColor = "#C0281B";
        this.growthBean.qgData = this.growthBean.analyseValue.B_height_country;
        this.growthBean.analyseData =
          this.growthBean.analyseValue.B_height_school;
        for (let i = 0; i < this.growthBean.analyseSource.length; i++) {
          this.growthBean.qgHeight.push(
            this.growthBean.analyseSource[i].B_height_kd[0]
          );
          this.growthBean.ysHeight.push(
            this.growthBean.analyseSource[i].B_height_kd[1]
          );
        }
      } else if (index == 2) {
        this.growthBean.analyseColor = "#40d100";
        this.growthBean.qgData = this.growthBean.analyseValue.B_weight_country;
        this.growthBean.analyseData =
          this.growthBean.analyseValue.B_weight_school;
        for (let i = 0; i < this.growthBean.analyseSource.length; i++) {
          this.growthBean.qgHeight.push(
            this.growthBean.analyseSource[i].B_weight_kd[0]
          );
          this.growthBean.ysHeight.push(
            this.growthBean.analyseSource[i].B_weight_kd[1]
          );
        }
      } else if (index == 3) {
        this.growthBean.analyseColor = "#00a8c1";
        this.growthBean.qgData = this.growthBean.analyseValue.B_eye_L_country;
        this.growthBean.analyseData =
          this.growthBean.analyseValue.B_eye_L_school;
        for (let i = 0; i < this.growthBean.analyseSource.length; i++) {
          this.growthBean.qgHeight.push(
            this.growthBean.analyseSource[i].B_eye_L_kd[0]
          );
          this.growthBean.ysHeight.push(
            this.growthBean.analyseSource[i].B_eye_L_kd[1]
          );
        }
      } else if (index == 4) {
        this.growthBean.analyseColor = "#f48a00";
        this.growthBean.qgData = this.growthBean.analyseValue.B_bust_country;
        this.growthBean.analyseData =
          this.growthBean.analyseValue.B_bust_school;
        for (let i = 0; i < this.growthBean.analyseSource.length; i++) {
          this.growthBean.qgHeight.push(
            this.growthBean.analyseSource[i].B_bust_kd[0]
          );
          this.growthBean.ysHeight.push(
            this.growthBean.analyseSource[i].B_bust_kd[1]
          );
        }
      } else if (index == 5) {
        this.growthBean.analyseColor = "#f48a00";
        this.growthBean.qgData = this.growthBean.analyseValue.B_flexion_country;
        this.growthBean.analyseData =
          this.growthBean.analyseValue.B_flexion_school;
        for (let i = 0; i < this.growthBean.analyseSource.length; i++) {
          this.growthBean.qgHeight.push(
            this.growthBean.analyseSource[i].B_flexion_kd[0]
          );
          this.growthBean.ysHeight.push(
            this.growthBean.analyseSource[i].B_flexion_kd[1]
          );
        }
      } else if (index == 6) {
        this.growthBean.analyseColor = "#2700ed";
        this.growthBean.qgData = this.growthBean.analyseValue.B_arm_country;
        this.growthBean.analyseData = this.growthBean.analyseValue.B_arm_school;
        for (let i = 0; i < this.growthBean.analyseSource.length; i++) {
          this.growthBean.qgHeight.push(
            this.growthBean.analyseSource[i].B_arm_kd[0]
          );
          this.growthBean.ysHeight.push(
            this.growthBean.analyseSource[i].B_arm_kd[1]
          );
        }
      } else if (index == 7) {
        this.growthBean.analyseColor = "#d600fd";
        this.growthBean.qgData = this.growthBean.analyseValue.B_lung_country;
        this.growthBean.analyseData =
          this.growthBean.analyseValue.B_lung_school;
        for (let i = 0; i < this.growthBean.analyseSource.length; i++) {
          this.growthBean.qgHeight.push(
            this.growthBean.analyseSource[i].B_lung_kd[0]
          );
          this.growthBean.ysHeight.push(
            this.growthBean.analyseSource[i].B_lung_kd[1]
          );
        }
      } else if (index == 8) {
        this.growthBean.analyseColor = "rgba(151, 32, 79, 1)";
        this.growthBean.qgData = this.growthBean.analyseValue.S_run_country;
        this.growthBean.analyseData = this.growthBean.analyseValue.S_run_school;
        for (let i = 0; i < this.growthBean.analyseSource.length; i++) {
          this.growthBean.qgHeight.push(
            this.growthBean.analyseSource[i].S_run_kd[0]
          );
          this.growthBean.ysHeight.push(
            this.growthBean.analyseSource[i].S_run_kd[1]
          );
        }
      } else if (index == 9) {
        this.growthBean.analyseColor = "rgba(150, 36, 49, 1)";
        this.growthBean.qgData = this.growthBean.analyseValue.S_jump_country;
        this.growthBean.analyseData =
          this.growthBean.analyseValue.S_jump_school;
        for (let i = 0; i < this.growthBean.analyseSource.length; i++) {
          this.growthBean.qgHeight.push(
            this.growthBean.analyseSource[i].S_jump_kd[0]
          );
          this.growthBean.ysHeight.push(
            this.growthBean.analyseSource[i].S_jump_kd[1]
          );
        }
      } else if (index == 10) {
        this.growthBean.analyseColor = "rgba(46, 162, 122, 1)";
        this.growthBean.qgData = this.growthBean.analyseValue.S_agile_country;
        this.growthBean.analyseData =
          this.growthBean.analyseValue.S_agile_school;
        for (let i = 0; i < this.growthBean.analyseSource.length; i++) {
          this.growthBean.qgHeight.push(
            this.growthBean.analyseSource[i].S_agile_kd[0]
          );
          this.growthBean.ysHeight.push(
            this.growthBean.analyseSource[i].S_agile_kd[1]
          );
        }
      } else if (index == 11) {
        this.growthBean.analyseColor = "rgba(31, 68, 135, 1)";
        this.growthBean.qgData = this.growthBean.analyseValue.S_balance_country;
        this.growthBean.analyseData =
          this.growthBean.analyseValue.S_balance_school;
        for (let i = 0; i < this.growthBean.analyseSource.length; i++) {
          this.growthBean.qgHeight.push(
            this.growthBean.analyseSource[i].S_balance_kd[0]
          );
          this.growthBean.ysHeight.push(
            this.growthBean.analyseSource[i].S_balance_kd[1]
          );
        }
      } else if (index == 12) {
        this.growthBean.analyseColor = "rgba(182, 150, 35, 1)";
        this.growthBean.qgData =
          this.growthBean.analyseValue.S_coordinate_country;
        this.growthBean.analyseData =
          this.growthBean.analyseValue.S_coordinate_school;
        for (let i = 0; i < this.growthBean.analyseSource.length; i++) {
          this.growthBean.qgHeight.push(
            this.growthBean.analyseSource[i].S_coordinate_kd[0]
          );
          this.growthBean.ysHeight.push(
            this.growthBean.analyseSource[i].S_coordinate_kd[1]
          );
        }
      } else if (index == 13) {
        this.growthBean.analyseColor = "rgb(60, 57, 87)";
        this.growthBean.qgData = this.growthBean.analyseValue.S_power_country;
        this.growthBean.analyseData =
          this.growthBean.analyseValue.S_power_school;
        for (let i = 0; i < this.growthBean.analyseSource.length; i++) {
          this.growthBean.qgHeight.push(
            this.growthBean.analyseSource[i].S_power_kd[0]
          );
          this.growthBean.ysHeight.push(
            this.growthBean.analyseSource[i].S_power_kd[1]
          );
        }
      } else if (index == 14) {
        this.growthBean.analyseColor = "rgba(24, 130, 148, 1)";
        this.growthBean.qgData =
          this.growthBean.analyseValue.S_ctrl_hand_country;
        this.growthBean.analyseData =
          this.growthBean.analyseValue.S_ctrl_hand_school;
        for (let i = 0; i < this.growthBean.analyseSource.length; i++) {
          this.growthBean.qgHeight.push(
            this.growthBean.analyseSource[i].S_ctrl_hand_kd[0]
          );
          this.growthBean.ysHeight.push(
            this.growthBean.analyseSource[i].S_ctrl_hand_kd[1]
          );
        }
      } else if (index == 15) {
        this.growthBean.analyseColor = "rgba(15, 193, 100, 1)";
        this.growthBean.qgData =
          this.growthBean.analyseValue.S_ctrl_foot_country;
        this.growthBean.analyseData =
          this.growthBean.analyseValue.S_ctrl_foot_school;
        for (let i = 0; i < this.growthBean.analyseSource.length; i++) {
          this.growthBean.qgHeight.push(
            this.growthBean.analyseSource[i].S_ctrl_foot_kd[0]
          );
          this.growthBean.ysHeight.push(
            this.growthBean.analyseSource[i].S_ctrl_foot_kd[1]
          );
        }
      }
      this.drawGrowthAnalyseLine("growthAnalyseChart");
      this.drawGrowthBigAnalyseLine("growthBigAnalyseChart");
    },
    // 获取顶部折线图数据
    getTopLineData() {
      let data = {};
      this.api.pes.growthValueTopData(data).then((res) => {
        this.growthBean.datatz1.push(...res.data[0].formatValues.B_height);
        this.growthBean.datatz1.push(...res.data[1].formatValues.B_height);
        this.growthBean.datatz1.push(...res.data[2].formatValues.B_height);
        this.growthBean.datatz2.push(...res.data[0].formatValues.B_weight);
        this.growthBean.datatz2.push(...res.data[1].formatValues.B_weight);
        this.growthBean.datatz2.push(...res.data[2].formatValues.B_weight);
        this.growthBean.datatz3.push(...res.data[0].formatValues.B_eye_L);
        this.growthBean.datatz3.push(...res.data[1].formatValues.B_eye_L);
        this.growthBean.datatz3.push(...res.data[2].formatValues.B_eye_L);
        this.growthBean.datatz4.push(...res.data[0].formatValues.B_bust);
        this.growthBean.datatz4.push(...res.data[1].formatValues.B_bust);
        this.growthBean.datatz4.push(...res.data[2].formatValues.B_bust);
        this.growthBean.datatz5.push(...res.data[0].formatValues.B_flexion);
        this.growthBean.datatz5.push(...res.data[1].formatValues.B_flexion);
        this.growthBean.datatz5.push(...res.data[2].formatValues.B_flexion);
        this.growthBean.datatz6.push(...res.data[0].formatValues.B_arm);
        this.growthBean.datatz6.push(...res.data[1].formatValues.B_arm);
        this.growthBean.datatz6.push(...res.data[2].formatValues.B_arm);
        this.growthBean.datatz7.push(...res.data[0].formatValues.B_lung);
        this.growthBean.datatz7.push(...res.data[1].formatValues.B_lung);
        this.growthBean.datatz7.push(...res.data[2].formatValues.B_lung);
        this.growthBean.datayd1.push(...res.data[0].formatValues.S_run);
        this.growthBean.datayd1.push(...res.data[1].formatValues.S_run);
        this.growthBean.datayd1.push(...res.data[2].formatValues.S_run);
        this.growthBean.datayd2.push(...res.data[0].formatValues.S_jump);
        this.growthBean.datayd2.push(...res.data[1].formatValues.S_jump);
        this.growthBean.datayd2.push(...res.data[2].formatValues.S_jump);
        this.growthBean.datayd3.push(...res.data[0].formatValues.S_agile);
        this.growthBean.datayd3.push(...res.data[1].formatValues.S_agile);
        this.growthBean.datayd3.push(...res.data[2].formatValues.S_agile);
        this.growthBean.datayd4.push(...res.data[0].formatValues.S_balance);
        this.growthBean.datayd4.push(...res.data[1].formatValues.S_balance);
        this.growthBean.datayd4.push(...res.data[2].formatValues.S_balance);
        this.growthBean.datayd5.push(...res.data[0].formatValues.S_coordinate);
        this.growthBean.datayd5.push(...res.data[1].formatValues.S_coordinate);
        this.growthBean.datayd5.push(...res.data[2].formatValues.S_coordinate);
        this.growthBean.datayd6.push(...res.data[0].formatValues.S_power);
        this.growthBean.datayd6.push(...res.data[1].formatValues.S_power);
        this.growthBean.datayd6.push(...res.data[2].formatValues.S_power);
        this.growthBean.datayd7.push(...res.data[0].formatValues.S_ctrl_hand);
        this.growthBean.datayd7.push(...res.data[1].formatValues.S_ctrl_hand);
        this.growthBean.datayd7.push(...res.data[2].formatValues.S_ctrl_hand);
        this.growthBean.datayd8.push(...res.data[0].formatValues.S_ctrl_foot);
        this.growthBean.datayd8.push(...res.data[1].formatValues.S_ctrl_foot);
        this.growthBean.datayd8.push(...res.data[2].formatValues.S_ctrl_foot);
        this.$nextTick(function () {
          this.drawGrowthLine("gLineChart");
          this.drawGrowthBigLine("bLineChart");
        });
      });
    },
    // 获取增长率数据
    getRateData() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.pes.growthValueRateData(data).then((res) => {
        this.growthBean.rateData = res.data;
      });
    },
    // 获取一键分析数据
    getAnalyseData() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.pes.growthValueAnalyseData(data).then((res) => {
        this.growthBean.analyseValue = res.data.formatValues;
        this.growthBean.analyseSource = res.data.values;
        this.growthBean.qgData = this.growthBean.analyseValue.B_height_country;
        this.growthBean.analyseData =
          this.growthBean.analyseValue.B_height_school;
        for (let i = 0; i < this.growthBean.analyseSource.length; i++) {
          this.growthBean.qgHeight.push(
            this.growthBean.analyseSource[i].B_height_kd[0]
          );
          this.growthBean.ysHeight.push(
            this.growthBean.analyseSource[i].B_height_kd[1]
          );
        }
        this.growthBean.times = this.growthBean.analyseValue.time;
        this.$nextTick(function () {
          this.drawGrowthAnalyseLine("growthAnalyseChart");
          this.drawGrowthBigAnalyseLine("growthBigAnalyseChart");
        });
      });
    },

    // 全国对比方法
    clickMapTab(index) {
      this.countryDbBean.type = index;
      if (index == 1) {
        this.countryDbBean.title = "身高";
        this.countryDbBean.circleColor = "#00b29d";
        this.countryDbBean.valueList = [];
        for (let i = 0; i < this.countryDbBean.mapData.length; i++) {
          this.countryDbBean.valueList.push({
            id: i,
            num1: this.countryDbBean.mapData[i].small.B_height,
            num2: this.countryDbBean.mapData[i].middle.B_height,
            num3: this.countryDbBean.mapData[i].big.B_height,
          });
        }
      } else if (index == 2) {
        this.countryDbBean.title = "体重";
        this.countryDbBean.circleColor = "#88391e";
        this.countryDbBean.valueList = [];
        for (let i = 0; i < this.countryDbBean.mapData.length; i++) {
          this.countryDbBean.valueList.push({
            id: i,
            num1: this.countryDbBean.mapData[i].small.B_weight,
            num2: this.countryDbBean.mapData[i].middle.B_weight,
            num3: this.countryDbBean.mapData[i].big.B_weight,
          });
        }
      } else if (index == 3) {
        this.countryDbBean.title = "视力";
        this.countryDbBean.circleColor = "#344f00";
        this.countryDbBean.valueList = [];
        for (let i = 0; i < this.countryDbBean.mapData.length; i++) {
          this.countryDbBean.valueList.push({
            id: i,
            num1: this.countryDbBean.mapData[i].small.B_eye_L,
            num2: this.countryDbBean.mapData[i].middle.B_eye_L,
            num3: this.countryDbBean.mapData[i].big.B_eye_L,
          });
        }
      } else if (index == 4) {
        this.countryDbBean.title = "臂展";
        this.countryDbBean.circleColor = "#002055";
        this.countryDbBean.valueList = [];
        for (let i = 0; i < this.countryDbBean.mapData.length; i++) {
          this.countryDbBean.valueList.push({
            id: i,
            num1: this.countryDbBean.mapData[i].small.B_arm,
            num2: this.countryDbBean.mapData[i].middle.B_arm,
            num3: this.countryDbBean.mapData[i].big.B_arm,
          });
        }
      } else if (index == 5) {
        this.countryDbBean.title = "肺活量";
        this.countryDbBean.circleColor = "#005047";
        this.countryDbBean.valueList = [];
        for (let i = 0; i < this.countryDbBean.mapData.length; i++) {
          this.countryDbBean.valueList.push({
            id: i,
            num1: this.countryDbBean.mapData[i].small.B_lung,
            num2: this.countryDbBean.mapData[i].middle.B_lung,
            num3: this.countryDbBean.mapData[i].big.B_lung,
          });
        }
      } else if (index == 6) {
        this.countryDbBean.title = "胸围";
        this.countryDbBean.circleColor = "#500009";
        this.countryDbBean.valueList = [];
        for (let i = 0; i < this.countryDbBean.mapData.length; i++) {
          this.countryDbBean.valueList.push({
            id: i,
            num1: this.countryDbBean.mapData[i].small.B_bust,
            num2: this.countryDbBean.mapData[i].middle.B_bust,
            num3: this.countryDbBean.mapData[i].big.B_bust,
          });
        }
      } else if (index == 7) {
        this.countryDbBean.title = "体前屈";
        this.countryDbBean.circleColor = "#41cbb9";
        this.countryDbBean.valueList = [];
        for (let i = 0; i < this.countryDbBean.mapData.length; i++) {
          this.countryDbBean.valueList.push({
            id: i,
            num1: this.countryDbBean.mapData[i].small.B_flexion,
            num2: this.countryDbBean.mapData[i].middle.B_flexion,
            num3: this.countryDbBean.mapData[i].big.B_flexion,
          });
        }
      } else if (index == 8) {
        this.countryDbBean.title = "跑动";
        this.countryDbBean.circleColor = "#a37d8c";
        this.countryDbBean.valueList = [];
        for (let i = 0; i < this.countryDbBean.mapData.length; i++) {
          this.countryDbBean.valueList.push({
            id: i,
            num1: this.countryDbBean.mapData[i].small.S_run,
            num2: this.countryDbBean.mapData[i].middle.S_run,
            num3: this.countryDbBean.mapData[i].big.S_run,
          });
        }
      } else if (index == 9) {
        this.countryDbBean.title = "跳跃";
        this.countryDbBean.circleColor = "#55242a";
        this.countryDbBean.valueList = [];
        for (let i = 0; i < this.countryDbBean.mapData.length; i++) {
          this.countryDbBean.valueList.push({
            id: i,
            num1: this.countryDbBean.mapData[i].small.S_jump,
            num2: this.countryDbBean.mapData[i].middle.S_jump,
            num3: this.countryDbBean.mapData[i].big.S_jump,
          });
        }
      } else if (index == 10) {
        this.countryDbBean.title = "敏捷";
        this.countryDbBean.circleColor = "#2e7aa2";
        this.countryDbBean.valueList = [];
        for (let i = 0; i < this.countryDbBean.mapData.length; i++) {
          this.countryDbBean.valueList.push({
            id: i,
            num1: this.countryDbBean.mapData[i].small.S_agile,
            num2: this.countryDbBean.mapData[i].middle.S_agile,
            num3: this.countryDbBean.mapData[i].big.S_agile,
          });
        }
      } else if (index == 11) {
        this.countryDbBean.title = "力量";
        this.countryDbBean.circleColor = "#263d69";
        this.countryDbBean.valueList = [];
        for (let i = 0; i < this.countryDbBean.mapData.length; i++) {
          this.countryDbBean.valueList.push({
            id: i,
            num1: this.countryDbBean.mapData[i].small.S_power,
            num2: this.countryDbBean.mapData[i].middle.S_power,
            num3: this.countryDbBean.mapData[i].big.S_power,
          });
        }
      } else if (index == 12) {
        this.countryDbBean.title = "协调";
        this.countryDbBean.circleColor = "#705d17";
        this.countryDbBean.valueList = [];
        for (let i = 0; i < this.countryDbBean.mapData.length; i++) {
          this.countryDbBean.valueList.push({
            id: i,
            num1: this.countryDbBean.mapData[i].small.S_coordinate,
            num2: this.countryDbBean.mapData[i].middle.S_coordinate,
            num3: this.countryDbBean.mapData[i].big.S_coordinate,
          });
        }
      } else if (index == 13) {
        this.countryDbBean.title = "平衡";
        this.countryDbBean.circleColor = "#230db5";
        this.countryDbBean.valueList = [];
        for (let i = 0; i < this.countryDbBean.mapData.length; i++) {
          this.countryDbBean.valueList.push({
            id: i,
            num1: this.countryDbBean.mapData[i].small.S_balance,
            num2: this.countryDbBean.mapData[i].middle.S_balance,
            num3: this.countryDbBean.mapData[i].big.S_balance,
          });
        }
      } else if (index == 14) {
        this.countryDbBean.title = "物控手";
        this.countryDbBean.circleColor = "#14c0de";
        this.countryDbBean.valueList = [];
        for (let i = 0; i < this.countryDbBean.mapData.length; i++) {
          this.countryDbBean.valueList.push({
            id: i,
            num1: this.countryDbBean.mapData[i].small.S_ctrl_hand,
            num2: this.countryDbBean.mapData[i].middle.S_ctrl_hand,
            num3: this.countryDbBean.mapData[i].big.S_ctrl_hand,
          });
        }
      } else if (index == 15) {
        this.countryDbBean.title = "物控脚";
        this.countryDbBean.circleColor = "#159c56";
        this.countryDbBean.valueList = [];
        for (let i = 0; i < this.countryDbBean.mapData.length; i++) {
          this.countryDbBean.valueList.push({
            id: i,
            num1: this.countryDbBean.mapData[i].small.S_ctrl_foot,
            num2: this.countryDbBean.mapData[i].middle.S_ctrl_foot,
            num3: this.countryDbBean.mapData[i].big.S_ctrl_foot,
          });
        }
      }
      this.drawMap();
    },
    clickAnalyseType(index) {
      this.countryDbBean.anaIndex = index;
      this.countryDbBean.qgData = [];
      this.countryDbBean.analyseData = [];
      this.countryDbBean.qgHeight = [];
      this.countryDbBean.ysHeight = [];
      if (index == 1) {
        this.countryDbBean.leftColor = "#00b29d";
        this.countryDbBean.rightColor = "rgba(192, 40, 27, 1)";
        for (let i = 0; i < this.countryDbBean.analyseValue.length; i++) {
          this.countryDbBean.qgData.push(
            this.countryDbBean.analyseValue[i].B_height[0]
          );
          this.countryDbBean.analyseData.push(
            this.countryDbBean.analyseValue[i].B_height[1]
          );
          this.countryDbBean.qgHeight.push(
            this.countryDbBean.analyseValue[i].B_height_kd[0]
          );
          this.countryDbBean.ysHeight.push(
            this.countryDbBean.analyseValue[i].B_height_kd[1]
          );
        }
      } else if (index == 2) {
        this.countryDbBean.leftColor = "#88391e";
        this.countryDbBean.rightColor = "rgba(64, 209, 0, 1)";
        for (let i = 0; i < this.countryDbBean.analyseValue.length; i++) {
          this.countryDbBean.qgData.push(
            this.countryDbBean.analyseValue[i].B_weight[0]
          );
          this.countryDbBean.analyseData.push(
            this.countryDbBean.analyseValue[i].B_weight[1]
          );
          this.countryDbBean.qgHeight.push(
            this.countryDbBean.analyseValue[i].B_weight_kd[0]
          );
          this.countryDbBean.ysHeight.push(
            this.countryDbBean.analyseValue[i].B_weight_kd[1]
          );
        }
      } else if (index == 3) {
        this.countryDbBean.leftColor = "#344f00";
        this.countryDbBean.rightColor = "rgba(0, 168, 193, 1)";
        for (let i = 0; i < this.countryDbBean.analyseValue.length; i++) {
          this.countryDbBean.qgData.push(
            this.countryDbBean.analyseValue[i].B_eye_L[0]
          );
          this.countryDbBean.analyseData.push(
            this.countryDbBean.analyseValue[i].B_eye_L[1]
          );
          this.countryDbBean.qgHeight.push(
            this.countryDbBean.analyseValue[i].B_eye_L_kd[0]
          );
          this.countryDbBean.ysHeight.push(
            this.countryDbBean.analyseValue[i].B_eye_L_kd[1]
          );
        }
      } else if (index == 4) {
        this.countryDbBean.leftColor = "#002055";
        this.countryDbBean.rightColor = "rgba(224, 200, 0, 1)";
        for (let i = 0; i < this.countryDbBean.analyseValue.length; i++) {
          this.countryDbBean.qgData.push(
            this.countryDbBean.analyseValue[i].B_arm[0]
          );
          this.countryDbBean.analyseData.push(
            this.countryDbBean.analyseValue[i].B_arm[1]
          );
          this.countryDbBean.qgHeight.push(
            this.countryDbBean.analyseValue[i].B_arm_kd[0]
          );
          this.countryDbBean.ysHeight.push(
            this.countryDbBean.analyseValue[i].B_arm_kd[1]
          );
        }
      } else if (index == 5) {
        this.countryDbBean.leftColor = "#005047";
        this.countryDbBean.rightColor = "rgba(244, 138, 0, 1)";
        for (let i = 0; i < this.countryDbBean.analyseValue.length; i++) {
          this.countryDbBean.qgData.push(
            this.countryDbBean.analyseValue[i].B_lung[0]
          );
          this.countryDbBean.analyseData.push(
            this.countryDbBean.analyseValue[i].B_lung[1]
          );
          this.countryDbBean.qgHeight.push(
            this.countryDbBean.analyseValue[i].B_lung_kd[0]
          );
          this.countryDbBean.ysHeight.push(
            this.countryDbBean.analyseValue[i].B_lung_kd[1]
          );
        }
      } else if (index == 6) {
        this.countryDbBean.leftColor = "#500009";
        this.countryDbBean.rightColor = "rgba(39, 0, 237, 1)";
        for (let i = 0; i < this.countryDbBean.analyseValue.length; i++) {
          this.countryDbBean.qgData.push(
            this.countryDbBean.analyseValue[i].B_bust[0]
          );
          this.countryDbBean.analyseData.push(
            this.countryDbBean.analyseValue[i].B_bust[1]
          );
          this.countryDbBean.qgHeight.push(
            this.countryDbBean.analyseValue[i].B_bust_kd[0]
          );
          this.countryDbBean.ysHeight.push(
            this.countryDbBean.analyseValue[i].B_bust_kd[1]
          );
        }
      } else if (index == 7) {
        this.countryDbBean.leftColor = "#41cbb9";
        this.countryDbBean.rightColor = "rgba(214, 0, 253, 1)";
        for (let i = 0; i < this.countryDbBean.analyseValue.length; i++) {
          this.countryDbBean.qgData.push(
            this.countryDbBean.analyseValue[i].B_flexion[0]
          );
          this.countryDbBean.analyseData.push(
            this.countryDbBean.analyseValue[i].B_flexion[1]
          );
          this.countryDbBean.qgHeight.push(
            this.countryDbBean.analyseValue[i].B_flexion_kd[0]
          );
          this.countryDbBean.ysHeight.push(
            this.countryDbBean.analyseValue[i].B_flexion_kd[1]
          );
        }
      } else if (index == 8) {
        this.countryDbBean.leftColor = "#a37d8c";
        this.countryDbBean.rightColor = "rgba(151, 32, 79, 1)";
        for (let i = 0; i < this.countryDbBean.analyseValue.length; i++) {
          this.countryDbBean.qgData.push(
            this.countryDbBean.analyseValue[i].S_run[0]
          );
          this.countryDbBean.analyseData.push(
            this.countryDbBean.analyseValue[i].S_run[1]
          );
          this.countryDbBean.qgHeight.push(
            this.countryDbBean.analyseValue[i].S_run_kd[0]
          );
          this.countryDbBean.ysHeight.push(
            this.countryDbBean.analyseValue[i].S_run_kd[1]
          );
        }
      } else if (index == 9) {
        this.countryDbBean.leftColor = "#55242a";
        this.countryDbBean.rightColor = "rgba(150, 36, 49, 1)";
        for (let i = 0; i < this.countryDbBean.analyseValue.length; i++) {
          this.countryDbBean.qgData.push(
            this.countryDbBean.analyseValue[i].S_jump[0]
          );
          this.countryDbBean.analyseData.push(
            this.countryDbBean.analyseValue[i].S_jump[1]
          );
          this.countryDbBean.qgHeight.push(
            this.countryDbBean.analyseValue[i].S_jump_kd[0]
          );
          this.countryDbBean.ysHeight.push(
            this.countryDbBean.analyseValue[i].S_jump_kd[1]
          );
        }
      } else if (index == 10) {
        this.countryDbBean.leftColor = "#2e7aa2";
        this.countryDbBean.rightColor = "#2ea27a";
        for (let i = 0; i < this.countryDbBean.analyseValue.length; i++) {
          this.countryDbBean.qgData.push(
            this.countryDbBean.analyseValue[i].S_agile[0]
          );
          this.countryDbBean.analyseData.push(
            this.countryDbBean.analyseValue[i].S_agile[1]
          );
          this.countryDbBean.qgHeight.push(
            this.countryDbBean.analyseValue[i].S_agile_kd[0]
          );
          this.countryDbBean.ysHeight.push(
            this.countryDbBean.analyseValue[i].S_agile_kd[1]
          );
        }
      } else if (index == 11) {
        this.countryDbBean.leftColor = "#263d69";
        this.countryDbBean.rightColor = "#1f4487";
        for (let i = 0; i < this.countryDbBean.analyseValue.length; i++) {
          this.countryDbBean.qgData.push(
            this.countryDbBean.analyseValue[i].S_power[0]
          );
          this.countryDbBean.analyseData.push(
            this.countryDbBean.analyseValue[i].S_power[1]
          );
          this.countryDbBean.qgHeight.push(
            this.countryDbBean.analyseValue[i].S_power_kd[0]
          );
          this.countryDbBean.ysHeight.push(
            this.countryDbBean.analyseValue[i].S_power_kd[1]
          );
        }
      } else if (index == 12) {
        this.countryDbBean.leftColor = "#705d17";
        this.countryDbBean.rightColor = "rgba(182, 150, 35, 1)";
        for (let i = 0; i < this.countryDbBean.analyseValue.length; i++) {
          this.countryDbBean.qgData.push(
            this.countryDbBean.analyseValue[i].S_coordinate[0]
          );
          this.countryDbBean.analyseData.push(
            this.countryDbBean.analyseValue[i].S_coordinate[1]
          );
          this.countryDbBean.qgHeight.push(
            this.countryDbBean.analyseValue[i].S_coordinate_kd[0]
          );
          this.countryDbBean.ysHeight.push(
            this.countryDbBean.analyseValue[i].S_coordinate_kd[1]
          );
        }
      } else if (index == 13) {
        this.countryDbBean.leftColor = "#230db5";
        this.countryDbBean.rightColor = "rgb(60, 57, 87)";
        for (let i = 0; i < this.countryDbBean.analyseValue.length; i++) {
          this.countryDbBean.qgData.push(
            this.countryDbBean.analyseValue[i].S_balance[0]
          );
          this.countryDbBean.analyseData.push(
            this.countryDbBean.analyseValue[i].S_balance[1]
          );
          this.countryDbBean.qgHeight.push(
            this.countryDbBean.analyseValue[i].S_balance_kd[0]
          );
          this.countryDbBean.ysHeight.push(
            this.countryDbBean.analyseValue[i].S_balance_kd[1]
          );
        }
      } else if (index == 14) {
        this.countryDbBean.leftColor = "#14c0de";
        this.countryDbBean.rightColor = "rgba(24, 130, 148, 1)";
        for (let i = 0; i < this.countryDbBean.analyseValue.length; i++) {
          this.countryDbBean.qgData.push(
            this.countryDbBean.analyseValue[i].S_ctrl_hand[0]
          );
          this.countryDbBean.analyseData.push(
            this.countryDbBean.analyseValue[i].S_ctrl_hand[1]
          );
          this.countryDbBean.qgHeight.push(
            this.countryDbBean.analyseValue[i].S_ctrl_hand_kd[0]
          );
          this.countryDbBean.ysHeight.push(
            this.countryDbBean.analyseValue[i].S_ctrl_hand_kd[1]
          );
        }
      } else if (index == 15) {
        this.countryDbBean.leftColor = "#159c56";
        this.countryDbBean.rightColor = "rgba(15, 193, 100, 1)";
        for (let i = 0; i < this.countryDbBean.analyseValue.length; i++) {
          this.countryDbBean.qgData.push(
            this.countryDbBean.analyseValue[i].S_ctrl_foot[0]
          );
          this.countryDbBean.analyseData.push(
            this.countryDbBean.analyseValue[i].S_ctrl_foot[1]
          );
          this.countryDbBean.qgHeight.push(
            this.countryDbBean.analyseValue[i].S_ctrl_foot_kd[0]
          );
          this.countryDbBean.ysHeight.push(
            this.countryDbBean.analyseValue[i].S_ctrl_foot_kd[1]
          );
        }
      }
      this.drawCountryAnalyseLine("countryAnalyseChart");
      this.drawBigCountryAnalyseLine("bcountryAnalyseChart");
    },
    drawMap() {
      //初始化echarts实例 通过传参获取要放置的id名称
      var that = this;
      if (this.countryDbBean.charts == null) {
        this.countryDbBean.charts = echarts.init(
          document.getElementById("chinaMap")
        );
      }
      if (this.countryDbBean.charts1 == null) {
        this.countryDbBean.charts1 = echarts.init(
          document.getElementById("chinaMap1")
        );
      }
      // 指定图表的配置项和数据
      var optionMap = {
        //设置 背景颜色
        backgroundColor: "#f1f1f1",
        tooltip: {
          trigger: "item",
          formatter: function (datas) {
            var res = datas.name + "-" + that.countryDbBean.title + "<br/>";
            for (var i = 0; i < that.countryDbBean.valueList.length; i++) {
              if (that.countryDbBean.valueList[i].id == datas.value) {
                res +=
                  "小班：" + that.countryDbBean.valueList[i].num1 + "<br/>";
                res +=
                  "中班：" + that.countryDbBean.valueList[i].num2 + "<br/>";
                res +=
                  "大班：" + that.countryDbBean.valueList[i].num3 + "<br/>";
                break;
              }
            }
            return res;
          },
        },
        geo: {
          map: "china",
          label: {
            emphasis: {
              show: false,
            },
          },
          roam: false,
          regions: this.countryDbBean.mapAreaData,
        },
        //配置属性
        series: [
          {
            title: this.countryDbBean.title,
            type: "map",
            map: "china",
            geoIndex: 0,
            roam: false,
            animation: false,
            data: this.countryDbBean.mapAreaData,
          },
        ],
      };
      var optionMap1 = {
        //设置 背景颜色
        backgroundColor: "#e8e8e8",
        tooltip: {
          trigger: "item",
          formatter: function (datas) {
            var res = datas.name + "-" + that.countryDbBean.title + "<br/>";
            for (var i = 0; i < that.countryDbBean.valueList.length; i++) {
              if (that.countryDbBean.valueList[i].id == datas.value) {
                res +=
                  "小班：" + that.countryDbBean.valueList[i].num1 + "<br/>";
                res +=
                  "中班：" + that.countryDbBean.valueList[i].num2 + "<br/>";
                res +=
                  "大班：" + that.countryDbBean.valueList[i].num3 + "<br/>";
                break;
              }
            }
            return res;
          },
        },
        geo: {
          map: "china",
          label: {
            emphasis: {
              show: false,
            },
          },
          roam: false,
          regions: this.countryDbBean.mapAreaData,
        },
        //配置属性
        series: [
          {
            title: this.countryDbBean.title,
            type: "map",
            map: "china",
            geoIndex: 0,
            roam: false,
            animation: false,
            data: this.countryDbBean.mapAreaData,
          },
        ],
      };
      //使用制定的配置项和数据显示图表
      this.countryDbBean.charts.setOption(optionMap);
      this.countryDbBean.charts1.setOption(optionMap1);
    },
    // 获取地图数据
    getMapData() {
      let data = {};
      this.api.pes.childrenDbMapData(data).then((res) => {
        this.countryDbBean.mapData = res.data;
        for (let i = 0; i < this.countryDbBean.mapData.length; i++) {
          var item = {
            name: this.countryDbBean.mapData[i].province,
            value: i,
            itemStyle: {
              normal: { areaColor: "#B42C3B", label: { show: false } },
              borderColor: "skyblue",
              borderWidth: 1,
            },
          };
          if (
            item.name == "新疆" ||
            item.name == "甘肃" ||
            item.name == "辽宁"
          ) {
            item.itemStyle.normal.areaColor = "#ed5f6a";
          } else if (item.name == "西藏") {
            item.itemStyle.normal.areaColor = "#b73d46";
          } else if (item.name == "青海") {
            item.itemStyle.normal.areaColor = "#ff9aaa";
          } else if (
            item.name == "四川" ||
            item.name == "湖北" ||
            item.name == "北京"
          ) {
            item.itemStyle.normal.areaColor = "#972321";
          } else if (item.name == "云南") {
            item.itemStyle.normal.areaColor = "#963d46";
          } else if (item.name == "内蒙古") {
            item.itemStyle.normal.areaColor = "#bc6171";
          } else if (item.name == "黑龙江") {
            item.itemStyle.normal.areaColor = "#d05e65";
          } else if (item.name == "吉林") {
            item.itemStyle.normal.areaColor = "#c2747a";
          } else if (item.name == "湖南") {
            item.itemStyle.normal.areaColor = "#e57168";
          } else if (item.name == "安徽") {
            item.itemStyle.normal.areaColor = "#921625";
          } else if (item.name == "河北") {
            item.itemStyle.normal.areaColor = "#8a262f";
          } else if (item.name == "山西") {
            item.itemStyle.normal.areaColor = "#c65c62";
          } else if (item.name == "山东") {
            item.itemStyle.normal.areaColor = "#d06c7b";
          } else if (item.name == "江苏") {
            item.itemStyle.normal.areaColor = "#b44953";
          } else {
            item.itemStyle.normal.areaColor = "#d26066";
          }
          this.countryDbBean.mapAreaData.push(item);
          this.countryDbBean.valueList.push({
            id: i,
            num1: this.countryDbBean.mapData[i].small.B_height,
            num2: this.countryDbBean.mapData[i].middle.B_height,
            num3: this.countryDbBean.mapData[i].big.B_height,
          });
        }
        this.$nextTick(function () {
          this.drawMap();
        });
      });
    },
    // 获取增长率数据
    getAvgData() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.pes.childrenDbRateData(data).then((res) => {
        this.countryDbBean.rateData = res.data;
      });
    },

    drawCountryAnalyseLine(id) {
      if (this.countryDbBean.analyseCharts == null) {
        this.countryDbBean.analyseCharts = echarts.init(
          document.getElementById(id)
        );
      }
      var that = this;
      this.countryDbBean.analyseCharts.setOption({
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)", // 提示背景颜色，默认为透明度为0.7的黑色
          borderColor: "#333", // 提示边框颜色
          borderRadius: 4, // 提示边框圆角，单位px，默认为4
          borderWidth: 0, // 提示边框线宽，单位px，默认为0（无边框）
          formatter: function (datas) {
            console.log(datas);
            var res = datas[0].axisValue + "月<br/>";
            for (var i = 0; i < datas.length; i++) {
              var val = datas[i].value;
              res += datas[i].seriesName + "：" + val + "<br/>";
            }
            return "";
          },
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            lineStyle: {
              // 直线指示器样式设置
              color: "#48b",
              width: 2,
              type: "solid",
            },
            shadowStyle: {
              // 阴影指示器样式设置
              width: "auto", // 阴影大小
              color: "rgba(0, 255, 198, 0.2)", // 阴影颜色
            },
          },
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          top: "40px",
          left: "0px",
          right: "0px",
          bottom: "5px",
        },
        legend: {
          orient: "horizontal",
          x: "left", //可设定图例在左、右、居中
          y: "top", //可设定图例在上、下、居中
          padding: [0, 0, 0, 0],
          data: ["全国", "园所"],
        },
        xAxis: {
          type: "category",
          data: this.countryDbBean.times,
          axisTick: {
            show: false, //去除刻度线
          },
          axisLine: {
            show: true, //去除轴线
          },
          axisLabel: {
            color: "#e8e8e8", //x轴文本颜色
            inside: true,
            formatter: function () {
              return "";
            },
          },
          splitLine: {
            // 分隔线
            show: false, // 默认显示，属性show控制显示与否
          },
        },
        yAxis: [
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: false, // 默认显示，属性show控制显示与否
            },
            splitLine: {
              // 分隔线
              show: false, // 默认显示，属性show控制显示与否
            },
            max: 10,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
          },
        ],

        series: [
          {
            name: "全国",
            type: "bar",
            data: this.countryDbBean.qgHeight,
            smooth: true,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: this.countryDbBean.leftColor,
                lineStyle: {
                  width: 6, //设置线条粗细
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: function (data) {
                    console.log(data);
                    var res = that.countryDbBean.qgData[data.dataIndex];
                    if (that.countryDbBean.anaIndex == 1) {
                      res += "cm";
                    } else if (that.countryDbBean.anaIndex == 2) {
                      res += "kg";
                    } else if (that.countryDbBean.anaIndex == 4) {
                      res += "cm";
                    } else if (that.countryDbBean.anaIndex == 5) {
                      res += "ml";
                    } else if (that.countryDbBean.anaIndex == 6) {
                      res += "cm";
                    } else if (that.countryDbBean.anaIndex == 7) {
                      res += "cm";
                    } else if (that.countryDbBean.anaIndex == 8) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 9) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 10) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 11) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 12) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 13) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 14) {
                      res += "个";
                    } else if (that.countryDbBean.anaIndex == 15) {
                      res += "个";
                    }
                    return res;
                  },
                },
              },
            },
          },
          {
            name: "园所",
            type: "bar",
            data: this.countryDbBean.ysHeight,
            smooth: true,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: this.countryDbBean.rightColor,
                lineStyle: {
                  width: 6, //设置线条粗细
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: function (data) {
                    console.log(data);
                    var res = that.countryDbBean.analyseData[data.dataIndex];
                    if (!that.yecfBean.analyseData[data.dataIndex]) {
                      res = "0.0";
                    }
                    if (that.countryDbBean.anaIndex == 1) {
                      res += "cm";
                    } else if (that.countryDbBean.anaIndex == 2) {
                      res += "kg";
                    } else if (that.countryDbBean.anaIndex == 4) {
                      res += "cm";
                    } else if (that.countryDbBean.anaIndex == 5) {
                      res += "ml";
                    } else if (that.countryDbBean.anaIndex == 6) {
                      res += "cm";
                    } else if (that.countryDbBean.anaIndex == 7) {
                      res += "cm";
                    } else if (that.countryDbBean.anaIndex == 8) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 9) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 10) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 11) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 12) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 13) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 14) {
                      res += "个";
                    } else if (that.countryDbBean.anaIndex == 15) {
                      res += "个";
                    }
                    return res;
                  },
                },
              },
            },
          },
        ],
      });
    },
    drawBigCountryAnalyseLine(id) {
      if (this.countryDbBean.bigAnalyseCharts == null) {
        this.countryDbBean.bigAnalyseCharts = echarts.init(
          document.getElementById(id)
        );
      }
      var that = this;
      this.countryDbBean.bigAnalyseCharts.setOption({
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)", // 提示背景颜色，默认为透明度为0.7的黑色
          borderColor: "#333", // 提示边框颜色
          borderRadius: 4, // 提示边框圆角，单位px，默认为4
          borderWidth: 0, // 提示边框线宽，单位px，默认为0（无边框）
          formatter: function (datas) {
            console.log(datas);
            var res = datas[0].axisValue + "月<br/>";
            for (var i = 0; i < datas.length; i++) {
              var val = datas[i].value;
              res += datas[i].seriesName + "：" + val + "<br/>";
            }
            return "";
          },
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            lineStyle: {
              // 直线指示器样式设置
              color: "#48b",
              width: 2,
              type: "solid",
            },
            shadowStyle: {
              // 阴影指示器样式设置
              width: "auto", // 阴影大小
              color: "rgba(0, 255, 198, 0.2)", // 阴影颜色
            },
          },
          textStyle: {
            color: "#fff",
          },
        },
        legend: {
          orient: "vertical",
          x: "left", //可设定图例在左、右、居中
          y: "top", //可设定图例在上、下、居中
          padding: [0, 0, 0, 0],
          data: ["全国", "园所"],
        },
        grid: {
          top: "50px",
          left: "0px",
          right: "0px",
          bottom: "10px",
        },
        xAxis: {
          type: "category",
          data: this.countryDbBean.times,
          axisTick: {
            show: false, //去除刻度线
          },
          axisLine: {
            show: false, //去除轴线
          },
          axisLabel: {
            color: "#e8e8e8", //x轴文本颜色
            inside: true,
            formatter: function () {
              return "";
            },
          },
          splitLine: {
            // 分隔线
            show: false, // 默认显示，属性show控制显示与否
            lineStyle: {
              // 属性lineStyle（详见lineStyle）控制线条样式
              color: "#C2C2C2",
              width: 2,
              type: "solid",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: false, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            max: 10,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
          },
        ],

        series: [
          {
            name: "全国",
            type: "bar",
            data: this.countryDbBean.qgHeight,
            smooth: true,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: this.countryDbBean.leftColor,
                lineStyle: {
                  width: 6, //设置线条粗细
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: function (data) {
                    var res = that.countryDbBean.qgData[data.dataIndex];
                    if (that.countryDbBean.anaIndex == 1) {
                      res += "cm";
                    } else if (that.countryDbBean.anaIndex == 2) {
                      res += "kg";
                    } else if (that.countryDbBean.anaIndex == 4) {
                      res += "cm";
                    } else if (that.countryDbBean.anaIndex == 5) {
                      res += "ml";
                    } else if (that.countryDbBean.anaIndex == 6) {
                      res += "cm";
                    } else if (that.countryDbBean.anaIndex == 7) {
                      res += "cm";
                    } else if (that.countryDbBean.anaIndex == 8) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 9) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 10) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 11) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 12) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 13) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 14) {
                      res += "个";
                    } else if (that.countryDbBean.anaIndex == 15) {
                      res += "个";
                    }
                    return res;
                  },
                },
              },
            },
          },
          {
            name: "园所",
            type: "bar",
            data: this.countryDbBean.ysHeight,
            smooth: true,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: this.countryDbBean.rightColor,
                lineStyle: {
                  width: 6, //设置线条粗细
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: function (data) {
                    console.log(data);
                    var res = that.countryDbBean.analyseData[data.dataIndex];
                    if (!that.yecfBean.analyseData[data.dataIndex]) {
                      res = "0.0";
                    }
                    if (that.countryDbBean.anaIndex == 1) {
                      res += "cm";
                    } else if (that.countryDbBean.anaIndex == 2) {
                      res += "kg";
                    } else if (that.countryDbBean.anaIndex == 4) {
                      res += "cm";
                    } else if (that.countryDbBean.anaIndex == 5) {
                      res += "ml";
                    } else if (that.countryDbBean.anaIndex == 6) {
                      res += "cm";
                    } else if (that.countryDbBean.anaIndex == 7) {
                      res += "cm";
                    } else if (that.countryDbBean.anaIndex == 8) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 9) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 10) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 11) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 12) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 13) {
                      res += "s";
                    } else if (that.countryDbBean.anaIndex == 14) {
                      res += "个";
                    } else if (that.countryDbBean.anaIndex == 15) {
                      res += "个";
                    }
                    return res;
                  },
                },
              },
            },
          },
        ],
      });
    },
    // 获取一键分析
    getAnalyseData1() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.pes.childrenDbAnalyseData(data).then((res) => {
        this.countryDbBean.analyseValue = res.data;
        for (let i = 0; i < this.countryDbBean.analyseValue.length; i++) {
          this.countryDbBean.qgData.push(
            this.countryDbBean.analyseValue[i].B_height[0]
          );
          this.countryDbBean.analyseData.push(
            this.countryDbBean.analyseValue[i].B_height[1]
          );
          this.countryDbBean.qgHeight.push(
            this.countryDbBean.analyseValue[i].B_height_kd[0]
          );
          this.countryDbBean.ysHeight.push(
            this.countryDbBean.analyseValue[i].B_height_kd[1]
          );
        }
        var json = JSON.parse(res.message);
        this.countryDbBean.message = json.message;
        this.countryDbBean.times.push(res.data[0].time);
        this.countryDbBean.times.push(res.data[1].time);
        this.countryDbBean.times.push(res.data[2].time);
        this.countryDbBean.times.push(res.data[3].time);
        this.countryDbBean.times.push(res.data[4].time);
        this.countryDbBean.times.push(res.data[5].time);
        this.$nextTick(function () {
          this.drawCountryAnalyseLine("countryAnalyseChart");
          this.drawBigCountryAnalyseLine("bcountryAnalyseChart");
        });
      });
    },
    // 幼儿运动处方
    drawBar() {
      if (this.yecfBean.charts == null) {
        this.yecfBean.charts = echarts.init(
          document.getElementById("barChart")
        );
      }
      if (this.yecfBean.charts1 == null) {
        this.yecfBean.charts1 = echarts.init(
          document.getElementById("barChart1")
        );
      }
      var option = {
        grid: {
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
        },
        radar: {
          shape: "circle",
          indicator: [
            {
              name: "身高\nCM",
              max: 100,
            },
            { name: "体重\nKG", max: 100 },
            { name: "视力", max: 100 },
            { name: "臂展\nCM", max: 100 },
            { name: "肺活量\nML", max: 100 },
            { name: "胸围\nCM", max: 100 },
            { name: "体前屈\nCM", max: 100 },
            { name: "跑动\nS", max: 100 },
            { name: "跳跃\nS", max: 100 },
            { name: "敏捷\nS", max: 100 },
            { name: "力量\nS", max: 100 },
            { name: "协调\nS", max: 100 },
            { name: "平衡\nS", max: 100 },
            { name: "物控手\n个", max: 100 },
            { name: "物控脚\n个", max: 100 },
          ],
          center: ["50%", "50%"],
          radius: "60%",
          name: {
            color: "#CACACA",
          },
          splitNumber: 2,
          axisLine: {
            lineStyle: {
              color: "#D1D1D1",
            },
          },
          splitLine: {
            lineStyle: {
              color: "#D1D1D1",
            },
          },
          splitArea: {
            areaStyle: {
              color: "#e8e8e8",
            },
          },
        },
        series: [
          {
            name: "全国",
            type: "radar",
            data: [
              {
                value: this.yecfBean.topQgData,
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#D7D7D7",
              },
            },
            label: {
              normal: {
                show: false,
              },
            },
            lineStyle: {
              width: 2,
              color: "#B0B0B0",
              labelLine: {
                show: true, //隐藏标示线
              },
            },
          },
          {
            name: "身高",
            type: "radar",
            data: [
              {
                value: [
                  this.yecfBean.value1,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#00b29d",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#00b29d",
              },
            },
            lineStyle: {
              width: 2,
              color: "#00b29d",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "体重",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  this.yecfBean.value2,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#88391e",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#88391e",
              },
            },
            lineStyle: {
              width: 2,
              color: "#88391e",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "视力",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  this.yecfBean.value3,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#344f00",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#344f00",
              },
            },
            lineStyle: {
              width: 2,
              color: "#344f00",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "臂展",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  this.yecfBean.value4,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#002055",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#002055",
              },
            },
            lineStyle: {
              width: 2,
              color: "#002055",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "肺活量",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  "",
                  this.yecfBean.value5,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#005047",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#005047",
              },
            },
            lineStyle: {
              width: 2,
              color: "#005047",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "胸围",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  "",
                  "",
                  this.yecfBean.value6,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#500009",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#500009",
              },
            },
            lineStyle: {
              width: 2,
              color: "#500009",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "体前屈",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  this.yecfBean.value7,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#41cbb9",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#41cbb9",
              },
            },
            lineStyle: {
              width: 2,
              color: "#41cbb9",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "跑动",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  this.yecfBean.value8,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#a37d8c",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#a37d8c",
              },
            },
            lineStyle: {
              width: 2,
              color: "#a37d8c",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "跳跃",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  this.yecfBean.value9,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#55242a",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#55242a",
              },
            },
            lineStyle: {
              width: 2,
              color: "#55242a",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "敏捷",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  this.yecfBean.value10,
                  "",
                  "",
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#2e7aa2",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#2e7aa2",
              },
            },
            lineStyle: {
              width: 2,
              color: "#2e7aa2",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "力量",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  this.yecfBean.value11,
                  "",
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#263d69",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#263d69",
              },
            },
            lineStyle: {
              width: 2,
              color: "#263d69",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "协调",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  this.yecfBean.value12,
                  "",
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#705d17",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#705d17",
              },
            },
            lineStyle: {
              width: 2,
              color: "#705d17",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "平衡",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  this.yecfBean.value13,
                  "",
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#230db5",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#230db5",
              },
            },
            lineStyle: {
              width: 2,
              color: "#230db5",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "物控手",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  this.yecfBean.value14,
                  "",
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#14c0de",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#14c0de",
              },
            },
            lineStyle: {
              width: 2,
              color: "#14c0de",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
          {
            name: "物控脚",
            type: "radar",
            data: [
              {
                value: [
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  this.yecfBean.value15,
                ],
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#159c56",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#159c56",
              },
            },
            lineStyle: {
              width: 2,
              color: "#159c56",
              labelLine: {
                show: false, //隐藏标示线
              },
            },
          },
        ],
      };
      this.yecfBean.charts.setOption(option);
      this.yecfBean.charts1.setOption(option);
    },
    monthClick(index) {
      this.yecfBean.monthType = index;
      this.yecfBean.topQgData = [];
      this.yecfBean.topQgData.push(
        this.yecfBean.topData[this.yecfBean.monthType - 1].B_judge_height[0]
      );
      this.yecfBean.topQgData.push(
        this.yecfBean.topData[this.yecfBean.monthType - 1].B_judge_weight[0]
      );
      this.yecfBean.topQgData.push(
        this.yecfBean.topData[this.yecfBean.monthType - 1].B_judge_eye[0]
      );
      this.yecfBean.topQgData.push(
        this.yecfBean.topData[this.yecfBean.monthType - 1].B_judge_arm[0]
      );
      this.yecfBean.topQgData.push(
        this.yecfBean.topData[this.yecfBean.monthType - 1].B_judge_lung[0]
      );
      this.yecfBean.topQgData.push(
        this.yecfBean.topData[this.yecfBean.monthType - 1].B_judge_bust[0]
      );
      this.yecfBean.topQgData.push(
        this.yecfBean.topData[this.yecfBean.monthType - 1].B_judge_flexion[0]
      );
      this.yecfBean.topQgData.push(
        this.yecfBean.topData[this.yecfBean.monthType - 1].S_judge_run[0]
      );
      this.yecfBean.topQgData.push(
        this.yecfBean.topData[this.yecfBean.monthType - 1].S_judge_jump[0]
      );
      this.yecfBean.topQgData.push(
        this.yecfBean.topData[this.yecfBean.monthType - 1].S_judge_agile[0]
      );
      this.yecfBean.topQgData.push(
        this.yecfBean.topData[this.yecfBean.monthType - 1].S_judge_power[0]
      );
      this.yecfBean.topQgData.push(
        this.yecfBean.topData[this.yecfBean.monthType - 1].S_judge_coordiate[0]
      );
      this.yecfBean.topQgData.push(
        this.yecfBean.topData[this.yecfBean.monthType - 1].S_judge_balance[0]
      );
      this.yecfBean.topQgData.push(
        this.yecfBean.topData[this.yecfBean.monthType - 1].S_judge_ctrlHand[0]
      );
      this.yecfBean.topQgData.push(
        this.yecfBean.topData[this.yecfBean.monthType - 1].S_judge_ctrlFoot[0]
      );
      this.yecfBean.value1 =
        this.yecfBean.topData[this.yecfBean.monthType - 1].B_judge_height[1];
      this.yecfBean.value2 =
        this.yecfBean.topData[this.yecfBean.monthType - 1].B_judge_weight[1];
      this.yecfBean.value3 =
        this.yecfBean.topData[this.yecfBean.monthType - 1].B_judge_eye[1];
      this.yecfBean.value4 =
        this.yecfBean.topData[this.yecfBean.monthType - 1].B_judge_arm[1];
      this.yecfBean.value5 =
        this.yecfBean.topData[this.yecfBean.monthType - 1].B_judge_lung[1];
      this.yecfBean.value6 =
        this.yecfBean.topData[this.yecfBean.monthType - 1].B_judge_bust[1];
      this.yecfBean.value7 =
        this.yecfBean.topData[this.yecfBean.monthType - 1].B_judge_flexion[1];
      this.yecfBean.value8 =
        this.yecfBean.topData[this.yecfBean.monthType - 1].S_judge_run[1];
      this.yecfBean.value9 =
        this.yecfBean.topData[this.yecfBean.monthType - 1].S_judge_jump[1];
      this.yecfBean.value10 =
        this.yecfBean.topData[this.yecfBean.monthType - 1].S_judge_agile[1];
      this.yecfBean.value11 =
        this.yecfBean.topData[this.yecfBean.monthType - 1].S_judge_power[1];
      this.yecfBean.value12 =
        this.yecfBean.topData[this.yecfBean.monthType - 1].S_judge_coordiate[1];
      this.yecfBean.value13 =
        this.yecfBean.topData[this.yecfBean.monthType - 1].S_judge_balance[1];
      this.yecfBean.value14 =
        this.yecfBean.topData[this.yecfBean.monthType - 1].S_judge_ctrlHand[1];
      this.yecfBean.value15 =
        this.yecfBean.topData[this.yecfBean.monthType - 1].S_judge_ctrlFoot[1];
      this.drawBar();
    },
    drawAnalyseBar() {
      if (this.yecfBean.analyseCharts == null) {
        this.yecfBean.analyseCharts = echarts.init(
          document.getElementById("yecfAnalyseChart")
        );
      }
      var that = this;
      this.yecfBean.analyseCharts.setOption({
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)", // 提示背景颜色，默认为透明度为0.7的黑色
          borderColor: "#333", // 提示边框颜色
          borderRadius: 4, // 提示边框圆角，单位px，默认为4
          borderWidth: 0, // 提示边框线宽，单位px，默认为0（无边框）
          formatter: function (datas) {
            console.log(datas);
            var res = datas[0].axisValue + "月<br/>";
            for (var i = 0; i < datas.length; i++) {
              var val = datas[i].value;
              res += datas[i].seriesName + "：" + val + "<br/>";
            }
            return "";
          },
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            lineStyle: {
              // 直线指示器样式设置
              color: "#48b",
              width: 2,
              type: "solid",
            },
            shadowStyle: {
              // 阴影指示器样式设置
              width: "auto", // 阴影大小
              color: "rgba(0, 255, 198, 0.2)", // 阴影颜色
            },
          },
          textStyle: {
            color: "#fff",
          },
        },
        legend: {
          orient: "horizontal",
          x: "left", //可设定图例在左、右、居中
          y: "top", //可设定图例在上、下、居中
          padding: [0, 10, 0, 0],
          data: ["全国", "园所"],
        },
        grid: {
          top: "40px",
          left: "0px",
          right: "0px",
          bottom: "5px",
        },
        xAxis: {
          type: "category",
          data: this.yecfBean.times,
          axisTick: {
            show: false, //去除刻度线
          },
          axisLine: {
            show: false, //去除轴线
          },
          axisLabel: {
            color: "#e8e8e8", //x轴文本颜色
            inside: true,
            formatter: function () {
              return "";
            },
          },
          splitLine: {
            // 分隔线
            show: false, // 默认显示，属性show控制显示与否
          },
        },
        yAxis: [
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: false, // 默认显示，属性show控制显示与否
            },
            splitLine: {
              // 分隔线
              show: false, // 默认显示，属性show控制显示与否
            },
            max: 10,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
          },
        ],

        series: [
          {
            name: "全国",
            type: "bar",
            data: this.yecfBean.qgHeight,
            smooth: true,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: this.yecfBean.leftColor,
                lineStyle: {
                  width: 6, //设置线条粗细
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: function (data) {
                    return that.yecfBean.qgData[data.dataIndex];
                  },
                },
              },
            },
          },
          {
            name: "园所",
            type: "bar",
            data: this.yecfBean.ysHeight,
            smooth: true,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: this.yecfBean.rightColor,
                lineStyle: {
                  width: 6, //设置线条粗细
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: function (data) {
                    if (!that.yecfBean.analyseData[data.dataIndex]) {
                      return "0.0";
                    }
                    return that.yecfBean.analyseData[data.dataIndex];
                  },
                },
              },
            },
          },
        ],
      });
    },
    drawBigAnalyseBar() {
      if (this.yecfBean.analyseCharts1 == null) {
        this.yecfBean.analyseCharts1 = echarts.init(
          document.getElementById("byecfAnalyseChart")
        );
      }
      var that = this;
      this.yecfBean.analyseCharts1.setOption({
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)", // 提示背景颜色，默认为透明度为0.7的黑色
          borderColor: "#333", // 提示边框颜色
          borderRadius: 4, // 提示边框圆角，单位px，默认为4
          borderWidth: 0, // 提示边框线宽，单位px，默认为0（无边框）
          formatter: function (datas) {
            console.log(datas);
            var res = datas[0].axisValue + "月<br/>";
            for (var i = 0; i < datas.length; i++) {
              var val = datas[i].value;
              res += datas[i].seriesName + "：" + val + "<br/>";
            }
            return "";
          },
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            lineStyle: {
              // 直线指示器样式设置
              color: "#48b",
              width: 2,
              type: "solid",
            },
            shadowStyle: {
              // 阴影指示器样式设置
              width: "auto", // 阴影大小
              color: "rgba(0, 255, 198, 0.2)", // 阴影颜色
            },
          },
          textStyle: {
            color: "#fff",
          },
        },
        legend: {
          orient: "vertical",
          x: "left", //可设定图例在左、右、居中
          y: "top", //可设定图例在上、下、居中
          padding: [0, 0, 0, 0],
          data: ["全国", "园所"],
        },
        grid: {
          top: "50px",
          left: "0px",
          right: "0px",
          bottom: "10px",
        },
        xAxis: {
          type: "category",
          data: this.yecfBean.times,
          axisTick: {
            show: false, //去除刻度线
          },
          axisLine: {
            show: true, //去除轴线
          },
          axisLabel: {
            color: "#e8e8e8", //x轴文本颜色
            inside: true,
            formatter: function () {
              return "";
            },
          },
          splitLine: {
            // 分隔线
            show: false, // 默认显示，属性show控制显示与否
            lineStyle: {
              // 属性lineStyle（详见lineStyle）控制线条样式
              color: "#C2C2C2",
              width: 2,
              type: "solid",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: false, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            max: 10,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
          },
        ],

        series: [
          {
            name: "全国",
            type: "bar",
            data: this.yecfBean.qgHeight,
            smooth: true,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: this.yecfBean.leftColor,
                lineStyle: {
                  width: 6, //设置线条粗细
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: function (data) {
                    return that.yecfBean.qgData[data.dataIndex];
                  },
                },
              },
            },
          },
          {
            name: "园所",
            type: "bar",
            data: this.yecfBean.ysHeight,
            smooth: true,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: this.yecfBean.rightColor,
                lineStyle: {
                  width: 6, //设置线条粗细
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: function (data) {
                    if (!that.yecfBean.analyseData[data.dataIndex]) {
                      return "0.0";
                    }
                    return that.yecfBean.analyseData[data.dataIndex];
                  },
                },
              },
            },
          },
        ],
      });
    },
    clickAnalyseType3(index) {
      this.yecfBean.anaIndex = index;
      this.yecfBean.qgData = [];
      this.yecfBean.analyseData = [];
      this.yecfBean.qgHeight = [];
      this.yecfBean.ysHeight = [];
      if (index == 1) {
        this.yecfBean.leftColor = "#00b29d";
        this.yecfBean.rightColor = "rgba(192, 40, 27, 1)";
        for (let i = 0; i < this.yecfBean.analyseValue.length; i++) {
          if (this.yecfBean.analyseValue[i].B_judge_height) {
            this.yecfBean.qgData.push(
              this.yecfBean.analyseValue[i].B_judge_height[0]
            );
            this.yecfBean.analyseData.push(
              this.yecfBean.analyseValue[i].B_judge_height[1]
            );
            this.yecfBean.qgHeight.push(
              this.yecfBean.analyseValue[i].B_judge_height_kd[0]
            );
            this.yecfBean.ysHeight.push(
              this.yecfBean.analyseValue[i].B_judge_height_kd[1]
            );
          } else {
            this.yecfBean.qgData.push("");
            this.yecfBean.analyseData.push("");
            this.yecfBean.qgHeight.push("");
            this.yecfBean.ysHeight.push("");
          }
        }
      } else if (index == 2) {
        this.yecfBean.leftColor = "#88391e";
        this.yecfBean.rightColor = "rgba(64, 209, 0, 1)";
        for (let i = 0; i < this.yecfBean.analyseValue.length; i++) {
          if (this.yecfBean.analyseValue[i].B_judge_height) {
            this.yecfBean.qgData.push(
              this.yecfBean.analyseValue[i].B_judge_weight[0]
            );
            this.yecfBean.analyseData.push(
              this.yecfBean.analyseValue[i].B_judge_weight[1]
            );
            this.yecfBean.qgHeight.push(
              this.yecfBean.analyseValue[i].B_judge_weight_kd[0]
            );
            this.yecfBean.ysHeight.push(
              this.yecfBean.analyseValue[i].B_judge_weight_kd[1]
            );
          } else {
            this.yecfBean.qgData.push("");
            this.yecfBean.analyseData.push("");
            this.yecfBean.qgHeight.push("");
            this.yecfBean.ysHeight.push("");
          }
        }
      } else if (index == 3) {
        this.yecfBean.leftColor = "#344f00";
        this.yecfBean.rightColor = "rgba(0, 168, 193, 1)";
        for (let i = 0; i < this.yecfBean.analyseValue.length; i++) {
          if (this.yecfBean.analyseValue[i].B_judge_height) {
            this.yecfBean.qgData.push(
              this.yecfBean.analyseValue[i].B_judge_eye[0]
            );
            this.yecfBean.analyseData.push(
              this.yecfBean.analyseValue[i].B_judge_eye[1]
            );
            this.yecfBean.qgHeight.push(
              this.yecfBean.analyseValue[i].B_judge_eye_kd[0]
            );
            this.yecfBean.ysHeight.push(
              this.yecfBean.analyseValue[i].B_judge_eye_kd[1]
            );
          } else {
            this.yecfBean.qgData.push("");
            this.yecfBean.analyseData.push("");
            this.yecfBean.qgHeight.push("");
            this.yecfBean.ysHeight.push("");
          }
        }
      } else if (index == 4) {
        this.yecfBean.leftColor = "#002055";
        this.yecfBean.rightColor = "rgba(224, 200, 0, 1)";
        for (let i = 0; i < this.yecfBean.analyseValue.length; i++) {
          if (this.yecfBean.analyseValue[i].B_judge_height) {
            this.yecfBean.qgData.push(
              this.yecfBean.analyseValue[i].B_judge_arm[0]
            );
            this.yecfBean.analyseData.push(
              this.yecfBean.analyseValue[i].B_judge_arm[1]
            );
            this.yecfBean.qgHeight.push(
              this.yecfBean.analyseValue[i].B_judge_arm_kd[0]
            );
            this.yecfBean.ysHeight.push(
              this.yecfBean.analyseValue[i].B_judge_arm_kd[1]
            );
          } else {
            this.yecfBean.qgData.push("");
            this.yecfBean.analyseData.push("");
            this.yecfBean.qgHeight.push("");
            this.yecfBean.ysHeight.push("");
          }
        }
      } else if (index == 5) {
        this.yecfBean.leftColor = "#005047";
        this.yecfBean.rightColor = "rgba(244, 138, 0, 1)";
        for (let i = 0; i < this.yecfBean.analyseValue.length; i++) {
          if (this.yecfBean.analyseValue[i].B_judge_height) {
            this.yecfBean.qgData.push(
              this.yecfBean.analyseValue[i].B_judge_lung[0]
            );
            this.yecfBean.analyseData.push(
              this.yecfBean.analyseValue[i].B_judge_lung[1]
            );
            this.yecfBean.qgHeight.push(
              this.yecfBean.analyseValue[i].B_judge_lung_kd[0]
            );
            this.yecfBean.ysHeight.push(
              this.yecfBean.analyseValue[i].B_judge_lung_kd[1]
            );
          } else {
            this.yecfBean.qgData.push("");
            this.yecfBean.analyseData.push("");
            this.yecfBean.qgHeight.push("");
            this.yecfBean.ysHeight.push("");
          }
        }
      } else if (index == 6) {
        this.yecfBean.leftColor = "#500009";
        this.yecfBean.rightColor = "rgba(39, 0, 237, 1)";
        for (let i = 0; i < this.yecfBean.analyseValue.length; i++) {
          if (this.yecfBean.analyseValue[i].B_judge_height) {
            this.yecfBean.qgData.push(
              this.yecfBean.analyseValue[i].B_judge_bust[0]
            );
            this.yecfBean.analyseData.push(
              this.yecfBean.analyseValue[i].B_judge_bust[1]
            );
            this.yecfBean.qgHeight.push(
              this.yecfBean.analyseValue[i].B_judge_bust_kd[0]
            );
            this.yecfBean.ysHeight.push(
              this.yecfBean.analyseValue[i].B_judge_bust_kd[1]
            );
          } else {
            this.yecfBean.qgData.push("");
            this.yecfBean.analyseData.push("");
            this.yecfBean.qgHeight.push("");
            this.yecfBean.ysHeight.push("");
          }
        }
      } else if (index == 7) {
        this.yecfBean.leftColor = "#41cbb9";
        this.yecfBean.rightColor = "rgba(214, 0, 253, 1)";
        for (let i = 0; i < this.yecfBean.analyseValue.length; i++) {
          if (this.yecfBean.analyseValue[i].B_judge_height) {
            this.yecfBean.qgData.push(
              this.yecfBean.analyseValue[i].B_judge_flexion[0]
            );
            this.yecfBean.analyseData.push(
              this.yecfBean.analyseValue[i].B_judge_flexion[1]
            );
            this.yecfBean.qgHeight.push(
              this.yecfBean.analyseValue[i].B_judge_flexion_kd[0]
            );
            this.yecfBean.ysHeight.push(
              this.yecfBean.analyseValue[i].B_judge_flexion_kd[1]
            );
          } else {
            this.yecfBean.qgData.push("");
            this.yecfBean.analyseData.push("");
            this.yecfBean.qgHeight.push("");
            this.yecfBean.ysHeight.push("");
          }
        }
      } else if (index == 8) {
        this.yecfBean.leftColor = "#a37d8c";
        this.yecfBean.rightColor = "rgba(151, 32, 79, 1)";
        for (let i = 0; i < this.yecfBean.analyseValue.length; i++) {
          if (this.yecfBean.analyseValue[i].B_judge_height) {
            this.yecfBean.qgData.push(
              this.yecfBean.analyseValue[i].S_judge_run[0]
            );
            this.yecfBean.analyseData.push(
              this.yecfBean.analyseValue[i].S_judge_run[1]
            );
            this.yecfBean.qgHeight.push(
              this.yecfBean.analyseValue[i].S_judge_run_kd[0]
            );
            this.yecfBean.ysHeight.push(
              this.yecfBean.analyseValue[i].S_judge_run_kd[1]
            );
          } else {
            this.yecfBean.qgData.push("");
            this.yecfBean.analyseData.push("");
            this.yecfBean.qgHeight.push("");
            this.yecfBean.ysHeight.push("");
          }
        }
      } else if (index == 9) {
        this.yecfBean.leftColor = "#55242a";
        this.yecfBean.rightColor = "rgba(150, 36, 49, 1)";
        for (let i = 0; i < this.yecfBean.analyseValue.length; i++) {
          if (this.yecfBean.analyseValue[i].B_judge_height) {
            this.yecfBean.qgData.push(
              this.yecfBean.analyseValue[i].S_judge_jump[0]
            );
            this.yecfBean.analyseData.push(
              this.yecfBean.analyseValue[i].S_judge_jump[1]
            );
            this.yecfBean.qgHeight.push(
              this.yecfBean.analyseValue[i].S_judge_jump_kd[0]
            );
            this.yecfBean.ysHeight.push(
              this.yecfBean.analyseValue[i].S_judge_jump_kd[1]
            );
          } else {
            this.yecfBean.qgData.push("");
            this.yecfBean.analyseData.push("");
            this.yecfBean.qgHeight.push("");
            this.yecfBean.ysHeight.push("");
          }
        }
      } else if (index == 10) {
        this.yecfBean.leftColor = "#2e7aa2";
        this.yecfBean.rightColor = "#2ea27a";
        for (let i = 0; i < this.yecfBean.analyseValue.length; i++) {
          if (this.yecfBean.analyseValue[i].B_judge_height) {
            this.yecfBean.qgData.push(
              this.yecfBean.analyseValue[i].S_judge_agile[0]
            );
            this.yecfBean.analyseData.push(
              this.yecfBean.analyseValue[i].S_judge_agile[1]
            );
            this.yecfBean.qgHeight.push(
              this.yecfBean.analyseValue[i].S_judge_agile_kd[0]
            );
            this.yecfBean.ysHeight.push(
              this.yecfBean.analyseValue[i].S_judge_agile_kd[1]
            );
          } else {
            this.yecfBean.qgData.push("");
            this.yecfBean.analyseData.push("");
            this.yecfBean.qgHeight.push("");
            this.yecfBean.ysHeight.push("");
          }
        }
      } else if (index == 11) {
        this.yecfBean.leftColor = "#263d69";
        this.yecfBean.rightColor = "#1f4487";
        for (let i = 0; i < this.yecfBean.analyseValue.length; i++) {
          if (this.yecfBean.analyseValue[i].B_judge_height) {
            this.yecfBean.qgData.push(
              this.yecfBean.analyseValue[i].S_judge_power[0]
            );
            this.yecfBean.analyseData.push(
              this.yecfBean.analyseValue[i].S_judge_power[1]
            );
            this.yecfBean.qgHeight.push(
              this.yecfBean.analyseValue[i].S_judge_power_kd[0]
            );
            this.yecfBean.ysHeight.push(
              this.yecfBean.analyseValue[i].S_judge_power_kd[1]
            );
          } else {
            this.yecfBean.qgData.push("");
            this.yecfBean.analyseData.push("");
            this.yecfBean.qgHeight.push("");
            this.yecfBean.ysHeight.push("");
          }
        }
      } else if (index == 12) {
        this.yecfBean.leftColor = "#705d17";
        this.yecfBean.rightColor = "rgba(182, 150, 35, 1)";
        for (let i = 0; i < this.yecfBean.analyseValue.length; i++) {
          if (this.yecfBean.analyseValue[i].S_judge_coordiate) {
            this.yecfBean.qgData.push(
              this.yecfBean.analyseValue[i].S_judge_coordiate[0]
            );
            this.yecfBean.analyseData.push(
              this.yecfBean.analyseValue[i].S_judge_coordiate[1]
            );
            this.yecfBean.qgHeight.push(
              this.yecfBean.analyseValue[i].S_judge_coordiate_kd[0]
            );
            this.yecfBean.ysHeight.push(
              this.yecfBean.analyseValue[i].S_judge_coordiate_kd[1]
            );
          } else {
            this.yecfBean.qgData.push("");
            this.yecfBean.analyseData.push("");
            this.yecfBean.qgHeight.push("");
            this.yecfBean.ysHeight.push("");
          }
        }
      } else if (index == 13) {
        this.yecfBean.leftColor = "#230db5";
        this.yecfBean.rightColor = "rgb(60, 57, 87)";
        for (let i = 0; i < this.yecfBean.analyseValue.length; i++) {
          if (this.yecfBean.analyseValue[i].S_judge_balance) {
            this.yecfBean.qgData.push(
              this.yecfBean.analyseValue[i].S_judge_balance[0]
            );
            this.yecfBean.analyseData.push(
              this.yecfBean.analyseValue[i].S_judge_balance[1]
            );
            this.yecfBean.qgHeight.push(
              this.yecfBean.analyseValue[i].S_judge_balance_kd[0]
            );
            this.yecfBean.ysHeight.push(
              this.yecfBean.analyseValue[i].S_judge_balance_kd[1]
            );
          } else {
            this.yecfBean.qgData.push("");
            this.yecfBean.analyseData.push("");
            this.yecfBean.qgHeight.push("");
            this.yecfBean.ysHeight.push("");
          }
        }
      } else if (index == 14) {
        this.yecfBean.leftColor = "#14c0de";
        this.yecfBean.rightColor = "rgba(24, 130, 148, 1)";
        for (let i = 0; i < this.yecfBean.analyseValue.length; i++) {
          if (this.yecfBean.analyseValue[i].B_judge_height) {
            this.yecfBean.qgData.push(
              this.yecfBean.analyseValue[i].S_judge_ctrlHand[0]
            );
            this.yecfBean.analyseData.push(
              this.yecfBean.analyseValue[i].S_judge_ctrlHand[1]
            );
            this.yecfBean.qgHeight.push(
              this.yecfBean.analyseValue[i].S_judge_ctrlHand_kd[0]
            );
            this.yecfBean.ysHeight.push(
              this.yecfBean.analyseValue[i].S_judge_ctrlHand_kd[1]
            );
          } else {
            this.yecfBean.qgData.push("");
            this.yecfBean.analyseData.push("");
            this.yecfBean.qgHeight.push("");
            this.yecfBean.ysHeight.push("");
          }
        }
      } else if (index == 15) {
        this.yecfBean.leftColor = "#159c56";
        this.yecfBean.rightColor = "rgba(15, 193, 100, 1)";
        for (let i = 0; i < this.yecfBean.analyseValue.length; i++) {
          if (this.yecfBean.analyseValue[i].B_judge_height) {
            this.yecfBean.qgData.push(
              this.yecfBean.analyseValue[i].S_judge_ctrlFoot[0]
            );
            this.yecfBean.analyseData.push(
              this.yecfBean.analyseValue[i].S_judge_ctrlFoot[1]
            );
            this.yecfBean.qgHeight.push(
              this.yecfBean.analyseValue[i].S_judge_ctrlFoot_kd[0]
            );
            this.yecfBean.ysHeight.push(
              this.yecfBean.analyseValue[i].S_judge_ctrlFoot_kd[1]
            );
          } else {
            this.yecfBean.qgData.push("");
            this.yecfBean.analyseData.push("");
            this.yecfBean.qgHeight.push("");
            this.yecfBean.ysHeight.push("");
          }
        }
      }
      this.drawAnalyseBar();
      this.drawBigAnalyseBar();
    },
    monthAnalyseClick(index) {
      this.yecfBean.analyseMonthType = index;

      this.drawAnalyseBar();
      this.drawBigAnalyseBar();
    },
    getTopData() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.pes.sportMethodRadarData(data).then((res) => {
        this.yecfBean.topData = res.data;
        this.yecfBean.topQgData.push(
          this.yecfBean.topData[0].B_judge_height[0]
        );
        this.yecfBean.topQgData.push(
          this.yecfBean.topData[0].B_judge_weight[0]
        );
        this.yecfBean.topQgData.push(this.yecfBean.topData[0].B_judge_eye[0]);
        this.yecfBean.topQgData.push(this.yecfBean.topData[0].B_judge_arm[0]);
        this.yecfBean.topQgData.push(this.yecfBean.topData[0].B_judge_lung[0]);
        this.yecfBean.topQgData.push(this.yecfBean.topData[0].B_judge_bust[0]);
        this.yecfBean.topQgData.push(
          this.yecfBean.topData[0].B_judge_flexion[0]
        );
        this.yecfBean.topQgData.push(this.yecfBean.topData[0].S_judge_run[0]);
        this.yecfBean.topQgData.push(this.yecfBean.topData[0].S_judge_jump[0]);
        this.yecfBean.topQgData.push(this.yecfBean.topData[0].S_judge_agile[0]);
        this.yecfBean.topQgData.push(this.yecfBean.topData[0].S_judge_power[0]);
        this.yecfBean.topQgData.push(
          this.yecfBean.topData[0].S_judge_coordiate[0]
        );
        this.yecfBean.topQgData.push(
          this.yecfBean.topData[0].S_judge_balance[0]
        );
        this.yecfBean.topQgData.push(
          this.yecfBean.topData[0].S_judge_ctrlHand[0]
        );
        this.yecfBean.topQgData.push(
          this.yecfBean.topData[0].S_judge_ctrlFoot[0]
        );
        this.yecfBean.value1 = this.yecfBean.topData[0].B_judge_height[1];
        this.yecfBean.value2 = this.yecfBean.topData[0].B_judge_weight[1];
        this.yecfBean.value3 = this.yecfBean.topData[0].B_judge_eye[1];
        this.yecfBean.value4 = this.yecfBean.topData[0].B_judge_arm[1];
        this.yecfBean.value5 = this.yecfBean.topData[0].B_judge_lung[1];
        this.yecfBean.value6 = this.yecfBean.topData[0].B_judge_bust[1];
        this.yecfBean.value7 = this.yecfBean.topData[0].B_judge_flexion[1];
        this.yecfBean.value8 = this.yecfBean.topData[0].S_judge_run[1];
        this.yecfBean.value9 = this.yecfBean.topData[0].S_judge_jump[1];
        this.yecfBean.value10 = this.yecfBean.topData[0].S_judge_agile[1];
        this.yecfBean.value11 = this.yecfBean.topData[0].S_judge_power[1];
        this.yecfBean.value12 = this.yecfBean.topData[0].S_judge_coordiate[1];
        this.yecfBean.value13 = this.yecfBean.topData[0].S_judge_balance[1];
        this.yecfBean.value14 = this.yecfBean.topData[0].S_judge_ctrlHand[1];
        this.yecfBean.value15 = this.yecfBean.topData[0].S_judge_ctrlFoot[1];
        for (let i = 0; i < this.yecfBean.topData.length; i++) {
          this.yecfBean.monthList.push(this.yecfBean.topData[i].time);
        }
        this.$nextTick(function () {
          this.drawBar();
        });
      });
    },
    // 获取评分数据
    getAvgData2() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.pes.sportMethodScoreData(data).then((res) => {
        this.yecfBean.rateData = res.data;
      });
    },
    // 获取分析数据
    getAnalyseData2() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.pes.sportMethodAnalyseData(data).then((res) => {
        this.yecfBean.analyseValue = res.data;
        var result = JSON.parse(res.message);
        this.ysName = result.message;
        this.message = result.analy;
        for (let i = 0; i < this.yecfBean.analyseValue.length; i++) {
          if (this.yecfBean.analyseValue[i].B_judge_height) {
            this.yecfBean.qgData.push(
              this.yecfBean.analyseValue[i].B_judge_height[0]
            );
            this.yecfBean.analyseData.push(
              this.yecfBean.analyseValue[i].B_judge_height[1]
            );
            this.yecfBean.qgHeight.push(
              this.yecfBean.analyseValue[i].B_judge_height_kd[0]
            );
            this.yecfBean.ysHeight.push(
              this.yecfBean.analyseValue[i].B_judge_height_kd[1]
            );
          } else {
            this.yecfBean.qgData.push("");
            this.yecfBean.analyseData.push("");
            this.yecfBean.qgHeight.push("");
            this.yecfBean.ysHeight.push("");
          }
          this.yecfBean.times.push(this.yecfBean.analyseValue[i].time);
        }
        this.$nextTick(function () {
          this.drawAnalyseBar();
          this.drawBigAnalyseBar();
        });
      });
    },
    shotPic() {
      if (
        this.bigChoice1 ||
        this.bigChoice2 ||
        this.bigChoice3 ||
        this.bigChoice4 ||
        this.bigChoice5 ||
        this.bigChoice6 ||
        this.bigChoice7 ||
        this.bigChoice8 ||
        this.bigChoice9 ||
        this.bigChoice10 ||
        this.bigChoice11 ||
        this.bigChoice12
      ) {
        const node = document.getElementById("downLoad"); // 通过id获取dom
        this.loading = true;
        var that = this;
        domtoimage
          .toJpeg(node, { quality: 0.9 })
          .then((dataUrl) => {
            that.loading = false;
            //输出图片的Base64,dataUrl
            // 下载到PC
            const a = document.createElement("a"); // 生成一个a元素
            const event = new MouseEvent("click"); // 创建一个单击事件
            a.download = "园所综合报告"; // 设置图片名称没有设置则为默认
            a.href = dataUrl; // 将生成的URL设置为a.href属性
            a.dispatchEvent(event); // 触发a的单击事件
          })
          .catch(function (error) {
            that.loading = false;
            console.error("oops, something went wrong!", error);
          });
      } else {
        this.$message("请选择要下载的内容");
      }
    },
  },
  mounted() {
    this.getSchoolGradeList();
    this.getTopLineData();
    this.getRateData();
    this.getAnalyseData();
    this.drawMap();
    this.getMapData();
    this.getAvgData();
    this.getAnalyseData1();
    this.getTopData();
    this.getAvgData2();
    this.getAnalyseData2();
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #e7e7e7;
  align-items: center;
  justify-content: center;
}
.row {
  display: flex;
  flex-direction: row;
}
.center {
  align-items: center;
  justify-content: center;
}
.row_center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flex {
  flex: 1;
}
.title {
  font-size: 50px;
  font-family: Microsoft YaHei;
  font-weight: 600;
  color: #000000;
}
.sub {
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin-top: 22px;
}
.width601 {
  width: 601px;
  background: #f1f1f1;
}
.mt23 {
  margin-top: 23px;
}
.grwoth_small {
  .topLine {
    width: 575px;
    height: 360px;
  }
  .top_hint {
    display: flex;
    flex-direction: row;
    width: 575px;
    height: 33px;
    .line {
      width: 2px;
      height: 33px;
      background: #c2c2c2;
    }
    .mrl1 {
      margin-left: 1px;
    }
    .mrl2 {
      margin-left: 2px;
    }
    .mrl3 {
      margin-left: 3px;
    }

    .text {
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #00157f;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
  }
  .red_text {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ce2323;
    margin-top: 10px;
  }
  .mrl10 {
    margin-left: 10px;
  }
  .tz_select {
    width: 67px;
    height: 24px;
    background: #001689;
    border: 3px solid #07155e;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .tz_normal {
    width: 67px;
    height: 24px;
    background: #e8e8e8;
    border: 3px solid #d8d8d8;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000e56;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .yd_select {
    width: 67px;
    height: 24px;
    background: #001689;
    border: 3px solid #07155e;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .yd_normal {
    width: 67px;
    height: 24px;
    background: #e8e8e8;
    border: 3px solid #d8d8d8;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000e56;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .ml8 {
    margin-left: 8px;
  }
  .ml32 {
    margin-left: 20px;
  }
  .ml30 {
    margin-left: 30px;
  }
  .label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .color {
      width: 16px;
      height: 16px;
      background: #000e56;
      border-radius: 50%;
    }
    .hint {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000e56;
      margin-top: 7px;
    }
    .text_normal {
      font-size: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000e56;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .text_select {
      font-size: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .width16 {
      width: 46px;
      height: 16px;
      border-radius: 18px;
    }
    .amt10 {
      margin-top: 10px;
    }
    .color1 {
      background: #c0281b;
    }
    .color2 {
      background: #40d100;
    }
    .color3 {
      background: #00a8c1;
    }
    .color4 {
      background: #e0c800;
    }
    .color5 {
      background: #f48a00;
    }
    .color6 {
      background: #2700ed;
    }
    .color7 {
      background: #d600fd;
    }
    .color_white {
      background: #f1f1f1;
    }
    .text {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000e56;
      margin-top: 4px;
    }
    .coloryd1 {
      background: rgba(151, 32, 79, 1);
    }
    .coloryd2 {
      background: rgba(150, 36, 49, 1);
    }
    .coloryd3 {
      background: rgba(46, 162, 122, 1);
    }
    .coloryd4 {
      background: rgba(31, 68, 135, 1);
    }
    .coloryd5 {
      background: rgba(182, 150, 35, 1);
    }
    .coloryd6 {
      background: rgb(60, 57, 87);
    }
    .coloryd7 {
      background: rgba(24, 130, 148, 1);
    }
    .coloryd8 {
      background: rgba(15, 193, 100, 1);
    }
  }
  .ml55 {
    margin-left: 55px;
  }
  .width514 {
    width: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .top_lay {
      position: relative;
      width: 100%;
      height: 46px;
    }
    .top_bg {
      width: 264px;
      height: 30px;
      background: #e7e7e7;
      border: 8px solid #ffffff;
      border-radius: 39px;
    }
    .left_text {
      width: 80px;
      height: 30px;
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .middle {
      width: 100%;
      height: 100%;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .pos_abs {
      position: absolute;
      left: 0;
      top: 0;
    }
    .marl8 {
      margin-left: 8px;
    }
    .hint1 {
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-top: 25px;
    }
    .left_lay {
      width: 170px;
      height: 49px;
      background: #0abda3;
      border: 6px solid #ffffff;
      border-radius: 31px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .text {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
      .text1 {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin-left: 4px;
      }
      .text2 {
        width: 5px;
        height: 5px;
        background: #ffffff;
        border-radius: 50%;
        margin-left: 15px;
        margin-top: 30px;
      }
    }
    .num_lay {
      width: 280px;
      height: 30px;
      background: #f2f2f2;
      border-radius: 31px;
      display: flex;
      flex-direction: row;
    }
    .mt16 {
      margin-top: 16px;
    }
    .mt36 {
      margin-top: 36px;
    }
    .column_num {
      display: flex;
      flex-direction: column;
      margin-left: 6px;
      margin-right: 6px;
      .text {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #888888;
        margin-top: 10px;
      }
      .line {
        width: 130px;
        height: 2px;
        background: #b9b9b9;
      }
      .value {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #0abda3;
        margin-top: 2px;
      }
      .vs {
        width: 39px;
        height: 25px;
        margin-top: 6px;
      }
    }
  }
  .analyse_chart {
    width: 601px;
    height: 300px;
    margin-top: 23px;
  }
  .analyse_tab {
    width: 70px;
    height: 23px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .text1 {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #cbcbcb;
    }
    .text2 {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #686868;
    }
  }
  .width1400 {
    width: 601px;
  }
  .tz_select1 {
    width: 67px;
    height: 24px;
    background: #000f60;
    border: 6px solid #0001c1;
    border-top-left-radius: 29px;
    border-bottom-left-radius: 29px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .tz_normal1 {
    width: 67px;
    height: 24px;
    background: #e8e8e8;
    border: 6px solid #c4c4c4;
    border-top-left-radius: 29px;
    border-bottom-left-radius: 29px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cbcbcb;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .yd_select1 {
    width: 67px;
    height: 24px;
    background: #000f60;
    border: 6px solid #0001c1;
    border-top-right-radius: 29px;
    border-bottom-right-radius: 29px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .yd_normal1 {
    width: 67px;
    height: 24px;
    background: #e8e8e8;
    border: 6px solid #c4c4c4;
    border-top-right-radius: 29px;
    border-bottom-right-radius: 29px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cbcbcb;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
.baseLine {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.mt84 {
  margin-top: 84px;
}
.mt41 {
  margin-top: 41px;
}
.width_max {
  width: 100%;
}
.column_base {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chart_title {
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #001166;
  margin-left: 33px;
}
.normal_img {
  width: 42px;
  height: 42px;
  margin-right: 30px;
  max-height: 42px;
  min-width: 42px;
  cursor: pointer;
}
.line {
  width: 100%;
  height: 2px;
  background: #e8e8e8;
}
.mt20 {
  margin-top: 20px;
}
.mt24 {
  margin-top: 24px;
}
.month_select1 {
  width: 62px;
  height: 24px;
  background: #ffffff;
  border: 3px solid #feba01;
  border-radius: 15px;
  cursor: pointer;
}
.month_normal1 {
  width: 62px;
  height: 24px;
  background: #f1f1f1;
  border: 3px solid #f1f1f1;
  border-radius: 15px;
  cursor: pointer;
}
.month_select2 {
  width: 62px;
  height: 24px;
  background: #ffffff;
  border: 3px solid #6c0000;
  border-radius: 27px;
  cursor: pointer;
}
.month_select3 {
  width: 62px;
  height: 24px;
  background: #ffffff;
  border: 3px solid #169f00;
  border-radius: 27px;
  cursor: pointer;
}
.month_select4 {
  width: 62px;
  height: 24px;
  background: #ffffff;
  border: 3px solid #bd0097;
  border-radius: 27px;
  cursor: pointer;
}
.month_select5 {
  width: 62px;
  height: 24px;
  background: #ffffff;
  border: 3px solid #fe0101;
  border-radius: 27px;
  cursor: pointer;
}
.month_select6 {
  width: 62px;
  height: 24px;
  background: #ffffff;
  border: 3px solid #e9fe01;
  border-radius: 27px;
  cursor: pointer;
}
.mt50 {
  margin-top: 50px;
}
.circle1 {
  width: 17px;
  height: 17px;
  background: #feba01;
  border-radius: 50%;
}
.circle2 {
  width: 17px;
  height: 17px;
  background: #6c0000;
  border-radius: 50%;
}
.circle3 {
  width: 17px;
  height: 17px;
  background: #169f00;
  border-radius: 50%;
}
.circle4 {
  width: 17px;
  height: 17px;
  background: #bd0097;
  border-radius: 50%;
}
.circle5 {
  width: 17px;
  height: 17px;
  background: #fe0101;
  border-radius: 50%;
}
.circle6 {
  width: 17px;
  height: 17px;
  background: #e9fe01;
  border-radius: 50%;
}
.top_text {
  font-size: 10px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #001166;
  margin-left: 4px;
}

.image_c {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;
  margin-left: 20px;
  margin-top: 22px;
  .title1 {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #001166;
  }
}
.mt60 {
  margin-top: 60px;
}
.image_l {
  position: relative;
  width: 480px;
  height: 410px;

  .image {
    width: 480px;
    height: 410px;
  }
  .num1 {
    width: 44px;
    position: absolute;
    top: 23px;
    right: 189px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    text-align: center;
  }
  .num2 {
    width: 44px;
    position: absolute;
    bottom: 57px;
    left: 125px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    text-align: center;
  }
  .num3 {
    width: 44px;
    position: absolute;
    bottom: 89px;
    right: 116px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    text-align: center;
    color: #1c1642;
  }
  .num4 {
    width: 44px;
    position: absolute;
    top: 62px;
    right: 190px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    text-align: center;
  }
  .num5 {
    width: 44px;
    position: absolute;
    bottom: 47px;
    left: 102px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    text-align: center;
  }
  .num6 {
    width: 44px;
    position: absolute;
    bottom: 72px;
    right: 89px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    text-align: center;
  }
  .num7 {
    width: 44px;
    position: absolute;
    top: 81px;
    right: 190px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    text-align: center;
  }
  .num8 {
    width: 44px;
    position: absolute;
    bottom: 38px;
    left: 90px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    text-align: center;
  }
  .num9 {
    width: 44px;
    position: absolute;
    bottom: 84px;
    right: 115px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    text-align: center;
  }
  .num10 {
    width: 44px;
    position: absolute;
    top: 42px;
    right: 190px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    text-align: center;
  }
  .num11 {
    width: 44px;
    position: absolute;
    bottom: 26px;
    left: 72px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    text-align: center;
  }
  .num12 {
    width: 44px;
    position: absolute;
    bottom: 65px;
    right: 79px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    text-align: center;
  }
  .num13 {
    width: 44px;
    position: absolute;
    top: 26px;
    right: 190px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    text-align: center;
  }
  .num14 {
    width: 44px;
    position: absolute;
    bottom: 69px;
    left: 145px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    text-align: center;
  }
  .num15 {
    width: 44px;
    position: absolute;
    bottom: 113px;
    right: 163px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    text-align: center;
  }
  .num16 {
    width: 44px;
    position: absolute;
    top: 116px;
    right: 190px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    text-align: center;
  }
  .num17 {
    width: 44px;
    position: absolute;
    bottom: 65px;
    left: 139px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    text-align: center;
  }
  .num18 {
    width: 44px;
    position: absolute;
    bottom: 103px;
    right: 142px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    text-align: center;
  }
}
.h500 {
  height: 500px;
}
.mrl5 {
  margin-left: 5px;
}
.white_bg {
  width: 100%;
  height: 102px;
  background: #ffffff;
  margin-top: 102px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .create {
    width: 322px;
    height: 66px;
    background: #00af0c;
    border: 6px solid #00e210;
    border-radius: 39px;
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #010000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .downLoad {
    width: 166px;
    height: 44px;
    background: #ffffff;
    border: 6px solid #00bdb6;
    font-size: 40px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #00bdb6;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 730px;
  }
}
/deep/.el-input__inner {
  border: none;
  background-color: #f1f1f1;
  width: 100px;
  height: 38px;
}

.big {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  .image_c {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 760px;
    margin-left: -56px;
    .title1 {
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #001166;
    }
  }
  .image_l {
    position: relative;
    width: 760px;
    height: 640px;
    .image {
      width: 760px;
      height: 640px;
    }
    .num1 {
      position: absolute;
      top: 38px;
      right: 304px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1c1642;
      width: 65px;
      text-align: center;
    }
    .num2 {
      position: absolute;
      bottom: 88px;
      left: 199px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1c1642;
      width: 65px;
      text-align: center;
    }
    .num3 {
      position: absolute;
      bottom: 139px;
      right: 188px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1c1642;
      width: 65px;
      text-align: center;
    }
    .num4 {
      position: absolute;
      top: 96px;
      right: 305px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1c1642;
      width: 65px;
      text-align: center;
    }
    .num5 {
      position: absolute;
      bottom: 72px;
      left: 162px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1c1642;
      width: 65px;
      text-align: center;
    }
    .num6 {
      position: absolute;
      bottom: 110px;
      right: 143px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1c1642;
      width: 65px;
      text-align: center;
    }
    .num7 {
      position: absolute;
      top: 127px;
      right: 305px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1c1642;
      width: 65px;
      text-align: center;
    }
    .num8 {
      position: absolute;
      bottom: 58px;
      left: 140px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1c1642;
      width: 65px;
      text-align: center;
    }
    .num9 {
      position: absolute;
      bottom: 131px;
      right: 185px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1c1642;
      width: 65px;
      text-align: center;
    }
    .num10 {
      position: absolute;
      top: 66px;
      right: 305px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1c1642;
      width: 65px;
      text-align: center;
    }
    .num11 {
      position: absolute;
      bottom: 40px;
      left: 114px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1c1642;
      width: 65px;
      text-align: center;
    }
    .num12 {
      position: absolute;
      bottom: 101px;
      right: 130px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1c1642;
      width: 65px;
      text-align: center;
    }
    .num13 {
      position: absolute;
      top: 41px;
      right: 305px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1c1642;
      width: 65px;
      text-align: center;
    }
    .num14 {
      position: absolute;
      bottom: 107px;
      left: 232px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1c1642;
      width: 65px;
      text-align: center;
    }
    .num15 {
      position: absolute;
      bottom: 176px;
      right: 264px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1c1642;
      width: 65px;
      text-align: center;
    }
    .num16 {
      position: absolute;
      top: 180px;
      right: 305px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1c1642;
      width: 65px;
      text-align: center;
    }
    .num17 {
      position: absolute;
      bottom: 102px;
      left: 219px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1c1642;
      width: 65px;
      text-align: center;
    }
    .num18 {
      position: absolute;
      bottom: 158px;
      right: 230px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1c1642;
      width: 65px;
      text-align: center;
    }
  }
  .mt125 {
    margin-top: 125px;
  }
  .month_select1 {
    width: 124px;
    height: 48px;
    background: #ffffff;
    border: 3px solid #feba01;
    border-radius: 27px;
    cursor: pointer;
  }
  .month_normal1 {
    width: 124px;
    height: 48px;
    background: #e8e8e8;
    border: 3px solid #e8e8e8;
    border-radius: 27px;
    cursor: pointer;
  }
  .month_select2 {
    width: 124px;
    height: 48px;
    background: #ffffff;
    border: 3px solid #6c0000;
    border-radius: 27px;
    cursor: pointer;
  }
  .month_select3 {
    width: 124px;
    height: 48px;
    background: #ffffff;
    border: 3px solid #169f00;
    border-radius: 27px;
    cursor: pointer;
  }
  .month_select4 {
    width: 124px;
    height: 48px;
    background: #ffffff;
    border: 3px solid #bd0097;
    border-radius: 27px;
    cursor: pointer;
  }
  .month_select5 {
    width: 124px;
    height: 48px;
    background: #ffffff;
    border: 3px solid #fe0101;
    border-radius: 27px;
    cursor: pointer;
  }
  .month_select6 {
    width: 124px;
    height: 48px;
    background: #ffffff;
    border: 3px solid #e9fe01;
    border-radius: 27px;
    cursor: pointer;
  }
  .mt25 {
    margin-top: 25px;
  }
  .circle1 {
    width: 35px;
    height: 35px;
    background: #feba01;
    border-radius: 50%;
  }
  .circle2 {
    width: 35px;
    height: 35px;
    background: #6c0000;
    border-radius: 50%;
  }
  .circle3 {
    width: 35px;
    height: 35px;
    background: #169f00;
    border-radius: 50%;
  }
  .circle4 {
    width: 35px;
    height: 35px;
    background: #bd0097;
    border-radius: 50%;
  }
  .circle5 {
    width: 35px;
    height: 35px;
    background: #fe0101;
    border-radius: 50%;
  }
  .circle6 {
    width: 35px;
    height: 35px;
    background: #e9fe01;
    border-radius: 50%;
  }
  .small_circle {
    width: 22px;
    height: 22px;
  }
  .width624 {
    width: 624px;
    max-width: 624px;
  }
  .top_text {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #001166;
    margin-left: 9px;
  }
  .form-control {
    height: 50px;
    border: solid 1px #d9d9d9;
    box-sizing: border-box;
    padding: 0rem 0.25rem;
    border-radius: 0.25rem;
  }
  /deep/.el-input__inner {
    border: none;
    background-color: #e8e8e8;
    width: 100px;
  }
}
.mt190 {
  margin-top: 100px;
}
.mt100 {
  margin-top: 100px;
}
.mrl40 {
  margin-left: 40px;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-control {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  border: solid 1px #d9d9d9;
  box-sizing: border-box;
  border-radius: 0.25rem;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
}
.el-input--suffix .el-input__inner {
  padding-left: 0 !important;
  font-size: 0.18rem;
  font-family: Alibaba PuHuiTi !important;
  color: #000;
}

.el-cascader .el-input .el-input__inner:focus,
.el-cascader .el-input.is-focus .el-input__inner {
  border: none;
  padding-left: 0 !important;
  font-family: Alibaba PuHuiTi !important;
  color: #000;
}
.mt30 {
  margin-top: 30px;
}
.growth {
  .line_b {
    position: relative;
    width: 1700px;
    height: 551px;
    margin-top: 54px;
  }
  .topLine1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 1700px;
    height: 551px;
  }
  .top_hint {
    display: flex;
    flex-direction: row;
    width: 1700px;
    height: 53px;
    .line {
      width: 2px;
      height: 53px;
      background: #c2c2c2;
    }
    .mrl1 {
      margin-left: 1px;
    }
    .mrl2 {
      margin-left: 2px;
    }
    .mrl3 {
      margin-left: 3px;
    }

    .text {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #00157f;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
  }
  .red_text {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ce2323;
    margin-top: 20px;
  }
  .mt26 {
    margin-top: 26px;
  }
  .ml20 {
    margin-left: 12px;
  }
  .tz_select {
    width: 67px;
    height: 24px;
    background: #001689;
    border: 3px solid #07155e;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .tz_normal {
    width: 67px;
    height: 24px;
    background: #e8e8e8;
    border: 3px solid #d8d8d8;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000e56;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .yd_select {
    width: 67px;
    height: 24px;
    background: #001689;
    border: 3px solid #07155e;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .yd_normal {
    width: 67px;
    height: 24px;
    background: #e8e8e8;
    border: 3px solid #d8d8d8;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000e56;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .ml8 {
    margin-left: 8px;
  }
  .ml32 {
    margin-left: 12px;
  }
  .ml30 {
    margin-left: 30px;
  }
  .label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .color {
      width: 22px;
      height: 22px;
      background: #000e56;
      border-radius: 50%;
    }
    .hint {
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000e56;
      margin-top: 7px;
    }
    .text_normal {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000e56;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .text_select {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .width16 {
      width: 72px;
      height: 32px;
      border-radius: 18px;
    }
    .color1 {
      background: #c0281b;
    }
    .color2 {
      background: #40d100;
    }
    .color3 {
      background: #00a8c1;
    }
    .color4 {
      background: #e0c800;
    }
    .color5 {
      background: #f48a00;
    }
    .color6 {
      background: #2700ed;
    }
    .color7 {
      background: #d600fd;
    }
    .text {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000e56;
      margin-top: 4px;
    }
    .coloryd1 {
      background: rgba(151, 32, 79, 1);
    }
    .coloryd2 {
      background: rgba(150, 36, 49, 1);
    }
    .coloryd3 {
      background: rgba(46, 162, 122, 1);
    }
    .coloryd4 {
      background: rgba(31, 68, 135, 1);
    }
    .coloryd5 {
      background: rgba(182, 150, 35, 1);
    }
    .coloryd6 {
      background: rgb(60, 57, 87);
    }
    .coloryd7 {
      background: rgba(24, 130, 148, 1);
    }
    .coloryd8 {
      background: rgba(15, 193, 100, 1);
    }
    .color_white {
      background: #e7e7e7;
    }
  }
  .ml55 {
    margin-left: 55px;
  }
  .width514 {
    width: 514px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .top_lay {
      position: relative;
      width: 100%;
      height: 78px;
    }
    .top_bg {
      width: 498px;
      height: 62px;
      background: #e7e7e7;
      border: 8px solid #ffffff;
      border-radius: 39px;
    }
    .left_text {
      width: 120px;
      height: 62px;
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .middle {
      width: 100%;
      height: 100%;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .pos_abs {
      position: absolute;
      left: 0;
      top: 0;
    }
    .marl8 {
      margin-left: 8px;
    }
    .hint1 {
      font-size: 21px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-top: 25px;
    }
    .left_lay {
      width: 170px;
      height: 49px;
      background: #0abda3;
      border: 6px solid #ffffff;
      border-radius: 31px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .text {
        font-size: 38px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
      .text1 {
        font-size: 38px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin-left: 4px;
      }
      .text2 {
        width: 5px;
        height: 5px;
        background: #ffffff;
        border-radius: 50%;
        margin-left: 15px;
        margin-top: 30px;
      }
    }
    .num_lay {
      width: 514px;
      height: 61px;
      background: #f2f2f2;
      border-radius: 31px;
      display: flex;
      flex-direction: row;
    }
    .mt16 {
      margin-top: 16px;
    }
    .mt36 {
      margin-top: 36px;
    }
  }
  .column_num {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
    margin-right: 6px;
    .text {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #888888;
      margin-top: 10px;
    }
    .line {
      width: 130px;
      height: 2px;
      background: #b9b9b9;
    }
    .value {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #0abda3;
      margin-top: 2px;
    }
    .vs {
      width: 39px;
      height: 25px;
      margin-top: 6px;
    }
  }
  .line_c {
    width: 1400px;
    height: 561px;
    margin-top: 63px;
    position: relative;
  }
  .analyse_chart {
    position: absolute;
    left: 0;
    top: 0;
    width: 1400px;
    height: 561px;
  }
  .analyse_tab {
    width: 130px;
    height: 33px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .text1 {
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #cbcbcb;
    }
    .text2 {
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #686868;
    }
  }
  .width1400 {
    width: 1400px;
  }
  .tz_select1 {
    width: 100px;
    height: 46px;
    background: #000f60;
    border: 6px solid #0001c1;
    border-top-left-radius: 29px;
    border-bottom-left-radius: 29px;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .tz_normal1 {
    width: 100px;
    height: 46px;
    background: #e8e8e8;
    border: 6px solid #c4c4c4;
    border-top-left-radius: 29px;
    border-bottom-left-radius: 29px;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cbcbcb;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .yd_select1 {
    width: 100px;
    height: 46px;
    background: #000f60;
    border: 6px solid #0001c1;
    border-top-right-radius: 29px;
    border-bottom-right-radius: 29px;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .yd_normal1 {
    width: 100px;
    height: 46px;
    background: #e8e8e8;
    border: 6px solid #c4c4c4;
    border-top-right-radius: 29px;
    border-bottom-right-radius: 29px;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cbcbcb;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
.country_db_small {
  .mrl10 {
    margin-left: 10px;
  }
  .analyse_chart {
    width: 601px;
    height: 300px;
    margin-top: 23px;
  }
  .analyse_tab {
    width: 70px;
    height: 23px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .text1 {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #cbcbcb;
    }
    .text2 {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #686868;
    }
  }
  .red_text {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ce2323;
    margin-top: 10px;
  }
  .label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .text_normal1 {
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000e56;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text_select1 {
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .width16 {
    width: 62px;
    height: 30px;
    border-radius: 18px;
  }
  .color_white {
    background: #f1f1f1;
  }
  .width1400 {
    width: 601px;
  }
  .topLine {
    width: 601px;
    height: 500px;
    overflow-x: hidden;
  }
  .pos_rel {
    position: relative;
    width: 601px;
    .label_row {
      position: absolute;
      right: 0;
      top: 20px;
      display: flex;
      flex-direction: row;
      align-items: baseline;
    }
    .column1 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background: #f1f1f1;
    }
    .row_center {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }
    .all_text {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #606060;
      margin-left: 5px;
    }
    .mrl23 {
      margin-left: 13px;
    }
  }
  .text_normal {
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000e56;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text_select {
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .width62 {
    width: 62px;
    height: 25px;
    border-radius: 18px;
  }
  .color1 {
    background-color: #00b29d;
  }
  .color2 {
    background-color: #88391e;
  }
  .alpha {
    background: #f1f1f1;
  }
  .color3 {
    background-color: #344f00;
  }
  .color4 {
    background-color: #002055;
  }
  .color5 {
    background-color: #005047;
  }
  .color6 {
    background-color: #500009;
  }
  .color7 {
    background-color: #41cbb9;
  }
  .color8 {
    background-color: #a37d8c;
  }
  .color9 {
    background-color: #55242a;
  }
  .color10 {
    background-color: #2e7aa2;
  }
  .color11 {
    background-color: #263d69;
  }
  .color12 {
    background-color: #705d17;
  }
  .color13 {
    background-color: #230db5;
  }
  .color14 {
    background-color: #14c0de;
  }
  .color15 {
    background-color: #159c56;
  }
  .mt6 {
    margin-top: 6px;
  }
  .width1300 {
    width: 1300px;
    .column {
      display: flex;
      flex-direction: column;
      width: 50px;
      .top_circle {
        border-radius: 50px 50px 0 0;
        width: 42px;
        height: 25px;
        border-top: 4px #bdbdbd solid;
        border-left: 4px #bdbdbd solid;
        border-right: 4px #bdbdbd solid;
      }
      .left_pos {
        position: relative;
        width: 4px;
        height: 517px;
      }
      .left_bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 517px;
        background: #bdbdbd;
      }
      .left_v {
        width: 4px;
        background: #1b1ec0;
      }
      .abs {
        position: absolute;
        top: 0;
        left: 0;
      }
      .left_value {
        width: 41px;
        height: 14px;
        background: #e8e8e8;
        border: 2px solid #cccccc;
        border-radius: 9px;
        font-size: 14px;
        font-family: AlibabaPuHuiTi_2_85_Bold;
        font-weight: 400;
        color: #001166;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -20px;
      }
      .left_line_color {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
      .mrl42 {
        margin-left: 42px;
      }
      .width42 {
        width: 42px;
        word-wrap: break-word;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .width10 {
          width: 10px;
          color: #00b29d;
          font-size: 16px;
          margin-bottom: 20px;
        }
      }
      .left_text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border: 2px solid #1b1ec0;
        border-radius: 9px;
        font-size: 12px;
        color: #00b29d;
        margin-left: -10px;
      }
      .right_text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        font-size: 12px;
        color: #00b29d;
      }
    }
    .mrl210 {
      margin-left: 210px;
    }

    .circle_left {
      border-radius: 0px 0 0px 25px;
      height: 21px;
      width: 21px;
      background-color: #e8e8e8;
    }
    .circle_right {
      border-radius: 0px 0 25px 0;
      height: 21px;
      width: 21px;
      background-color: #e8e8e8;
    }
    .h517 {
      height: 517px;
    }
    .column_base {
      display: flex;
      flex-direction: column;
      align-items: baseline;
    }
  }
  .ys_color1 {
    background-color: rgba(192, 40, 27, 1);
  }
  .ys_color2 {
    background-color: rgba(64, 209, 0, 1);
  }
  .ys_color3 {
    background-color: rgba(0, 168, 193, 1);
  }
  .ys_color4 {
    background-color: rgba(224, 200, 0, 1);
  }
  .ys_color5 {
    background-color: rgba(244, 138, 0, 1);
  }
  .ys_color6 {
    background-color: rgba(39, 0, 237, 1);
  }
  .ys_color7 {
    background-color: rgba(214, 0, 253, 1);
  }
  .ys_color8 {
    background-color: rgba(151, 32, 79, 1);
  }
  .ys_color9 {
    background-color: rgba(150, 36, 49, 1);
  }
  .ys_color10 {
    background-color: rgba(46, 162, 122, 1);
  }
  .ys_color11 {
    background-color: rgba(31, 68, 135, 1);
  }
  .ys_color12 {
    background-color: rgba(182, 150, 35, 1);
  }
  .ys_color13 {
    background-color: rgb(60, 57, 87);
  }
  .ys_color14 {
    background-color: rgba(24, 130, 148, 1);
  }
  .ys_color15 {
    background-color: rgba(15, 193, 100, 1);
  }
  .ys_text {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    margin-top: 10px;
    color: #606060;
  }
  .ys_name {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #606060;
    margin-top: -240px;
    text-align: center;
  }
  .column1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  .column2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .width9 {
    width: 9px;
    height: 9px;
    border-width: 7px;
    margin-top: 3px;
  }
  .label1 {
    width: 69px;
    height: 23px;
    border: 2px solid #bbbbbb;
    border-radius: 11px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #606060;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mrl5 {
    margin-left: 5px;
  }
  .mrl16 {
    margin-left: 6px;
  }

  .mrl9 {
    margin-left: 3px;
  }
  .mt82 {
    margin-top: 82px;
  }
}
.country_db {
  .analyse_chart {
    width: 1400px;
    height: 561px;
    margin-top: 63px;
  }
  .analyse_tab {
    width: 130px;
    height: 33px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .text1 {
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #cbcbcb;
    }
    .text2 {
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #686868;
    }
  }
  .red_text {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ce2323;
    margin-top: 20px;
  }
  .label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .text_normal {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000e56;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text_select {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .width16 {
    width: 72px;
    height: 32px;
    border-radius: 18px;
    cursor: pointer;
  }
  .color_white {
    background: #e7e7e7;
  }
  .width1400 {
    width: 1400px;
  }
  .row {
    display: flex;
    flex-direction: row;
  }
  .flex {
    flex: 1;
  }
  .top_hint {
    display: flex;
    flex-direction: row;
    width: 1700px;
    height: 53px;
    .line {
      width: 2px;
      height: 53px;
      background: #c2c2c2;
    }
    .mrl1 {
      margin-left: 1px;
    }
    .mrl2 {
      margin-left: 2px;
    }
    .mrl3 {
      margin-left: 3px;
    }
    .text {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #00157f;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
  }
  .mt26 {
    margin-top: 26px;
  }
  .ml8 {
    margin-left: 8px;
  }
  .ml32 {
    margin-left: 12px;
  }
  .ml30 {
    margin-left: 30px;
  }
  .title {
    font-size: 50px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
  }
  .pes {
    width: 150px;
    height: 150px;
    margin-top: 21px;
  }
  .content {
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
  .mt15 {
    margin-top: 15px;
  }
  .ml20 {
    margin-left: 12px;
  }
  .ml55 {
    margin-left: 55px;
  }
  .pos_rel1 {
    position: relative;
    width: 960px;
    .topLine2 {
      width: 863px;
      height: 847px;
    }
    .label_row {
      position: absolute;
      right: 0;
      top: 120px;
      display: flex;
      flex-direction: row;
      align-items: baseline;
    }
    .column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .row_center {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }
    .all_text {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #606060;
      margin-left: 5px;
    }
    .mrl23 {
      margin-left: 23px;
    }
  }
  .mt6 {
    margin-top: 6px;
  }
  .text_normal {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000e56;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text_select {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .width62 {
    width: 72px;
    height: 32px;
    border-radius: 18px;
  }
  .alpha {
    background: #e7e7e7;
  }
  .color1 {
    background-color: #00b29d;
  }
  .color2 {
    background-color: #88391e;
  }

  .color3 {
    background-color: #344f00;
  }
  .color4 {
    background-color: #002055;
  }
  .color5 {
    background-color: #005047;
  }
  .color6 {
    background-color: #500009;
  }
  .color7 {
    background-color: #41cbb9;
  }
  .color8 {
    background-color: #a37d8c;
  }
  .color9 {
    background-color: #55242a;
  }
  .color10 {
    background-color: #2e7aa2;
  }
  .color11 {
    background-color: #263d69;
  }
  .color12 {
    background-color: #705d17;
  }
  .color13 {
    background-color: #230db5;
  }
  .color14 {
    background-color: #14c0de;
  }
  .color15 {
    background-color: #159c56;
  }
  .width514 {
    width: 514px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .top_lay {
      position: relative;
      width: 100%;
      height: 78px;
    }
    .top_bg {
      width: 498px;
      height: 62px;
      background: #e7e7e7;
      border: 8px solid #ffffff;
      border-radius: 39px;
    }
    .left_text {
      width: 120px;
      height: 62px;
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .middle {
      width: 100%;
      height: 100%;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .pos_abs {
      position: absolute;
      left: 0;
      top: 0;
    }
    .marl8 {
      margin-left: 8px;
    }
    .hint1 {
      font-size: 21px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-top: 25px;
    }
    .left_lay {
      width: 170px;
      height: 49px;
      background: #0abda3;
      border: 6px solid #ffffff;
      border-radius: 31px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .text {
        font-size: 38px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
      .text1 {
        font-size: 38px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin-left: 4px;
      }
      .text2 {
        width: 5px;
        height: 5px;
        background: #ffffff;
        border-radius: 50%;
        margin-left: 15px;
        margin-top: 30px;
      }
    }
    .num_lay {
      width: 514px;
      height: 61px;
      background: #f2f2f2;
      border-radius: 31px;
      display: flex;
      flex-direction: row;
    }
    .mt16 {
      margin-top: 16px;
    }
    .mt36 {
      margin-top: 36px;
    }
  }

  .baseLine {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flex2 {
    flex: 2;
  }
  .flex1 {
    flex: 1;
  }
  .mt72 {
    margin-top: 72px;
  }
  .width80 {
    width: 80px;
  }
  .row_center {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .column_num {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
    margin-right: 6px;
    .text {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #888888;
      margin-top: 10px;
    }
    .line {
      width: 130px;
      height: 2px;
      background: #b9b9b9;
    }
    .value {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #0abda3;
      margin-top: 2px;
    }
    .vs {
      width: 39px;
      height: 25px;
      margin-top: 6px;
    }
  }
  .analyse {
    width: 334px;
    height: 66px;
    background: #00af0c;
    border: 6px solid #00e210;
    border-radius: 39px;
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #010000;
    margin-top: 150px;
    cursor: pointer;
  }
  .analyse_chart {
    width: 1400px;
    height: 561px;
    margin-top: 63px;
  }
  .analyse_tab {
    width: 130px;
    height: 33px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .text1 {
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #cbcbcb;
    }
    .text2 {
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #686868;
    }
  }
  .width1400 {
    width: 1400px;
  }
  .ys_name {
    font-size: 26px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #606060;
    margin-top: 20px;
  }
  .h240 {
    height: 240px;
  }
  .circle_left {
    border-radius: 0px 0 0px 25px;
    height: 21px;
    width: 21px;
    background-color: #e8e8e8;
  }
  .circle_right {
    border-radius: 0px 0 25px 0;
    height: 21px;
    width: 21px;
    background-color: #e8e8e8;
  }
  .mt83 {
    margin-top: 83px;
  }
  .width1300 {
    width: 1300px;
    .column {
      display: flex;
      flex-direction: column;
      width: 50px;
      .top_circle {
        border-radius: 50px 50px 0 0;
        width: 42px;
        height: 25px;
        border-top: 4px #bdbdbd solid;
        border-left: 4px #bdbdbd solid;
        border-right: 4px #bdbdbd solid;
      }
      .left_pos {
        position: relative;
        width: 4px;
        height: 517px;
      }
      .left_bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 517px;
        background: #bdbdbd;
      }
      .left_v {
        width: 4px;
        background: #1b1ec0;
      }
      .abs {
        position: absolute;
        top: 0;
        left: 0;
      }
      .left_value {
        width: 41px;
        height: 14px;
        background: #e8e8e8;
        border: 2px solid #cccccc;
        border-radius: 9px;
        font-size: 14px;
        font-family: AlibabaPuHuiTi_2_85_Bold;
        font-weight: 400;
        color: #001166;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -20px;
      }
      .left_line_color {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
      .mrl42 {
        margin-left: 42px;
      }
      .width42 {
        width: 42px;
        word-wrap: break-word;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .width10 {
          width: 10px;
          color: #00b29d;
          font-size: 16px;
          margin-bottom: 20px;
        }
      }
      .left_text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border: 2px solid #1b1ec0;
        border-radius: 9px;
        font-size: 12px;
        color: #00b29d;
        margin-left: -10px;
      }
      .right_text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        font-size: 12px;
        color: #00b29d;
      }
    }
    .mrl210 {
      margin-left: 210px;
    }
  }
  .h517 {
    height: 517px;
  }
  .mt82 {
    margin-top: 82px;
  }
  .column_base {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .label1 {
    width: 69px;
    height: 23px;
    border: 2px solid #bbbbbb;
    border-radius: 11px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #606060;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .column1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  .column2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .width9 {
    width: 9px;
    height: 9px;
    border-width: 7px;
    margin-top: 3px;
  }
  .mrl5 {
    margin-left: 5px;
  }
  .mrl16 {
    margin-left: 16px;
  }
  .ys_color1 {
    background-color: rgba(192, 40, 27, 1);
  }
  .ys_color2 {
    background-color: rgba(64, 209, 0, 1);
  }
  .ys_color3 {
    background-color: rgba(0, 168, 193, 1);
  }
  .ys_color4 {
    background-color: rgba(224, 200, 0, 1);
  }
  .ys_color5 {
    background-color: rgba(244, 138, 0, 1);
  }
  .ys_color6 {
    background-color: rgba(39, 0, 237, 1);
  }
  .ys_color7 {
    background-color: rgba(214, 0, 253, 1);
  }
  .ys_color8 {
    background-color: rgba(151, 32, 79, 1);
  }
  .ys_color9 {
    background-color: rgba(150, 36, 49, 1);
  }
  .ys_color10 {
    background-color: rgba(46, 162, 122, 1);
  }
  .ys_color11 {
    background-color: rgba(31, 68, 135, 1);
  }
  .ys_color12 {
    background-color: rgba(182, 150, 35, 1);
  }
  .ys_color13 {
    background-color: rgb(60, 57, 87);
  }
  .ys_color14 {
    background-color: rgba(24, 130, 148, 1);
  }
  .ys_color15 {
    background-color: rgba(15, 193, 100, 1);
  }
  .ys_text {
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    margin-top: 10px;
    color: #606060;
  }
  .mrl9 {
    margin-left: 9px;
  }
}
.yecf_small {
  .topBar {
    width: 601px;
    height: 360px;
    margin-top: 0px;
  }
  .ys_name {
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #606060;
    margin-top: 20px;
  }
  .row {
    display: flex;
    flex-direction: row;
  }
  .bgf1 {
    background: #f1f1f1;
  }
  .column1 {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .column2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .ys_top {
    width: 54px;
    height: 19px;
    border: 2px solid #bbbbbb;
    border-radius: 11px;
    font-size: 15px;
  }
  .qg_top {
    width: 78px;
    height: 19px;
    border: 2px solid #bbbbbb;
    border-radius: 11px;
    font-size: 15px;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .h240 {
    height: 240px;
  }
  .mt21 {
    margin-top: 13px;
  }
  .mt43 {
    margin-top: 43px;
  }
  .mrl100 {
    margin-left: 100px;
  }
  .ml55 {
    margin-left: 55px;
  }
  .month_normal {
    width: 49px;
    height: 20px;
    border: 3px solid #d4d4d4;
    border-radius: 13px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .text1 {
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #b0b0b0;
    }
    .text2 {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #b0b0b0;
      margin-top: 2px;
    }
  }
  .month_select {
    width: 49px;
    height: 20px;
    border: 3px solid #41cbb9;
    border-radius: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .text1 {
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #41cbb9;
    }
    .text2 {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #41cbb9;
      margin-top: 2px;
    }
  }
  .mt2 {
    margin-top: 2px;
  }
  .ys_color1 {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(192, 40, 27, 1) solid;
  }
  .ys_color2 {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(64, 209, 0, 1) solid;
  }
  .ys_color3 {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(0, 168, 193, 1) solid;
  }
  .ys_color4 {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(224, 200, 0, 1) solid;
  }
  .ys_color5 {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(244, 138, 0, 1) solid;
  }
  .ys_color6 {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(39, 0, 237, 1) solid;
  }
  .ys_color7 {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(214, 0, 253, 1) solid;
  }
  .ys_color8 {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(151, 32, 79, 1) solid;
  }
  .ys_color9 {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(150, 36, 49, 1) solid;
  }
  .ys_color10 {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(46, 162, 122, 1) solid;
  }
  .ys_color11 {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(31, 68, 135, 1) solid;
  }
  .ys_color12 {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(182, 150, 35, 1) solid;
  }
  .ys_color13 {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgb(60, 57, 87) solid;
  }
  .ys_color14 {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(24, 130, 148, 1) solid;
  }
  .ys_color15 {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(15, 193, 100, 1) solid;
  }
  .ys_text {
    font-size: 10px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    margin-top: 2px;
    color: #606060;
  }
  .width50 {
    width: 40px;
  }
  .mrl9 {
    margin-left: 3px;
  }
  .mrl16 {
    margin-left: 6px;
  }
  .mt14 {
    margin-top: 14px;
  }
  .pos {
    position: relative;
    width: 500px;
    height: 87px;
  }
  .top_line {
    position: absolute;
    left: 0;
    bottom: 21px;
    width: 489px;
    height: 2px;
    background: #b0b0b0;
  }
  .line_row {
    position: absolute;
    left: 0;
    top: 0px;
    display: flex;
    flex-direction: row;
    .fk {
      width: 12px;
      height: 5px;
      background: #b0b0b0;
      margin-top: 6px;
    }
  }
  .mrl35 {
    margin-left: 35px;
  }
  .title {
    font-size: 50px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
  }
  .mt15 {
    margin-top: 15px;
  }
  .baseLine {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  .mt72 {
    margin-top: 72px;
  }
  .width514 {
    width: 514px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .top_lay {
      position: relative;
      width: 100%;
      height: 78px;
    }
    .top_bg {
      width: 498px;
      height: 62px;
      background: #e7e7e7;
      border: 8px solid #ffffff;
      border-radius: 39px;
    }
    .left_text {
      width: 120px;
      height: 62px;
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .middle {
      width: 100%;
      height: 100%;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .pos_abs {
      position: absolute;
      left: 0;
      top: 0;
    }
    .marl8 {
      margin-left: 8px;
    }
    .hint1 {
      font-size: 21px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-top: 25px;
    }
    .left_lay {
      width: 170px;
      height: 49px;
      background: #0abda3;
      border: 6px solid #ffffff;
      border-radius: 31px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .text {
        font-size: 38px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
      .text1 {
        font-size: 38px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin-left: 4px;
      }
      .text2 {
        width: 5px;
        height: 5px;
        background: #ffffff;
        border-radius: 50%;
        margin-left: 15px;
        margin-top: 30px;
      }
    }
    .num_lay {
      width: 514px;
      height: 61px;
      background: #f2f2f2;
      border-radius: 31px;
      display: flex;
      flex-direction: row;
    }
    .mt16 {
      margin-top: 16px;
    }
    .mt36 {
      margin-top: 36px;
    }
  }
  .column_num {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
    margin-right: 6px;
    .text {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #888888;
      margin-top: 10px;
    }
    .line {
      width: 130px;
      height: 2px;
      background: #b9b9b9;
    }
    .value {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #0abda3;
      margin-top: 2px;
    }
    .vs {
      width: 39px;
      height: 25px;
      margin-top: 6px;
    }
  }
  .flex {
    flex: 1;
  }
  .analyseBar {
    width: 601px;
    height: 360px;
  }
  .center {
    align-items: center;
    justify-content: center;
  }
  .tz_select1 {
    width: 67px;
    height: 24px;
    background: #000f60;
    border: 6px solid #0001c1;
    border-top-left-radius: 29px;
    border-bottom-left-radius: 29px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .tz_normal1 {
    width: 67px;
    height: 24px;
    background: #e8e8e8;
    border: 6px solid #c4c4c4;
    border-top-left-radius: 29px;
    border-bottom-left-radius: 29px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cbcbcb;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .yd_select1 {
    width: 67px;
    height: 24px;
    background: #000f60;
    border: 6px solid #0001c1;
    border-top-right-radius: 29px;
    border-bottom-right-radius: 29px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .yd_normal1 {
    width: 67px;
    height: 24px;
    background: #e8e8e8;
    border: 6px solid #c4c4c4;
    border-top-right-radius: 29px;
    border-bottom-right-radius: 29px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cbcbcb;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .mrl10 {
    margin-left: 10px;
  }
  .qg_flag {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #000f5a;
    margin-left: 3px;
  }
  .qg_text {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000f5a;
    margin-left: 5px;
  }
  .analyse_month_select {
    width: 59px;
    height: 20px;
    border: 3px solid #000f5a;
    border-radius: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .text1 {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000f5a;
    }
    .text2 {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000f5a;
      margin-top: 2px;
    }
  }
}
.yecf {
  .topBar {
    width: 654px;
    height: 651px;
    margin-top: -30px;
  }
  .ys_name {
    font-size: 26px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #606060;
    margin-top: 20px;
  }
  .row {
    display: flex;
    flex-direction: row;
  }
  .column1 {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .column2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .ys_top {
    width: 74px;
    height: 19px;
    border: 2px solid #bbbbbb;
    border-radius: 11px;
    font-size: 15px;
  }
  .qg_top {
    width: 98px;
    height: 19px;
    border: 2px solid #bbbbbb;
    border-radius: 11px;
    font-size: 15px;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .h240 {
    height: 240px;
  }
  .mt21 {
    margin-top: 21px;
  }
  .mt43 {
    margin-top: 43px;
  }
  .mrl100 {
    margin-left: 100px;
  }
  .month_normal {
    width: 59px;
    height: 20px;
    border: 3px solid #d4d4d4;
    border-radius: 13px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .text1 {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #b0b0b0;
    }
    .text2 {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #b0b0b0;
      margin-top: 2px;
    }
  }
  .month_select {
    width: 59px;
    height: 20px;
    border: 3px solid #41cbb9;
    border-radius: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .text1 {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #41cbb9;
    }
    .text2 {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #41cbb9;
      margin-top: 2px;
    }
  }
  .mt2 {
    margin-top: 2px;
  }
  .ys_color1 {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(192, 40, 27, 1) solid;
  }
  .ys_color2 {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(64, 209, 0, 1) solid;
  }
  .ys_color3 {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(0, 168, 193, 1) solid;
  }
  .ys_color4 {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(224, 200, 0, 1) solid;
  }
  .ys_color5 {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(244, 138, 0, 1) solid;
  }
  .ys_color6 {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(39, 0, 237, 1) solid;
  }
  .ys_color7 {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(214, 0, 253, 1) solid;
  }
  .ys_color8 {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(151, 32, 79, 1) solid;
  }
  .ys_color9 {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(150, 36, 49, 1) solid;
  }
  .ys_color10 {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(46, 162, 122, 1) solid;
  }
  .ys_color11 {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(31, 68, 135, 1) solid;
  }
  .ys_color12 {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(182, 150, 35, 1) solid;
  }
  .ys_color13 {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgb(60, 57, 87) solid;
  }
  .ys_color14 {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(24, 130, 148, 1) solid;
  }
  .ys_color15 {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    border: 8px rgba(15, 193, 100, 1) solid;
  }
  .ys_text {
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    margin-top: 2px;
    color: #606060;
  }
  .mrl9 {
    margin-left: 9px;
  }
  .mrl16 {
    margin-left: 16px;
  }
  .mt14 {
    margin-top: 14px;
  }
  .pos {
    position: relative;
    width: 700px;
    height: 87px;
  }
  .top_line {
    position: absolute;
    left: 0;
    bottom: 13px;
    width: 689px;
    height: 2px;
    background: #b0b0b0;
  }
  .line_row {
    position: absolute;
    left: 0;
    top: 0px;
    display: flex;
    flex-direction: row;
    .fk {
      width: 12px;
      height: 5px;
      background: #b0b0b0;
      margin-top: 6px;
    }
  }
  .mrl35 {
    margin-left: 35px;
  }
  .title {
    font-size: 50px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
  }
  .pes {
    width: 150px;
    height: 150px;
    margin-top: 21px;
  }
  .content {
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
  .mt15 {
    margin-top: 15px;
  }
  .baseLine {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  .mt72 {
    margin-top: 72px;
  }
  .width514 {
    width: 514px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .top_lay {
      position: relative;
      width: 100%;
      height: 78px;
    }
    .top_bg {
      width: 498px;
      height: 62px;
      background: #e7e7e7;
      border: 8px solid #ffffff;
      border-radius: 39px;
    }
    .left_text {
      width: 120px;
      height: 62px;
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .middle {
      width: 100%;
      height: 100%;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .pos_abs {
      position: absolute;
      left: 0;
      top: 0;
    }
    .marl8 {
      margin-left: 8px;
    }
    .hint1 {
      font-size: 21px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-top: 25px;
    }
    .left_lay {
      width: 170px;
      height: 49px;
      background: #0abda3;
      border: 6px solid #ffffff;
      border-radius: 31px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .text {
        font-size: 38px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
      .text1 {
        font-size: 38px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin-left: 4px;
      }
      .text2 {
        width: 5px;
        height: 5px;
        background: #ffffff;
        border-radius: 50%;
        margin-left: 15px;
        margin-top: 30px;
      }
    }
    .num_lay {
      width: 514px;
      height: 61px;
      background: #f2f2f2;
      border-radius: 31px;
      display: flex;
      flex-direction: row;
    }
    .mt16 {
      margin-top: 16px;
    }
    .mt36 {
      margin-top: 36px;
    }
  }
  .column_num {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
    margin-right: 6px;
    .text {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #888888;
      margin-top: 10px;
    }
    .line {
      width: 130px;
      height: 2px;
      background: #b9b9b9;
    }
    .value {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #0abda3;
      margin-top: 2px;
    }
    .vs {
      width: 39px;
      height: 25px;
      margin-top: 6px;
    }
  }
  .flex {
    flex: 1;
  }
  .analyseBar {
    width: 890px;
    height: 850px;
  }
  .tz_select1 {
    width: 100px;
    height: 46px;
    background: #000f60;
    border: 6px solid #0001c1;
    border-top-left-radius: 29px;
    border-bottom-left-radius: 29px;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .tz_normal1 {
    width: 100px;
    height: 46px;
    background: #e8e8e8;
    border: 6px solid #c4c4c4;
    border-top-left-radius: 29px;
    border-bottom-left-radius: 29px;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cbcbcb;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .yd_select1 {
    width: 100px;
    height: 46px;
    background: #000f60;
    border: 6px solid #0001c1;
    border-top-right-radius: 29px;
    border-bottom-right-radius: 29px;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .yd_normal1 {
    width: 100px;
    height: 46px;
    background: #e8e8e8;
    border: 6px solid #c4c4c4;
    border-top-right-radius: 29px;
    border-bottom-right-radius: 29px;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cbcbcb;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .mrl10 {
    margin-left: 10px;
  }
  .qg_flag {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #000f5a;
    margin-left: 3px;
  }
  .qg_text {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000f5a;
    margin-left: 5px;
  }
  .analyse_month_select {
    width: 59px;
    height: 20px;
    border: 3px solid #000f5a;
    border-radius: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .text1 {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000f5a;
    }
    .text2 {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000f5a;
      margin-top: 2px;
    }
  }
}
.empty_text {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #da3c2d;
}
.h240 {
  height: 120px;
}
.column_center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>